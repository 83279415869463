import { getDynamicMasterMap } from '../../services/attribute-values-service'
import { AttributeValuesActionType } from './actionTypes'
import { AxiosResponse } from 'axios'

interface Action {
  type?: AttributeValuesActionType
  payload?: AxiosResponse<any>
  attributeType?: string
  searchText?: string
  parentFamilyId?: string
}

const initialState = {
  bpId_dynamicMasterApexID: {},
  bpId_standardrApexID: {},
  dept_classes: {},
  bpIdPids_dept: {},
  deptClass_subclasses: {},
  deptClassSubclass_merchStyles: {},
  dept_merchTypes: {},
  merchTypeId_itemTypes: {},
  itemTypeId_sizes: {},
  allCountries: [],
  country_portOfExports: {},
  portOfExport_factoryCities: {},
  vendorId_vendorAttributes: {},
  autocompleteAtrributeValues: {},
  deptId_stdRetPolicies: {},
  colorFamilies: [],
  patternFamilies: [],
  vendorsByBpId: {},
  pidIds_Details: {},
  fetchCounter: 0,
  completionsCounter: 0,
  isAttributeValuesLoaded: false,
}

const incrementFetches = state => ({
  fetchCounter: state.fetchCounter + 1,
  isAttributeValuesLoaded: false,
})

const incrementCompletions = state => {
  const completionsCounter = state.completionsCounter + 1
  const isAttributeValuesLoaded = state.fetchCounter === completionsCounter
  return { completionsCounter, isAttributeValuesLoaded }
}

export const attributeValuesReducer = (
  state = initialState,
  action: Action = {}
) => {
  switch (action.type) {
    case AttributeValuesActionType.FETCH_DEPT_CLASSES:
    case AttributeValuesActionType.FETCH_SUB_CLASSES:
    case AttributeValuesActionType.FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES:
    case AttributeValuesActionType.FETCH_ALL_COUNTRIES:
    case AttributeValuesActionType.FETCH_MERCH_TYPE:
    case AttributeValuesActionType.FETCH_ITEM_TYPE:
    case AttributeValuesActionType.FETCH_SIZES:
    case AttributeValuesActionType.FETCH_MERCH_STYLES:
    case AttributeValuesActionType.FETCH_VMM_ATTRIBUTES:
    case AttributeValuesActionType.FETCH_DYNAMIC_MASTER_APEX_IDS:
    case AttributeValuesActionType.FETCH_STANDARD_APEX_IDS:
    case AttributeValuesActionType.FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES:
    case AttributeValuesActionType.FETCH_DEPT_STANDARD_RET_POLICIES:
    case AttributeValuesActionType.FETCH_COLOR_FAMILIES:
    case AttributeValuesActionType.FETCH_PIDS:
    case AttributeValuesActionType.FETCH_PIDS_DETAILS:
    case AttributeValuesActionType.FETCH_PATTERN_FAMILIES: {
      return {
        ...state,
        ...incrementFetches(state),
      }
    }

    case AttributeValuesActionType.FETCH_DEPT_CLASSES_SUCCESS: {
      const { payload } = action
      const dept_classes_temp = {
        ...state.dept_classes,
        ...payload?.data,
      }
      return {
        ...state,
        dept_classes: dept_classes_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_SUB_CLASSES_SUCCESS: {
      const { payload } = action
      const deptClass_subclasses_temp = {
        ...state.deptClass_subclasses,
        ...payload?.data,
      }
      return {
        ...state,
        deptClass_subclasses: deptClass_subclasses_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES_SUCCESS: {
      const { payload } = action
      const country_portOfExports_temp = {
        ...state.country_portOfExports,
        ...payload?.data.portOfExports,
      }
      const portOfExport_factoryCities_temp = {
        ...state.portOfExport_factoryCities,
        ...payload?.data.factoryCities,
      }
      return {
        ...state,
        country_portOfExports: country_portOfExports_temp,
        portOfExport_factoryCities: portOfExport_factoryCities_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_ALL_COUNTRIES_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        allCountries: payload?.data,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_MERCH_TYPE_SUCCESS: {
      const { payload } = action
      const dept_merchTypes_temp = {
        ...state.dept_merchTypes,
        ...payload?.data.merchTypes,
      }
      return {
        ...state,
        dept_merchTypes: dept_merchTypes_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_ITEM_TYPE_SUCCESS: {
      const { payload } = action
      const merchTypeId_itemTypes_temp = {
        ...state.merchTypeId_itemTypes,
        ...payload?.data,
      }
      return {
        ...state,
        merchTypeId_itemTypes: merchTypeId_itemTypes_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_SIZES_SUCCESS: {
      const { payload } = action
      const itemTypeId_sizes_temp = {
        ...state.itemTypeId_sizes,
        ...payload?.data,
      }
      return {
        ...state,
        itemTypeId_sizes: itemTypeId_sizes_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_MERCH_STYLES_SUCCESS: {
      const { payload } = action
      const deptClassSubclass_merchStyles_temp = {
        ...state.deptClassSubclass_merchStyles,
        ...payload?.data,
      }
      return {
        ...state,
        deptClassSubclass_merchStyles: deptClassSubclass_merchStyles_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_VMM_ATTRIBUTES_SUCCESS: {
      const { payload } = action
      const vendorAttributes_temp = {
        ...state.vendorId_vendorAttributes,
        ...payload?.data,
      }
      return {
        ...state,
        vendorId_vendorAttributes: vendorAttributes_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_DYNAMIC_MASTER_APEX_IDS_SUCCESS: {
      const { payload } = action
      const dynamicMasterApexID_temp = {
        ...state.bpId_dynamicMasterApexID,
        ...getDynamicMasterMap(
          payload?.data.master_grouping_attributes_response
        ),
      }
      return {
        ...state,
        bpId_dynamicMasterApexID: dynamicMasterApexID_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_STANDARD_APEX_IDS_SUCCESS: {
      const { payload } = action
      const standardApexID_temp = {
        ...state.bpId_standardrApexID,
        ...getDynamicMasterMap(
          payload?.data.master_grouping_attributes_response
        ),
      }
      return {
        ...state,
        bpId_standardApexID: standardApexID_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES_SUCCESS: {
      const { payload, attributeType, searchText, parentFamilyId } = action
      const transformedData = payload?.data.map(value => {
        return { label: value.name, value: value.fieldId }
      })
      let autocompleteAtrributeValues_temp
      if (searchText != null) {
        autocompleteAtrributeValues_temp = {
          ...state.autocompleteAtrributeValues,
          [attributeType!]: {
            ...state.autocompleteAtrributeValues[attributeType!],
            [searchText]: transformedData,
          },
        }
      }
      if (parentFamilyId != null) {
        autocompleteAtrributeValues_temp = {
          ...state.autocompleteAtrributeValues,
          [attributeType!]: {
            ...state.autocompleteAtrributeValues[attributeType!],
            [parentFamilyId]: transformedData,
          },
        }
      }
      return {
        ...state,
        autocompleteAtrributeValues: autocompleteAtrributeValues_temp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_DEPT_STANDARD_RET_POLICIES_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        deptId_stdRetPolicies: payload?.data,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_PIDS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        bpIdPids_dept: payload,
        ...incrementCompletions(state),
      }
    }
    case AttributeValuesActionType.FETCH_PIDS_DETAILS_SUCCESS: {
      const { payload } = action
      const pidIds_Details = payload?.data?.pidDetails?.reduce(
        (pidMap, pid) => {
          pidMap[pid.productId] = pid
          return pidMap
        },
        {}
      )

      return {
        ...state,
        pidIds_Details: pidIds_Details,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_COLOR_FAMILIES_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        colorFamilies: payload?.data,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_PATTERN_FAMILIES_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        patternFamilies: payload?.data,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_VENDORS_FOR_BPIDS: {
      return {
        ...state,
        vendorsByBpId: {},
        ...incrementFetches(state),
      }
    }

    case AttributeValuesActionType.FETCH_VENDORS_FOR_BPIDS_SUCCESS: {
      const vendorsByBpTemp: any = {}
      const vendorBpList: any[] = []
      const importVendors: any[] = []
      const domVendors: any[] = []
      const dvsVendors: any[] = []
      ;(action.payload as any).vendors.forEach(vendor => {
        const vendorBpMap: any = {}
        vendorBpMap.bpId = vendor.bpId
        vendorBpMap.vendorNumber = vendor.vendorNumber
        vendorBpList.push(vendorBpMap)
        switch (vendor.vendorType) {
          case 'DOMESTIC':
            domVendors.push(vendor.vendorNumber)
            break
          case 'IMPORT':
            importVendors.push(vendor.vendorNumber)
            break
          case 'DVS':
            dvsVendors.push(vendor.vendorNumber)
            break
          default:
            break
        }
      })
      vendorsByBpTemp.impVendorIds = importVendors
      vendorsByBpTemp.domVendorIds = domVendors
      vendorsByBpTemp.dvsVendorIds = dvsVendors
      vendorsByBpTemp.vendorBpList = vendorBpList
      return {
        ...state,
        vendorsByBpId: vendorsByBpTemp,
        ...incrementCompletions(state),
      }
    }

    case AttributeValuesActionType.FETCH_DEPT_CLASSES_ERROR:
    case AttributeValuesActionType.FETCH_SUB_CLASSES_ERROR:
    case AttributeValuesActionType.FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES_ERROR:
    case AttributeValuesActionType.FETCH_ALL_COUNTRIES_ERROR:
    case AttributeValuesActionType.FETCH_MERCH_TYPE_ERROR:
    case AttributeValuesActionType.FETCH_ITEM_TYPE_ERROR:
    case AttributeValuesActionType.FETCH_SIZES_ERROR:
    case AttributeValuesActionType.FETCH_PIDS_ERROR:
    case AttributeValuesActionType.FETCH_PIDS_DETAILS_ERROR:
    case AttributeValuesActionType.FETCH_MERCH_STYLES_ERROR:
    case AttributeValuesActionType.FETCH_VMM_ATTRIBUTES_ERROR:
    case AttributeValuesActionType.FETCH_DYNAMIC_MASTER_APEX_IDS_ERROR:
    case AttributeValuesActionType.FETCH_STANDARD_APEX_IDS_ERROR:
    case AttributeValuesActionType.FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES_ERROR:
    case AttributeValuesActionType.FETCH_DEPT_STANDARD_RET_POLICIES_ERROR:
    case AttributeValuesActionType.FETCH_COLOR_FAMILIES_ERROR:
    case AttributeValuesActionType.FETCH_PATTERN_FAMILIES_ERROR:
    case AttributeValuesActionType.FETCH_VENDORS_FOR_BPIDS_ERROR: {
      return {
        ...state,
        ...incrementCompletions(state),
      }
    }
    case AttributeValuesActionType.ATTRIBUTES_LOADING_SKIPPED: {
      return {
        ...state,
        isAttributeValuesLoaded: true,
      }
    }
    default:
      return state
  }
}
