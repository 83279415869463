export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR'

export const FETCH_RELEASES = 'FETCH_RELEASES'
export const FETCH_RELEASES_SUCCESS = 'FETCH_RELEASES_SUCCESS'
export const FETCH_RELEASES_ERROR = 'FETCH_RELEASES_ERROR'

export const FETCH_ITEM_SETUP_EVENTS = 'FETCH_ITEM_SETUP_EVENTS'
export const FETCH_ITEM_SETUP_EVENTS_SUCCESS = 'FETCH_ITEM_SETUP_EVENTS_SUCCESS'
export const FETCH_ITEM_SETUP_EVENTS_ERROR = 'FETCH_ITEM_SETUP_EVENTS_ERROR'

export const ARCHIVE_EVENTS = 'ARCHIVE_EVENTS'
export const ARCHIVE_EVENTS_SUCCESS = 'ARCHIVE_EVENTS_SUCCESS'
export const ARCHIVE_EVENTS_ERROR = 'ARCHIVE_EVENTS_ERROR'

export const FETCH_ARCHIVED_EVENTS = 'FETCH_ARCHIVED_EVENTS'
export const FETCH_ARCHIVED_EVENTS_SUCCESS = 'FETCH_ARCHIVED_EVENTS_SUCCESS'
export const FETCH_ARCHIVED_EVENTS_ERROR = 'FETCH_ARCHIVED_EVENTS_ERROR'

export const UNARCHIVE_EVENTS = 'UNARCHIVE_EVENTS'
export const UNARCHIVE_EVENTS_SUCCESS = 'UNARCHIVE_EVENTS_SUCCESS'
export const UNARCHIVE_EVENTS_ERROR = 'UNARCHIVE_EVENTS_ERROR'

export const SUBMIT_EVENTS = 'SUBMIT_EVENTS'
export const SUBMIT_EVENTS_SUCCESS = 'SUBMIT_EVENTS_SUCCESS'
export const SUBMIT_EVENTS_ERROR = 'SUBMIT_EVENTS_ERROR'

export const UPDATE_BID = 'UPDATE_BID'
export const UPDATE_BID_SUCCESS = 'UPDATE_BID_SUCCESS'
export const UPDATE_BID_ERROR = 'UPDATE_BID_ERROR'

export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA'
export const CLEAR_SEARCH_CRITERIA = 'CLEAR_SEARCH_CRITERIA'

export const SEARCH_EVENTS = 'SEARCH_EVENTS'
export const SEARCH_EVENTS_SUCCESS = 'SEARCH_EVENTS_SUCCESS'
export const SEARCH_EVENTS_ERROR = 'SEARCH_EVENTS_ERROR'

export const SEARCH_INTERNAL_EVENTS = 'SEARCH_INTERNAL_EVENTS'
export const SEARCH_INTERNAL_EVENTS_SUCCESS = 'SEARCH_INTERNAL_EVENTS_SUCCESS'
export const SEARCH_INTERNAL_EVENTS_ERROR = 'SEARCH_INTERNAL_EVENTS_ERROR'

export const FETCH_EVENT_CONTACTS = 'FETCH_EVENT_CONTACTS'
export const FETCH_EVENT_CONTACTS_SUCCESS = 'FETCH_EVENT_CONTACTS_SUCCESS'
export const FETCH_EVENT_CONTACTS_ERROR = 'FETCH_EVENT_CONTACTS_ERROR'

export const FETCH_EVENT_ATTRIBUTES = 'FETCH_EVENT_ATTRIBUTES'
export const FETCH_EVENT_ATTRIBUTES_SUCCESS = 'FETCH_EVENT_ATTRIBUTES_SUCCESS'
export const FETCH_EVENT_ATTRIBUTES_ERROR = 'FETCH_EVENT_ATTRIBUTES_ERROR'

export const UPDATE_EVENT_ATTRIBUTES = 'UPDATE_EVENT_ATTRIBUTES'
export const UPDATE_EVENT_ATTRIBUTES_SUCCESS = 'UPDATE_EVENT_ATTRIBUTES_SUCCESS'
export const UPDATE_EVENT_ATTRIBUTES_ERROR = 'UPDATE_EVENT_ATTRIBUTES_ERROR'

export const FETCH_ASSOCIATED_PLANS = 'FETCH_ASSOCIATED_PLANS'
export const FETCH_ASSOCIATED_PLANS_SUCCESS = 'FETCH_ASSOCIATED_PLANS_SUCCESS'
export const FETCH_ASSOCIATED_PLANS_ERROR = 'FETCH_ASSOCIATED_PLANS_ERROR'

export const FETCH_VENDOR_COUNT = 'FETCH_VENDOR_COUNT'
export const FETCH_VENDOR_COUNT_SUCCESS = 'FETCH_VENDOR_COUNT_SUCCESS'
export const FETCH_VENDOR_COUNT_ERROR = 'FETCH_VENDOR_COUNT_ERROR'

export const FETCH_PRODUCT_COUNT = 'FETCH_PRODUCT_COUNT'
export const FETCH_PRODUCT_COUNT_SUCCESS = 'FETCH_PRODUCT_COUNT_SUCCESS'
export const FETCH_PRODUCT_COUNT_ERROR = 'FETCH_PRODUCT_COUNT_ERROR'

export const FETCH_PRODUCT_COUNT_FOR_BP = 'FETCH_PRODUCT_COUNT_FOR_BP'
export const FETCH_PRODUCT_COUNT_FOR_BP_SUCCESS =
  'FETCH_PRODUCT_COUNT_FOR_BP_SUCCESS'
export const FETCH_PRODUCT_COUNT_FOR_BP_ERROR =
  'FETCH_PRODUCT_COUNT_FOR_BP_ERROR'

export const GET_AUTH_USERS = 'GET_AUTH_USERS'
export const GET_AUTH_USERS_SUCCESS = 'GET_AUTH_USERS_SUCCESS'
export const GET_AUTH_USERS_ERROR = 'GET_AUTH_USERS_ERROR'

export const GET_EVENT_USER_VIEWS = 'GET_EVENT_USER_VIEWS'
export const GET_EVENT_USER_VIEWS_SUCCESS = 'GET_EVENT_USER_VIEWS_SUCCESS'
export const GET_EVENT_USER_VIEWS_ERROR = 'GET_EVENT_USER_VIEWS_ERROR'

export const UPDATE_BID_INTERNAL = 'UPDATE_BID_INTERNAL'
export const UPDATE_BID_INTERNAL_SUCCESS = 'UPDATE_BID_INTERNAL_SUCCESS'
export const UPDATE_BID_INTERNAL_ERROR = 'UPDATE_BID_INTERNAL_ERROR'

export const RESET_EVENT_LISTS = 'RESET_EVENT_LISTS'
export const RESET_EVENT_USER_VIEWS = 'RESET_EVENT_USER_VIEWS'

export const FETCH_DISTRIBUTOR_BPS = 'FETCH_DISTRIBUTOR_BPS'
export const FETCH_DISTRIBUTOR_BPS_SUCCESS = 'FETCH_DISTRIBUTOR_BPS_SUCCESS'
export const FETCH_DISTRIBUTOR_BPS_ERROR = 'FETCH_DISTRIBUTOR_BPS_ERROR'
