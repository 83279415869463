export const FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS =
  'FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS'
export const FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS_SUCCESS =
  'FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS_SUCCESS'
export const FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS_ERROR =
  'FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS_ERROR'

export const SAVE_VENDOR_PRE_ISU = 'SAVE_VENDOR_PRE_ISU'
export const SAVE_VENDOR_PRE_ISU_SUCCESS = 'SAVE_VENDOR_PRE_ISU_SUCCESS'
export const SAVE_VENDOR_PRE_ISU_ERROR = 'SAVE_VENDOR_PRE_ISU_ERROR'

export const EXPORT_VENDOR_PRE_ISU = 'EXPORT_VENDOR_PRE_ISU'
export const EXPORT_VENDOR_PRE_ISU_SUCCESS = 'EXPORT_VENDOR_PRE_ISU_SUCCESS'
export const EXPORT_VENDOR_PRE_ISU_ERROR = 'EXPORT_VENDOR_PRE_ISU_ERROR'

export const RESET_REQUEST_VENDOR_PRE_ISU = 'RESET_REQUEST_VENDOR_PRE_ISU'
