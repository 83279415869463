import React, { FunctionComponent, useState, useEffect } from 'react'
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Toolbar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { getAppConfig } from '../../config/appConfig'
import { connect } from 'react-redux'
import apex_logo_white from 'apex-ui-kit/images/apex_logo_white.svg'
import { Typography, ThemeConfig } from 'apex-ui-kit'
import { PcnRedirect } from '../PcnRedirect'
import { clearSearchCriteriaAction } from '../../store/events/actionCreator'

const useStyles = makeStyles({
  list: {
    width: 300,
  },

  listItemButton: {
    '&:hover': {
      backgroundColor: 'rgba(158,158,158,0.2)',
    },
  },

  listItemIcon: {
    color: 'inherit',
    minWidth: '30px',
  },

  listItemText: {
    color: 'inherit',
    textTransform: 'uppercase',
  },

  toolBar: {
    backgroundColor: '#8F1C20',
    padding: '8px',
  },
  subHeader: {
    color: 'inherit',
    opacity: 0.55,
  },
  paper: {
    backgroundColor: ThemeConfig.palette.background.sideMenu,
    color: '#ccc',
  },
  apexLogo: {
    width: '72px',
    height: '72px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

interface NavBarProps {
  navRoutes: { [key: string]: any }
  pageName: string
  clearSearchCriteriaOnNavigate?: boolean
  clearSearchCriteriaAction?: () => void
}

const NavBar: FunctionComponent<NavBarProps> = ({
  navRoutes,
  pageName,
  clearSearchCriteriaOnNavigate,
  clearSearchCriteriaAction,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<string>('none')

  useEffect(() => {
    if (redirectTo !== 'none') {
      setOpen(false)
      setRedirectTo('none')
    }
  }, [redirectTo])

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const onNavLinkClick = pageName => {
    setOpen(false)
    const newRedirection = navRoutes[pageName].route
    if (newRedirection !== redirectTo) {
      if (newRedirection === '/logout') {
        const config = getAppConfig()
        localStorage.removeItem('id_token')
        localStorage.removeItem('access_token')
        window.location.href = config.auth.logoutUrl
      } else {
        if (clearSearchCriteriaOnNavigate) {
          clearSearchCriteriaAction?.()
        }
        setRedirectTo(newRedirection)
      }
    }
  }

  return (
    <>
      {redirectTo !== 'none' && <PcnRedirect redirectTo={redirectTo} />}
      {redirectTo === 'none' && (
        <AppBar position={'static'}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {pageName}
            </Typography>
          </Toolbar>
          <Drawer
            open={open}
            onClose={toggleDrawer}
            classes={{ paper: classes.paper }}
          >
            <div className={classes.list}>
              <Toolbar className={classes.toolBar}>
                <img
                  src={apex_logo_white}
                  className={classes.apexLogo}
                  alt={'Apex Logo'}
                />
              </Toolbar>
              <List>
                <ListSubheader className={classes.subHeader}>
                  Navigation
                </ListSubheader>
                {Object.keys(navRoutes).map(pageName => (
                  <ListItem
                    button
                    key={pageName}
                    onClick={() => onNavLinkClick(pageName)}
                    classes={{ button: classes.listItemButton }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      {navRoutes[pageName].icon}
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={pageName}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>
        </AppBar>
      )}
    </>
  )
}

const mapStateToProps = state => {
  const { navBar } = state
  const { pageName } = navBar
  return {
    pageName,
  }
}

const mapDispatchToProps = {
  clearSearchCriteriaAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
