import React, { Suspense, lazy, useRef } from 'react'
import { Switch } from 'react-router-dom'
import { ProtectedRoute } from '@praxis/component-auth'
import PcnView from '../views/PcnView'

const HomeContainer = lazy(() => import('../views/Vendor/Home'))
const EventsContainer = lazy(() => import('../views/Vendor/Events'))
const ItemSetupContainer = lazy(() => import('../views/Vendor/ItemSetup'))
const VendorEventContainer = lazy(() => import('../views/Vendor/VendorEvent'))

const addToCrumbs = (crumbs, props) => {
  // If back was clicked, remove the page we backed from
  if (props?.location?.state?.backClicked) {
    crumbs.pop()
  }

  // If we're going to a page we don't think we're at, add it
  if (
    props?.location?.pathname &&
    crumbs?.[crumbs.length - 1] !== props?.location?.pathname
  ) {
    crumbs.push(props?.location?.pathname)
  }
}

const ExternalAppRoutes = () => {
  const crumbs = useRef<string[]>([])

  return (
    <Suspense fallback={<div>Please wait while your page is loading...</div>}>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={props => {
            // Reset crumbs when going home, we don't need atm
            crumbs.current = [props?.location?.pathname]
            return (
              <PcnView view="home" {...props}>
                {viewProps => <HomeContainer {...viewProps} />}
              </PcnView>
            )
          }}
          allowed={[]}
        />
        <ProtectedRoute
          exact
          path="/events"
          component={props => {
            // Reset crumbs when going to events, we don't need atm
            crumbs.current = [props?.location?.pathname]
            return (
              <PcnView view="events" {...props}>
                {viewProps => <EventsContainer {...viewProps} />}
              </PcnView>
            )
          }}
          allowed={[]}
        />
        <ProtectedRoute
          exact
          path="/item-setup"
          component={props => {
            addToCrumbs(crumbs.current, props)
            return (
              <PcnView view="item-setup" {...props}>
                {viewProps => (
                  <ItemSetupContainer
                    {...viewProps}
                    referrer={crumbs.current?.[crumbs.current.length - 2]}
                  />
                )}
              </PcnView>
            )
          }}
          allowed={[]}
        />
        <ProtectedRoute
          exact
          path="/:id/vendor-bids"
          component={props => {
            addToCrumbs(crumbs.current, props)
            return (
              <PcnView view="vendor-bids" {...props}>
                {viewProps => (
                  <VendorEventContainer
                    {...viewProps}
                    referrer={crumbs.current?.[crumbs.current.length - 2]}
                  />
                )}
              </PcnView>
            )
          }}
          allowed={[]}
        />
        <ProtectedRoute
          exact
          path="/:id/costing-bids"
          component={props => {
            addToCrumbs(crumbs.current, props)
            return (
              <PcnView view="vendor-costing-bids" {...props}>
                {viewProps => (
                  <VendorEventContainer
                    {...viewProps}
                    referrer={crumbs.current?.[crumbs.current.length - 2]}
                  />
                )}
              </PcnView>
            )
          }}
          allowed={[]}
        />
        <ProtectedRoute
          exact
          path="/:id/manage-files"
          component={props => {
            addToCrumbs(crumbs.current, props)
            return (
              <PcnView view="manage-files" {...props}>
                {viewProps => <VendorEventContainer {...viewProps} />}
              </PcnView>
            )
          }}
          allowed={[]}
        />
        <ProtectedRoute
          exact
          path="/:id/vendor-alignment"
          component={props => {
            addToCrumbs(crumbs.current, props)
            return (
              <PcnView view="vendor-alignment" {...props}>
                {viewProps => <VendorEventContainer {...viewProps} />}
              </PcnView>
            )
          }}
          allowed={[]}
        />
      </Switch>
    </Suspense>
  )
}
export default ExternalAppRoutes
