import axios, { AxiosRequestConfig } from 'axios/index'
import { getAppConfig } from '../config/appConfig'
import { getApiKey } from '../config/apiKey'
import Cookies from 'js-cookie'
import { getStore } from '../store/store'
import { setChallengeAuthenticationAction } from '../store/auth/actionCreator'

import axiosRetry from 'axios-retry'
import { analyticsService } from '../services/analytics-service'

const initAxios = () => {
  axios.defaults.baseURL = getAppConfig().REACT_APP_SERVICE_URL
}

const AxiosUtil = axios.create()
axiosRetry(AxiosUtil, { retries: 0 })
let requestInterceptorId = 0
let responseInterceptorId = 0
let angularRequestInterceptorId = 0
let angularResponseInterceptorId = 0

interface ConfigWithId extends AxiosRequestConfig {
  id: number
}

const addAxiosInterceptors = () => {
  //If interceptors already exist, eject them to avoid duplication of interceptors
  AxiosUtil.interceptors.request.eject(requestInterceptorId)
  AxiosUtil.interceptors.response.eject(responseInterceptorId)
  requestInterceptorId = AxiosUtil.interceptors.request.use(
    config => {
      if (isSSOSessionActive()) {
        // add auth headers only if sso session is alive
        if (localStorage.getItem('access_token')) {
          config.headers.Authorization = localStorage.getItem('access_token')
        }
        if (localStorage.getItem('id_token')) {
          config.headers.id_token = localStorage.getItem('id_token')
        }
      }
      config.params = { key: getApiKey() }
      try {
        ;(config as ConfigWithId).id = analyticsService.requestTimerStart(
          config.url!
        )
      } catch (error) {
        console.error('Error while tracking analytics call', error)
      }
      return config
    },
    error => {
      analyticsService.axiosException(error)
      return error
    }
  )

  responseInterceptorId = AxiosUtil.interceptors.response.use(
    response => {
      analyticsService.requestTimerStop((response?.config as ConfigWithId)?.id)
      return decodeHtmlResponseData(response)
    },
    error => {
      if (401 === error.response.status) {
        dispatch(setChallengeAuthenticationAction(true))
      }
      analyticsService.requestTimerStop(
        (error?.response?.config as ConfigWithId)?.id
      )
      analyticsService.axiosException(error)
      throw error
    }
  )
}

const addAxiosInterceptorsForAngular = () => {
  //If interceptors already exist, eject them to avoid duplication of interceptors
  AxiosUtil.interceptors.request.eject(angularRequestInterceptorId)
  AxiosUtil.interceptors.response.eject(angularResponseInterceptorId)
  angularRequestInterceptorId = AxiosUtil.interceptors.request.use(
    config => {
      if (localStorage.getItem('satellizer_token')) {
        config.headers.Authorization =
          'Bearer ' + localStorage.getItem('satellizer_token')
      }
      if (localStorage.getItem('satellizer_id_token')) {
        config.headers.id_token = localStorage.getItem('satellizer_id_token')
      }
      config.params = { key: getApiKey() }
      try {
        ;(config as ConfigWithId).id = analyticsService.requestTimerStart(
          config.url!
        )
      } catch (error) {
        console.error('Error while tracking analytics call', error)
      }
      return config
    },
    error => {
      analyticsService.axiosException(error)
      return error
    }
  )

  angularResponseInterceptorId = AxiosUtil.interceptors.response.use(
    response => {
      analyticsService.requestTimerStop((response?.config as ConfigWithId)?.id)
      return decodeHtmlResponseData(response)
    },
    error => {
      if (401 === error.response.status) {
        dispatch(setChallengeAuthenticationAction(true))
      }
      analyticsService.requestTimerStop(
        (error?.response?.config as ConfigWithId)?.id
      )
      analyticsService.axiosException(error)
      throw error
    }
  )
}

const decodeHtmlResponseData = response => {
  for (const key in response) {
    if (!Object.hasOwnProperty.call(response, key)) {
      continue
    }
    if (typeof response[key] === 'object') {
      response[key] = decodeHtmlResponseData(response[key])
    } else if (typeof response[key] === 'string') {
      response[key] = decodeHtml(response[key])
    }
  }
  return response
}

const decodeHtml = html => {
  return html
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&#34;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
}

const isSSOSessionActive = () => {
  let ssoSessionActive = false
  const tokenUserName = getUserIdFromAccessToken()
  const ssoSessionCookie = Cookies.get('SSOSESSIONINFO')
  if (ssoSessionCookie && ssoSessionCookie.length > 0) {
    const ssoSessionCookieJson = JSON.parse(atob(ssoSessionCookie))
    ssoSessionActive = ssoSessionCookieJson.login_status
    const sessionCookieUserName = ssoSessionCookieJson.user
    if (tokenUserName && sessionCookieUserName) {
      ssoSessionActive =
        ssoSessionActive &&
        sessionCookieUserName.toUpperCase() === tokenUserName.toUpperCase()
    }
  }
  return ssoSessionActive
}

export const getUserIdFromAccessToken = () => {
  const accessToken =
    localStorage.getItem('access_token') ??
    localStorage.getItem('satellizer_token')
  if (accessToken) {
    const userDetails = accessToken.split('.')[1]
    if (userDetails) {
      const userDetailsJSON = JSON.parse(atob(userDetails))
      return userDetailsJSON.username
    }
  }
  return null
}

const dispatch = dispatchable => {
  const store = getStore()
  store.dispatch(dispatchable)
}

export {
  AxiosUtil,
  initAxios,
  addAxiosInterceptors,
  addAxiosInterceptorsForAngular,
}
