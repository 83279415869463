import * as greenfieldActionTypes from './actionTypes'

const initialState = {
  greenfieldViewerFilters: [],
  greenfieldColumns: {},
  greenfieldData: [],
  greenfieldTotalsData: [],
  isFetchingGreenfieldMetadata: false,
  isFetchingGreenfieldData: false,
  currentGreenfieldRequest: null,
}

export const greenfieldReducer = (
  state: any = initialState,
  action: { type?: string; payload?: any } = {}
) => {
  switch (action.type) {
    case greenfieldActionTypes.FETCH_GREENFIELD_METADATA: {
      return { ...state, isFetchingGreenfieldMetadata: true }
    }
    case greenfieldActionTypes.FETCH_GREENFIELD_METADATA_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        greenfieldViewerFilters: payload.card_query_attribute.viewer_filters,
        greenfieldColumns: payload.card_query_attribute.columns.filter(
          //These fields are derived in the grid as we need weighted averages
          col =>
            col.field_name !== 'pmu_a' &&
            col.field_name !== 'net_pmu_a' &&
            col.field_name !== 'initial_markup_a'
        ),
        fieldToRefIdMap: payload.card_query_attribute.columns.reduce(
          (acc, col) => {
            acc[col.field_name] = col.ref_id
            return acc
          },
          {}
        ),
        isFetchingGreenfieldMetadata: false,
      }
    }
    case greenfieldActionTypes.FETCH_GREENFIELD_METADATA_ERROR: {
      return {
        ...state,
        isFetchingGreenfieldMetadata: false,
        greenfieldColumns: {},
        fieldToRefIdMap: {},
        greenfieldViewerFilters: {},
      }
    }
    case greenfieldActionTypes.FETCH_GREENFIELD_DATA: {
      return { ...state, isFetchingGreenfieldData: true }
    }
    case greenfieldActionTypes.FETCH_GREENFIELD_DATA_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        greenfieldData: payload.data.query_results,
        greenfieldTotalsData: payload?.data?.total_results?.[0],
        isFetchingGreenfieldData: false,
        currentGreenfieldRequest: payload.request,
      }
    }
    case greenfieldActionTypes.FETCH_GREENFIELD_DATA_ERROR: {
      return {
        ...state,
        isFetchingGreenfieldData: false,
        greenfieldData: [],
        currentGreenfieldRequest: null,
      }
    }

    case greenfieldActionTypes.RESET_GREENFIELD_ACTION: {
      return {
        ...state,
        greenfieldViewerFilters: [],
        greenfieldColumns: {},
        greenfieldData: [],
        greenfieldTotalsData: [],
      }
    }

    default:
      return state
  }
}
