import { getEnvConfig } from '@praxis/component-runtime-env'
import { merge } from 'lodash'
import angularConfig from './angularConfig'
import { commonConfig } from './commonConfig'

let appConfig = {}

const setAppConfig = appCnfg => {
  appConfig = appCnfg
  return appCnfg
}

const getAppConfig = () => {
  return appConfig
}

const getEnvConfigLocation = environment => {
  switch (environment) {
    case 'external':
      return '/config/external.json'
    case 'internal':
      return '/config/internal.json'
    case 'impersonation':
      return '/config/impersonation.json'
    default:
      return '/app-environment'
  }
}

const initializeAppConfig = async ({ isAngular, edgeBaseUrlForAngular }) => {
  if (isAngular) {
    const config = await angularConfig(edgeBaseUrlForAngular)
    return setAppConfig(config)
  } else {
    const envConfig = await getEnvConfig(
      getEnvConfigLocation(process.env.REACT_APP_PCN_ENV)
    )
    const config = merge(commonConfig, envConfig)
    return setAppConfig(config)
  }
}

export { initializeAppConfig, getAppConfig, setAppConfig }
