import React from 'react'
import { makeStyles } from '@material-ui/core/'
import { NavBar } from '../components/NavBar'
import { ExternalNavRoutes } from '../components/NavBar'
import { AuthProvider } from '@praxis/component-auth'
import { getAppConfig } from '../config/appConfig'
import ExternalAppRoutes from '../routes/ExternalAppRoutes'
import { BrowserRouter as Router } from 'react-router-dom'

const useStyles = makeStyles({
  appContainer: {},
})

const ExternalAppLayout = () => {
  const classes = useStyles()
  const config = getAppConfig()
  const authConfig = config.auth
  return (
    <div className={classes.appContainer}>
      <AuthProvider
        clientId={authConfig.clientId}
        authorizationUrl={authConfig.authorizationUrl}
        logoutUrl={authConfig.logoutUrl}
      >
        <Router>
          <NavBar
            navRoutes={ExternalNavRoutes}
            clearSearchCriteriaOnNavigate={true}
          />
          <ExternalAppRoutes />
        </Router>
      </AuthProvider>
    </div>
  )
}

export default ExternalAppLayout
