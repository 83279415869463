import * as vendorsActionTypes from './actionTypes'

const initialState = {
  vendorsByEventId: {},
  isFetchingVendors: false,
  bids: {},
  bpIds: [],
  isFetchingPickedNLCItems: false,
  pickedNLCItems: [],
  pickedNLCItemsCount: 0,
}

export const vendorsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case vendorsActionTypes.FETCH_VENDORS_FOR_EVENT: {
      return {
        ...state,
        isFetchingVendors: true,
      }
    }
    case vendorsActionTypes.FETCH_VENDORS_FOR_EVENT_SUCCESS: {
      let bpIds = []
      const bids = {}
      Object.entries(action.payload).forEach(([key, value]) => {
        value.bids.map(
          vendor =>
            (bids[vendor.id] = {
              name: vendor.name,
              bp_id: vendor.bp_id,
              round_start_time: vendor.round_start_time,
            })
        )
        bpIds = value.bids.map(vendor => vendor.bp_id)
      })
      const vendorsByEventIdTemp = {
        ...state.vendorsByEventId,
        ...action.payload,
      }
      return {
        ...state,
        isFetchingVendors: false,
        vendorsByEventId: vendorsByEventIdTemp,
        bids: bids,
        bpIds: bpIds,
      }
    }
    case vendorsActionTypes.FETCH_VENDORS_FOR_EVENT_ERROR: {
      return {
        ...state,
        isFetchingVendors: false,
        vendorsByEventId: {},
        bids: {},
        bpIds: [],
      }
    }

    case vendorsActionTypes.EXPORT_VENDOR_CONTACTS_FOR_EVENT: {
      return {
        ...state,
        isDownloading: true,
      }
    }
    case vendorsActionTypes.EXPORT_VENDOR_CONTACTS_FOR_EVENT_SUCCESS: {
      return {
        ...state,
        isDownloading: false,
      }
    }
    case vendorsActionTypes.EXPORT_VENDOR_CONTACTS_FOR_EVENT_ERROR: {
      return {
        ...state,
        isDownloading: false,
      }
    }
    case vendorsActionTypes.FETCH_PICKED_NLC_ITEMS: {
      return {
        ...state,
        isFetchingPickedNLCItems: true,
        pickedNLCItems: [],
        pickedNLCItemsCount: 0,
      }
    }
    case vendorsActionTypes.FETCH_PICKED_NLC_ITEMS_SUCCESS: {
      return {
        ...state,
        isDownloading: false,
        isFetchingPickedNLCItems: false,
        pickedNLCItems: action.payload.values,
        pickedNLCItemsCount: action.payload.count,
      }
    }
    case vendorsActionTypes.FETCH_PICKED_NLC_ITEMS_ERROR: {
      return {
        ...state,
        isFetchingPickedNLCItems: false,
        pickedNLCItems: [],
        pickedNLCItemsCount: 0,
      }
    }

    default:
      return state
  }
}
