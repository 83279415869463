const EventConstants = {
  EVENT_TYPE: 'eventType',

  PLAN_ID: 'planId',

  LABEL: 'label',

  VENDOR_NAME: 'vendorName',

  NEGOTIATION_TYPE: {
    VENDOR: 'vendor',
    PARTNER: 'businessPartner',
  },
  CYCLE: [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
  ],

  QUATER: [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
  ],

  KEY_DOWN: 'keydown',

  KEY_BACKSPACE: 8,

  KEY_DELETE: 46,

  DEFAULT_VIEW_ID: 1,

  FOB_UNIT_COST_COUNTRIES: [
    'china',
    'cambodia',
    'vietnam',
    'india',
    'indonesia',
  ],

  MESSAGE_DPCI:
    "Some DPCI's are still being processed and details are being fetched from IMN. " +
    ' This button will automatically be enabled once all details are available.',

  TARGET_CMT: 'Target',

  VENDOR_CMT: 'Vendor',

  TARGET: 'TARGET',

  FILES_FOR: 'Files for: ',

  FILE_STATUS_ACTIVE: 'active',
  FILE_STATUS_FAILED: 'failed',
  FILE_STATUS_SUCCESS: 'success',

  GET_EVENTS: 'internal/events/v1/event/user/',
  GET_ALL_USER_EVENTS: 'internal/events/v1/event/getAllUserEvents',

  EVENT: 'internal/events/v1/event/',

  PLAN_EVENT: 'internal/events/v1/event/plan/',

  ADD_EVENT: 'internal/events/v1/event',

  COPY: 'copy',

  SEARCH_EVENTS: 'searchEvents',

  GET_VALID_USER_BY_EMAIL: 'internal/userDetails?email=',

  GET_WORKER_DATA: '/internal/workerData',

  GET_VALID_USER_BY_ID: 'internal/userDetails?userId=',

  COMMON: 'common/events/v1/',

  ITEM_BP: 'common/events/v1/itemBps',

  ITEM_BP_EXCEL: 'common/events/v1/itemBps/excel',

  VENDOR_CONTACTS_EXCEL: 'common/events/v1/event/excelDownload',

  FEEDBACK_HIGHLIGHT: 'common/events/v1/vendor/feedback/highlight',

  ALL_FEEDBACK: '/common/events/v1/mobile/feedback/all',

  INTERNAL_ITEM_BP: 'internal/v1/itemBps',

  VENDOR_EVENTS: 'external/events/v1/vendor',

  VENDOR_EVENTS_SEARCH: 'external/events/v1/searchEvents',

  VENDOR_ISU_EVENTS: 'external/events/v1/isuvendor',

  BID_UPDATE: 'external/events/v1/bids',

  PLANNED_PRODUCTS: 'internal/events/v1/event/plannedProducts?',

  BUYER: 'I',

  VENDOR: 'E',

  VENDOR_ATTRIBUTES: '/vendor/vmmAttributes/?bidId=',

  CLAZZ: 'event/vendor/clazz?dept=',

  SUBCLAZZ_BY_DEPT_AND_CLASS: 'event/getSubClassByDeptClazz',

  MERCH_TYPE: 'merchTypes?dept=',

  ITEM_TYPE: 'itemTypes?merchTypeId=',

  DYNAMIC_MASTER_IDS: 'event/masterApexIds',

  STANDARD_ASSORTED_MASTER_IDS:
    'event/masterApexIds?standard_assorted_item_only=true',

  STANDARD_MASTER_IDS: 'event/masterApexIds?standard_item_only=true',

  SIZES_BY_ITEM_TYPE: 'getPimAttributes?attributeType=size&itemTypeId=',

  SIZES_BY_MERCH_TYPE: 'getPimAttributes?attributeType=size&merchTypeId=',

  USER_SETTING: 'internal/v1/userSetting/',

  ATTRIBUTE_SUMMARY: 'common/events/v1/attributeSummary',

  UNSAVED_MESSAGE:
    'Unsaved data will be lost by performing this action. Please click “Go Back” to return to the main screen and click “Save”.',

  NO_VENDORS_FOR_EVENT: 'No business partners have been added to this event.',

  REMOVE_EVENT_CONFIRM_MESSAGE:
    'Are you sure you want to remove this event from Events list?',

  ADD_EVENT_CONFIRM_MESSAGE:
    'Are you sure you want to add this event to Events list?',

  NOTFOUND_MESSAGE:
    'No search results found. Kindly try searching in next page',

  INITIATED: 'initiated',

  SUBMITTED_TO_BUSINESS_PARTNER: 'submitted_to_business_partner',

  ONLY_USER_EVENTS: '&only_user_events=',

  PAGE: '?page=',

  PER_PAGE: '&per_page=',

  EVENT_NAME: '&event_name=',

  FIRST_LAST_NAME: '&first_last_name=',

  PLAN: 'internal/v1/plans',

  COMMON_PLAN: 'common/plans/v1',

  COMMON_DEPARTMENT: 'common/departments',

  COMMON_CLASS: 'common/classes',

  POTENTIAL_ITEMS: 'internal/v1/potentialItems/',

  ITEM_DRILL_STATUS: 'common/events/v1/itemBps/itemsDrillStatus',

  ITEM_BY_ITEMID: 'common/events/v1/item/id',

  LINE_PLAN_PROGRESS: 'internal/v1/potentialItems/lineplanProgress',

  EVENT_ID: '&eventId=',

  BP_ID: '&bp_id=',

  PRODUCT_DESCRIPTION: 'section_data.item.description',

  AG_GROUP_CELL_RENDERER: 'agGroupCellRenderer',

  COMPARE_BIDS_WITH_TREE_VIEW: 'compareBidsWithTreeView',

  PICK: 'buyers_choice',

  EXPOSE_TO_BP: 'expose_to_bp',

  PRODUCT_ITEM_CREATE_TYPE: 'section_data.buyer_owned.item_create_type',

  ISU_COUNT: 'external/events/v1/isuproducts/count',

  VALIDATE_FOR_ISU: 'common/events/v1/itemBps/validate_item_bps',

  UNCLAIMED_PLAN_MESSAGE:
    'All products in this Business Partner must be associated to a plan before submitting.',

  HIDDEN_FROM_VENDOR_VIEW: 'This attribute is hidden from vendor view.',

  ATTRIBUTE_NOT_ADDED_MESSAGE:
    'This attribute is in your view, but not added to the event. Please go to Manage Event and add it.',

  REQUIRED_FOR_ISU_VALIDATION: [
    'commitment_date',
    'business_partner_contact_name',
    'business_partner_contact_email',
    'merch_type_id',
    'merch_type_name',
    'item_type_id',
    'item_type_name',
    'store_set_date',
    'digital_launch_date',
    'brand',
    'item_create_type',
    'assortment_type',
    'selling_channel',
    'bar_code_type',
    'master_casepack_unit',
    'master_depth',
    'master_width',
    'master_height',
    'master_unit_of_measure',
    'master_weight',
    'master_weight_uom',
    'inner_casepack_unit',
    'inner_casepack_unit',
    'inner_width',
    'inner_height',
    'inner_depth',
    'inner_unit_of_measure',
    'inner_weight',
    'inner_weight_uom',
    'import_designation',
    'upc_or_bar_code',
  ],
  REQUIRED_FOR_ISU_VALIDATION_OWNED_BRAND_VIEW: ['color', 'factory_name'],

  ISU_NON_NULL_CHECK_ATTRS: [
    'section_data.item.bar_code_type',
    'section_data.vendor_order_point_product_casepack.master_casepack_unit',
    'section_data.vendor_order_point_product_casepack.master_depth',
    'section_data.vendor_order_point_product_casepack.master_width',
    'section_data.vendor_order_point_product_casepack.master_height',
    'section_data.vendor_order_point_product_casepack.master_unit_of_measure',
    'section_data.vendor_order_point_product_casepack.master_weight',
    'section_data.vendor_order_point_product_casepack.master_weight_uom',
    'section_data.vendor_order_point_product_casepack.inner_casepack_unit',
    'section_data.vendor_order_point_product_casepack.inner_width',
    'section_data.vendor_order_point_product_casepack.inner_height',
    'section_data.vendor_order_point_product_casepack.inner_depth',
    'section_data.vendor_order_point_product_casepack.inner_unit_of_measure',
    'section_data.vendor_order_point_product_casepack.inner_weight',
    'section_data.vendor_order_point_product_casepack.inner_weight_uom',
    'section_data.shipping_location.import_designation',
  ],

  ISU_OWN_BRAND_NON_NULL_CHECK_ATTRS: [
    'section_data.shipping_location.factory_name',
  ],

  BID_ID: '&bidId=',

  EVENT_BID: 'common/events/v1/bid/',

  EXT_RELEASES: 'external/v1/releases',

  EVENT_FILES: 'common/events/v1/event/files',
  FILE_TYPES: [
    '.bmp',
    '.gif',
    '.jpeg',
    '.jpg',
    '.tex',
    '.xls',
    '.xlsx',
    '.doc',
    '.docx',
    '.odt',
    '.txt',
    '.pdf',
    '.png',
    '.pptx',
    '.ppt',
    '.rtf',
    '.dwg',
    '.dxf',
    '.dgn',
    '.stl',
  ],
  EVENT_FILES_URLS: 'internal/events/v1/event/files/urls',
  EVENT_FILES_SHARES: 'internal/events/v1/event/files/shares',
  EVENT_FILES_DOWNLOADS: 'external/events/v1/event/files/downloads',
  IMPORT_JOB: 'external/events/v1/event/import/job',
  IMPORT_JOB_INPUT_FILE: 'external/events/v1/event/import/inputFile',
  IMPORT_JOB_ERROR_FILE: 'external/events/v1/event/import/errorFile',

  IMPORT_DATA_USERS: [
    'bab92959',
    'bab92963',
    'bab93033',
    'bab93035',
    'bab93036',
    'bab93038',
    'bab93253',
    'bac11660',
    'bac17298',
    'bac19004',
    'bab80652',
    'bab80655',
    'bab80663',
    'bab80664',
    'bab80665',
    'bab80666',
    'bab80667',
    'bab80668',
    'bab80832',
    'bab81309',
    'bab81362',
    'bab81364',
    'bab81371',
    'bab81376',
    'bab81404',
    'bab81405',
    'bab81411',
    'bab81422',
    'bab81448',
    'bab81461',
    'bab81474',
    'bab87055',
    'bab88159',
    'bab92167',
    'bab92504',
    'bab92990',
    'bab94950',
    'bab94961',
    'bab96607',
    'bab97243',
    'bab97257',
    'bac12643',
    'bac14527',
    'bac18617',
    'bac20469',
    'bac24917',
    'baa94613',
    'baa95765',
    'baa97184',
    'baa97599',
    'baa98801',
    'baa99386',
    'baa99445',
    'baa99518',
    'bab09851',
    'bab12644',
    'bab18491',
    'bab20481',
    'bab20898',
    'bab22272',
    'bab22627',
    'bab31969',
    'bab33165',
    'bab33179',
    'bab39304',
    'bab47737',
    'bab50538',
    'bab52216',
    'bab57077',
    'bab60016',
    'bab66704',
    'bab72966',
    'bab77068',
    'bab82610',
    'bab90259',
    'bac14112',
    'bab86899',
    'bab86903',
    'bab86904',
    'bab86994',
    'bab87000',
    'bab87011',
    'bab87012',
    'bab87014',
    'bab87981',
    'bab88234',
    'bab88618',
    'bab89753',
    'bab91113',
    'bab91183',
    'bab92027',
    'bab93834',
    'bac02093',
    'bac08673',
    'bac13771',
    'bac19870',
    'baa16023',
    'bab20910',
    'bac23341',
    'baa69857',
    'bac23342',
    'bab71073',
    'bab71074',
    'bab71124',
    'bab71402',
    'bab71825',
    'bab71826',
    'bab71831',
    'bab71926',
    'bab71973',
    'bab72186',
    'bab72217',
    'bab72341',
    'bab72487',
    'bab72523',
    'bab72744',
    'bab72745',
    'bab72749',
    'bab72766',
    'bab72784',
    'bab72787',
    'bab72803',
    'bab72818',
    'bab72833',
    'bab72854',
    'bab72894',
    'bab72898',
    'bab73457',
    'bab93000',
    'bab94951',
    'bab94960',
    'bac05496',
    'bac05498',
    'bac08334',
    'bac08672',
    'bac22499',
    'bac22537',
    'bac24625',
    'bac29209',
  ],
  // use lower case only
  IMPORT_EXCEL_COLUMNS_EXCLUDED: [
    'dept',
    'class',
    'subclass name',
    'import vendor name',
    'domestic vendor name',
    'dvs vendor name',
    'regular description',
    'new store description',
    'port of export',
    'fca factory city',
    'factory name / factory id ',
  ],

  MANAGE_ATTRIBUTES_VALIDATION: {
    REQUIRED_COLUMNS: ['item.dept', 'item.clazz', 'item.description'],
    ALWAYS_VISIBLE_COLUMNS: ['item.dept', 'item.clazz'],
    ALWAYS_VENDOR_HIDDEN_COLUMNS: [
      'freight_cost',
      'net_cost',
      'avg_net_cost',
      'imu',
      'pmu',
      'net_cogs_savings',
      'cogs_savings_comments',
      'net_pmu',
      'fees',
      'import_poex_freight_rate',
      'factory_net_cost',
      'port_of_export_net_cost',
      'import_poex_freight_rate_override',
      'fca_poex_freight_rate',
      'fca_poex_freight_rate_override',
      'fca_freight_rate',
      'fca_freight_rate_override',
      'port_to_dc_rate',
      'duty_cost',
      'factory_freight_cost',
      'import_freight_cost',
      'domestic_freight_rate',
      'domestic_freight_rate_override',
      'collect_domestic_freight_cost',
      'prepaid_domestic_net_cost',
      'collect_domestic_net_cost',
      'direct_to_store_delivery_net_cost',
    ],
    SYNCHRONIZED_ATTRIBUTES: [['factory_name', 'factory_id']],
  },

  MANAGE_ATTRIBUTES_HIDDEN_COLUMNS: [
    'merch_type_id',
    'item_type_id',
    'prop_65_flag',
    'strat_mta_nb_assort',
    'product_title',
    'collection_direction',
    'collection',
    'variation_parent',
    'primary_image',
    'vap_color',
    'subscription_eligible_flag',
    'return_method',
    'business_partner_contact_name',
    'business_partner_contact_email',
    'master_unit_of_measure',
    'inner_unit_of_measure',
    'master_weight_uom',
    'inner_weight_uom',
    'like_item_description',
    'like_item_department',
    'like_item_class',
    'like_item_item_number',
    'like_item_tcin',
    'new_store_description',
    'regular_description',
    'line_review_notes',
    'effective_date',
    'program_week',
    'quarter',
    'subject_to_trade_remedies',
    // following used to be MANAGE_ATTRIBUTES_ALWAYS_HIDDEN_COLUMNS
    // AND used to be NOT_REQUIRED_ROOT_COLUMNS_FOR_MANAGE_VIEWS
    'apex_id',
    'item_setup',
    'plan_id',
    'plan_name',
    'sent_to_sharepoint',
    'system_status_value',
    'level',
    'event_id',
    'event_name',
    'bp_id',
    'bp_name',
    'bp_isu_ready',
    'buyers_choice',
    'expose_to_bp',
    'final_assorted',
    'pcn_item_id',
    'bulk_vendor_income',
    'business_confirmed',
    'atl_started',
    // Below was not in NOT_REQUIRED_ROOT_COLUMNS_FOR_MANAGE_VIEWS, but that was likely out of date
    'picked_date',
    'aligned_date',
    'create_time',
    'item_level_status',
    'pid',
    'retail_ticket_indicator',
  ],

  MANAGE_ATTRIBUTES_HIDDEN_PMU_COLUMNS: [
    //exlude these from manage attributes wizard
    //they will be added later in validate pmu
    'free_carrier_unit',
    'import_unit',
    'prepaid_domestic_unit',
    'collect_domestic_unit',
    'direct_to_store_delivery_unit',
    'shipping_unit',
    'est_total',
    'vendor_est_total_reciept_units',
    'duty_rate',
    'factory_unit_cost',
    'port_of_export_unit_cost',
    'factory_city',
    'prepaid_domestic_unit_cost',
    'collect_domestic_unit_cost',
    'direct_to_store_delivery_cost',
    'vendor_ship_point',
    'est_total_reciept_units',
  ],

  CUSTOM_ATTRIBUTES_SECTION_ID: 'custom_attributes',
  CUSTOM_ATTRIBUTES_SECTION_OTHERS_ID: 'custom_attributes_others',
  CUSTOM_ATTRIBUTES_CREATED_BY_USER_SECTION_NAME:
    'Custom Attributes Created by You',
  CUSTOM_ATTRIBUTES_CREATED_BY_OTHERS_SECTION_NAME:
    'Custom Attributes Created by Other Authorized Users',

  EVENT_ATTRIBUTE_STATUS_FILTER_NAMES: [
    { id: 0, text: 'None', status: null },
    { id: 1, text: 'Editable for all users', status: ['E'] },
    { id: 2, text: 'Mandatory for vendors', status: ['M'] },
    { id: 3, text: 'Read only for vendors', status: ['R'] },
    { id: 4, text: 'Hidden from vendors', status: ['H'] },
    { id: 5, text: 'Visible to all vendors', status: ['E', 'M', 'R'] },
  ],
}

export default EventConstants
