import { AxiosUtil } from '../utils/axios-util'
import { convertDateToUtcDay } from '../utils/DateUtil'
import {
  errorNotification,
  errorResponseNotification,
  successNotification,
} from './notification-service'
import EventConstants from '../constants/EventConstants'
import { hasDefinedNonNullProps } from '../utils/PropsValidationUtil'
import { analyticsService, EventCategories } from './analytics-service'

const getPageUrl = (page, perPage, bpId) => {
  return page + '&per_page=' + perPage + '&bpId=' + bpId
}

const getValidEvents = (eventList: any[], propsList: string[]) => {
  return eventList.reduce((eventsWithValidProps, event) => {
    if (hasDefinedNonNullProps(event, propsList)) {
      event.event_name_id = `${event.event_name} (${event.event_id})`
      eventsWithValidProps.push(event)
    }
    return eventsWithValidProps
  }, [])
}

export const archiveEvents = (eventObjectId, bidId) =>
  AxiosUtil.put(
    'common/events/v1/archiveEvent/' + eventObjectId + '?bid_id=' + bidId,
    null,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      successNotification(['Successfully Archived'])
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })

export const unarchiveEvents = (eventObjectId, bidId, eventName) =>
  AxiosUtil.put(
    'common/events/v1/unarchiveEvent/' + eventObjectId + '?bid_id=' + bidId,
    null,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      successNotification([`Event ${eventName} unarchived successfully.`])
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })

export const submit = (
  bid,
  successMessage,
  errorMessage,
  sendEmailFeed = false
) =>
  AxiosUtil.put(
    `external/events/v1/bids/${bid.id}?sendEmailFeed=${sendEmailFeed}`,
    bid,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (successMessage) {
        successNotification([successMessage])
      }
      return res
    })
    .catch(err => {
      if (errorMessage) {
        errorNotification(errorMessage)
      } else {
        errorResponseNotification(err)
      }
      throw err
    })

export const createEvent = (
  name: string,
  developmentCycle: string | null,
  fiscalQuarter: string | null,
  launchDate: Date | null,
  storeSetDate: Date | null,
  isApparelEvent: boolean | null
) => {
  const requestBody = {
    event_name: name,
    development_cycle: developmentCycle,
    quarter: fiscalQuarter,
    launch_date: launchDate ? convertDateToUtcDay(launchDate) : null,
    store_set_date: storeSetDate ? convertDateToUtcDay(storeSetDate) : null,
    level: isApparelEvent,
  }

  return AxiosUtil.post(EventConstants.EVENT, requestBody).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getEvents = (page, perPage, bpId, sortByBidTs, archivedEvents) => {
  let url = '/external/events/v1/vendor?page=' + getPageUrl(page, perPage, bpId)

  if (sortByBidTs) {
    url = url + '&sort_by_bid_ts=true'
  }
  if (archivedEvents) {
    url = url + '&archived_only=true'
  }
  return AxiosUtil.get(url)
    .then(resp => {
      // Filter out events that are missing props needed for views
      const validEvents = getValidEvents(resp.data.events, [
        'id',
        'bid_response',
        'event_name',
        'event_id',
        'event_counts',
      ])

      // If some events were filtered out, show error toast that informs the user not all events were loaded
      if (resp.data.events.length !== validEvents.length) {
        errorNotification(
          ['Unable to load some events. Refresh the page to try again.'],
          null
        )
      }
      resp.data.events = validEvents
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const searchEvents = (page, perPage, bpId, eventName, eventId) => {
  let url =
    'external/events/v1/searchEvents?page=' +
    getPageUrl(page, perPage, bpId) +
    '&show_archived_events=true&only_events_info=false'

  let analyticsAction = 'External Search'
  if (eventName && eventName !== '') {
    url = url + '&event_name=' + encodeURIComponent(eventName)
    analyticsAction = `Event Name ${analyticsAction}`
  }
  if (eventId && eventId !== '') {
    url = url + '&event_ids=' + eventId
    analyticsAction = `Event ID ${analyticsAction}`
  }

  analyticsService.event({
    category: EventCategories.SearchEvent,
    action: analyticsAction,
    label: `eventName: ${eventName}; eventId: ${eventId}`,
  })

  return AxiosUtil.get(url)
    .then(resp => {
      resp.data.events = resp.data.events.map(event => {
        event.event_name_id = `${event.event_name} (${event.event_id})`
        return event
      })
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const fetchEventContacts = eventId => {
  const url = 'external/events/v1/authUsers/' + eventId

  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getItemSetupEvents = (page, perPage, bpId) =>
  AxiosUtil.get(
    '/external/events/v1/isuvendor?page=' + getPageUrl(page, perPage, bpId)
  )
    .then(resp => {
      // Filter out ISU events that are missing props needed for the Item Setup view
      const validEvents = getValidEvents(resp.data.events, [
        'id',
        'bid_response',
        'event_name',
        'event_id',
      ])
      // If some events were filtered out, show error toast that informs the user not all events were loaded
      if (resp.data.events.length !== validEvents.length) {
        errorNotification(
          [
            'Unable to load some Pre-Item Setup events. Refresh the page to try again.',
          ],
          null
        )
      }
      resp.data.events = validEvents
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })

export const getBid = (bidId, bpId) => {
  const url = EventConstants.EVENT_BID + bidId + '?bpId=' + bpId

  return AxiosUtil.get(url)
    .then(res => {
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getEventAttributes = (eventId, bidId) => {
  let url = EventConstants.COMMON + 'attributes/' + eventId
  if (bidId) {
    url += '?bids=' + [bidId]
  }
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const updateEventAttributes = (eventId, bidId, requestBody) => {
  let url = EventConstants.COMMON + 'updateAttributes/' + eventId
  if (bidId) {
    url = url + '?bid_id=' + bidId
  }
  return AxiosUtil.put(url, requestBody).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getDistBps = bpId => {
  const url = '/external/events/v1/getDistBps?bpId=' + bpId
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getVendorsByBpIds = bpIds => {
  const url = 'external/vendorsByBps/' + bpIds
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}
