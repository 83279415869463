import * as authActionTypes from './actionTypes'
import { getAuthenticatedUser } from '../../services/user-service'

export const getUserAction = () => dispatch => {
  dispatch({ type: authActionTypes.FETCH_USER })

  getAuthenticatedUser()
    .then(res => {
      dispatch({
        type: authActionTypes.FETCH_USER_SUCCESS,
        payload: res,
      })
    })
    .catch(err => {
      dispatch({
        type: authActionTypes.FETCH_USER_ERROR,
        payload: err,
      })
    })
}

export const setChallengeAuthenticationAction =
  challengeAuthentication => dispatch => {
    dispatch({
      type: authActionTypes.CHALLENGE_AUTHENTICATION,
      payload: challengeAuthentication,
    })
  }

//This is required only till we have to integrate react pages to angular. Should be reomved once everything is in react
export const setUserType = userType => dispatch => {
  dispatch({
    type: authActionTypes.SET_USER_TYPE,
    payload: userType,
  })
}
