import * as navBarActionTypes from './actionTypes'

const initialState = {
  pageName: '',
}

export const navBarReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case navBarActionTypes.SET_PAGE_NAME: {
      const { payload } = action

      return {
        ...state,
        pageName: payload,
      }
    }

    default:
      return state
  }
}
