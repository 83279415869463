import * as rollupActionTypes from './actionTypes'

const initialState = {
  isFetchingRollupAttributes: false,
  isFetchingPivotViews: false,
  sections: [],
  pivotViews: [],
}
export const rollupReducer = (
  state = initialState,
  action: { type?: string; payload?: any } = {}
) => {
  switch (action.type) {
    case rollupActionTypes.FETCH_ROLLUP_ATTRIBUTES: {
      return {
        ...state,
        isFetchingRollupAttributes: true,
      }
    }
    case rollupActionTypes.FETCH_ROLLUP_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        isFetchingRollupAttributes: false,
        sections: action.payload.data.sections,
      }
    }
    case rollupActionTypes.FETCH_ROLLUP_ATTRIBUTES_ERROR: {
      return {
        ...state,
        isFetchingRollupAttributes: false,
        sections: [],
      }
    }
    case rollupActionTypes.FETCH_PIVOT_VIEWS: {
      return {
        ...state,
        isFetchingPivotViews: true,
      }
    }
    case rollupActionTypes.FETCH_PIVOT_VIEWS_SUCCESS: {
      return {
        ...state,
        isFetchingPivotViews: false,
        pivotViews: action.payload.data,
      }
    }
    case rollupActionTypes.FETCH_PIVOT_VIEWS_ERROR: {
      return {
        ...state,
        isFetchingPivotViews: false,
        pivotViews: [],
      }
    }
    case rollupActionTypes.RESET_ROLLUP: {
      return {
        ...state,
        isFetchingRollupAttributes: false,
        sections: [],
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
