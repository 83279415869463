export enum EventActions {
  Cancel = 'cancel',
  Close = 'close',
  Navigate = 'navigate',
  Open = 'open',
  OpenErrorPane = 'Open Error Pane',
  OpenWarningPane = 'Open Warning Pane',
  DownloadFile = 'Download File',
  DeleteFile = 'Delete File',
  OpenInfo = 'Open Info',
}
