import React from 'react'
import ExternalAppLayout from '../layouts/ExternalAppLayout'
import PcnApp from './PcnApp'

const ExternalApp = () => {
  return (
    <PcnApp>
      <ExternalAppLayout />
    </PcnApp>
  )
}

export default ExternalApp
