import * as claimOrReleaseProductsActionTypes from './actionTypes'

const initialState = {
  unclaimedProductsCount: {},
  unclaimedProducts: {},
}

export const claimOrReleaseProductsReducer = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case claimOrReleaseProductsActionTypes.FETCH_UNCLAIMED_PRODUCTS_COUNT: {
      return {
        ...state,
        unclaimedProductsCount: {
          ...state.unclaimedProductsCount,
          ...action.payload,
        },
      }
    }
    case claimOrReleaseProductsActionTypes.FETCH_UNCLAIMED_PRODUCTS: {
      return {
        ...state,
        unclaimedProducts: {
          ...state.unclaimedProducts,
          ...action.payload,
        },
      }
    }
    case claimOrReleaseProductsActionTypes.FETCH_UNCLAIMED_PRODUCTS_ERROR: {
      return state
    }
    default:
      return state
  }
}
