import React from 'react'

const AngularContext = React.createContext({ isAngular: false })

const withAngularContext = Component => {
  return props => (
    <AngularContext.Consumer>
      {value => <Component {...props} angularProps={value} />}
    </AngularContext.Consumer>
  )
}

export { AngularContext, withAngularContext }
