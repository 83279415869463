import { ExceptionArgs, googleAnalyticsService } from 'google-analytics-library'
import { gtagService, EventData, PageViewArgs } from 'gtag-service'
import { EventArgs, TimingArgs } from 'react-ga'
import { getAppConfig } from '../../config/appConfig'
import { getUserIdFromAccessToken } from '../../utils/axios-util'
import { EventCategories } from './EventCategories'

export interface AnalyticsService {
  event: (args: EventArgs) => void
  exception: (args: ExceptionArgs) => void
  pageView: (path: string) => void
  modalView: (name: string) => void
  timing: (args: TimingArgs) => void
}

const getUserId = () => {
  const userId = getUserIdFromAccessToken() ?? null
  if (userId !== null) {
    return btoa(userId)
  }
  return null
}

gtagService.configure({
  getTrackingId: () => getAppConfig()?.googleAnalytics?.gtagTrackingId ?? null,
  getUserId: getUserId,
})

googleAnalyticsService.configure({
  getTrackingId: () => getAppConfig()?.googleAnalytics?.trackingId ?? null,
  getUserId: getUserId,
  trackerName: 'pcnGaTracker',
})

const event = (args: EventArgs) => {
  googleAnalyticsService.event(args)
  gtagService.uaEvent({ ...args })
}

const customEvent = (action, data: EventData) => {
  gtagService.event(action, data)
}

export interface AgGridEventArgs {
  action: string
  label?: string
  value?: number
  nonInteraction?: boolean
}

const agGridEvent = (args: AgGridEventArgs) => {
  event({
    ...args,
    category: EventCategories.AgGrid,
  })
}

const exception = (args: ExceptionArgs) => {
  googleAnalyticsService.exception(args)
  gtagService.exception(args?.description ?? 'Exception', args.fatal ?? false)
}

const axiosException = (error: any) => {
  const message: string | null = error?.message ?? error?.data?.message
  exception({
    description: `${error?.response?.status}: ${message}`,
    fatal: false,
  })
}

const pageView = (args?: PageViewArgs) => {
  // eslint-disable-next-line no-restricted-globals
  googleAnalyticsService.pageView(args?.path ?? location.pathname)
  gtagService.pageView(args ?? {})
}

const pageViewTimings: { [path: string]: number } = {}

const pageNavigationTimerStart = (path: string) => {
  if (pageViewTimings[path]) {
    console.error(`Page Navigation overwrote timing for: ${path}`)
  }
  pageViewTimings[path] = window?.performance?.now()
}

const pageNavigationTimerStop = (path: string) => {
  const now = window?.performance?.now()
  if (pageViewTimings[path] && now) {
    const pageLoadTime = Math.round(now - pageViewTimings[path]!)
    timing({
      category: 'Page Navigation Time',
      variable: 'load',
      value: pageLoadTime,
      label: path,
    })
  }
  delete pageViewTimings[path]
}

const modalView = (name: string) => {
  googleAnalyticsService.modalView(name)
  gtagService.modalView(name)
}

const requestTimings: {
  [id: number]: {
    path: string
    startTime: number
  }
} = {}

const requestTimerStart = (path: string): number => {
  const id = window?.performance?.now()
  if (id) {
    requestTimings[id] = {
      path,
      startTime: id,
    }
  }
  return id
}

const requestTimerStop = (id: number) => {
  const now = window?.performance?.now()
  if (requestTimings[id] && now) {
    const requestTotalTime = Math.round(now - requestTimings[id].startTime)
    timing({
      category: 'Request Time',
      variable: 'request',
      value: requestTotalTime,
      label: requestTimings[id].path,
    })
  }
  delete requestTimings[id]
}

const timing = (args: TimingArgs): void => {
  googleAnalyticsService.timing(args)
  gtagService.timing({
    name: args.variable,
    value: args.value,
    eventCategory: args.category,
    eventLabel: args.label,
  })
}

export const analyticsService = {
  event,
  customEvent,
  agGridEvent,
  exception,
  axiosException,
  pageView,
  pageNavigationTimerStart,
  pageNavigationTimerStop,
  modalView,
  requestTimerStart,
  requestTimerStop,
  timing,
}
