export enum AttributeValuesActionType {
  FETCH_DEPT_CLASSES = 'FETCH_DEPT_CLASSES',
  FETCH_DEPT_CLASSES_SUCCESS = 'FETCH_DEPT_CLASSES_SUCCESS',
  FETCH_DEPT_CLASSES_ERROR = 'FETCH_DEPT_CLASSES_ERROR',

  FETCH_SUB_CLASSES = 'FETCH_SUB_CLASSES',
  FETCH_SUB_CLASSES_SUCCESS = 'FETCH_SUB_CLASSES_SUCCESS',
  FETCH_SUB_CLASSES_ERROR = 'FETCH_SUB_CLASSES_ERROR',

  FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES = 'FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES',
  FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES_SUCCESS = 'FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES_SUCCESS',
  FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES_ERROR = 'FETCH_PORT_OF_EXPORTS_AND_FACTORY_CITIES_ERROR',

  FETCH_ALL_COUNTRIES = 'FETCH_ALL_COUNTRIES',
  FETCH_ALL_COUNTRIES_SUCCESS = 'FETCH_ALL_COUNTRIES_SUCCESS',
  FETCH_ALL_COUNTRIES_ERROR = 'FETCH_ALL_COUNTRIES_ERROR',

  FETCH_MERCH_TYPE = 'FETCH_MERCH_TYPE',
  FETCH_MERCH_TYPE_SUCCESS = 'FETCH_MERCH_TYPE_SUCCESS',
  FETCH_MERCH_TYPE_ERROR = 'FETCH_MERCH_TYPE_ERROR',

  FETCH_ITEM_TYPE = 'FETCH_ITEM_TYPE',
  FETCH_ITEM_TYPE_SUCCESS = 'FETCH_ITEM_TYPE_SUCCESS',
  FETCH_ITEM_TYPE_ERROR = 'FETCH_ITEM_TYPE_ERROR',

  FETCH_SIZES = 'FETCH_SIZES',
  FETCH_SIZES_SUCCESS = 'FETCH_SIZES_SUCCESS',
  FETCH_SIZES_ERROR = 'FETCH_SIZES_ERROR',

  FETCH_MERCH_STYLES = 'FETCH_MERCH_STYLES',
  FETCH_MERCH_STYLES_SUCCESS = 'FETCH_MERCH_STYLES_SUCCESS',
  FETCH_MERCH_STYLES_ERROR = 'FETCH_MERCH_STYLES_ERROR',

  FETCH_VMM_ATTRIBUTES = 'FETCH_VMM_ATTRIBUTES',
  FETCH_VMM_ATTRIBUTES_SUCCESS = 'FETCH_VMM_ATTRIBUTES_SUCCESS',
  FETCH_VMM_ATTRIBUTES_ERROR = 'FETCH_VMM_ATTRIBUTES_ERROR',

  FETCH_DYNAMIC_MASTER_APEX_IDS = 'FETCH_DYNAMIC_MASTER_APEX_IDS',
  FETCH_DYNAMIC_MASTER_APEX_IDS_SUCCESS = 'FETCH_DYNAMIC_MASTER_APEX_IDS_SUCCESS',
  FETCH_DYNAMIC_MASTER_APEX_IDS_ERROR = 'FETCH_DYNAMIC_MASTER_APEX_IDS_ERROR',

  FETCH_STANDARD_APEX_IDS = 'FETCH_STANDARD_APEX_IDS',
  FETCH_STANDARD_APEX_IDS_SUCCESS = 'FETCH_STANDARD_APEX_IDS_SUCCESS',
  FETCH_STANDARD_APEX_IDS_ERROR = 'FETCH_STANDARD_APEX_IDS_ERROR',

  FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES = 'FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES',
  FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES_SUCCESS = 'FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES_SUCCESS',
  FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES_ERROR = 'FETCH_AUTOCOMPLETE_ATTRIBUTE_VALUES_ERROR',

  FETCH_DEPT_STANDARD_RET_POLICIES = 'FETCH_DEPT_STANDARD_RET_POLICIES',
  FETCH_DEPT_STANDARD_RET_POLICIES_SUCCESS = 'FETCH_DEPT_STANDARD_RET_POLICIES_SUCCESS',
  FETCH_DEPT_STANDARD_RET_POLICIES_ERROR = 'FETCH_DEPT_STANDARD_RET_POLICIES_ERROR',

  FETCH_COLOR_FAMILIES = 'FETCH_COLOR_FAMILIES',
  FETCH_COLOR_FAMILIES_SUCCESS = 'FETCH_COLOR_FAMILIES_SUCCESS',
  FETCH_COLOR_FAMILIES_ERROR = 'FETCH_COLOR_FAMILIES_ERROR',

  FETCH_PATTERN_FAMILIES = 'FETCH_PATTERN_FAMILIES',
  FETCH_PATTERN_FAMILIES_SUCCESS = 'FETCH_PATTERN_FAMILIES_SUCCESS',
  FETCH_PATTERN_FAMILIES_ERROR = 'FETCH_PATTERN_FAMILIES_ERROR',

  FETCH_VENDORS_FOR_BPIDS = 'FETCH_VENDORS_FOR_BPIDS',
  FETCH_VENDORS_FOR_BPIDS_SUCCESS = 'FETCH_VENDORS_FOR_BPIDS_SUCCESS',
  FETCH_VENDORS_FOR_BPIDS_ERROR = 'FETCH_VENDORS_FOR_BPIDS_ERROR',

  FETCH_PIDS = 'FETCH_PIDS',
  FETCH_PIDS_SUCCESS = 'FETCH_PIDS_SUCCESS',
  FETCH_PIDS_ERROR = 'FETCH_PIDS_ERROR',

  FETCH_PIDS_DETAILS = 'FETCH_PIDS_DETAILS',
  FETCH_PIDS_DETAILS_SUCCESS = 'FETCH_PIDS_DETAILS_SUCCESS',
  FETCH_PIDS_DETAILS_ERROR = 'FETCH_PIDS_DETAILS_ERROR',

  ATTRIBUTES_LOADING_SKIPPED = 'ATTRIBUTES_LOADING_SKIPPED',
}
