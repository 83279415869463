import { AxiosUtil } from '../utils/axios-util'
import { errorResponseNotification } from './notification-service'
import EventConstants from '../constants/EventConstants'

export const getClazz = deptIds => {
  const url = EventConstants.COMMON + EventConstants.CLAZZ + deptIds
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getSubClazz = deptClazz => {
  const url =
    EventConstants.COMMON + 'event/vendor/getSubClass?deptClazz=' + deptClazz
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getPortOfExportsAndFactoryCities = countryOfOrigins => {
  const url = EventConstants.COMMON + 'fcaPortOfExport'
  return AxiosUtil.post(url, countryOfOrigins).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getAllCountries = () => {
  const url = EventConstants.COMMON + 'countries'
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getMerchTypes = (deptIds, merchTypeIds) => {
  const url =
    EventConstants.COMMON +
    EventConstants.MERCH_TYPE +
    deptIds +
    '&merchTypeId=' +
    merchTypeIds
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getItemTypes = merchTypeId => {
  const url = EventConstants.COMMON + EventConstants.ITEM_TYPE + merchTypeId
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

const mapSizeItem = (itemTypeId, data) => {
  const sizeMap = {}
  //For Each Size Item get the Size Id, Size Group Id, and Size Name, then push to the sizeMap.
  if (data) {
    sizeMap[itemTypeId] = data.map(sizeData => {
      return {
        id: sizeData.fieldId,
        name: sizeData.name,
      }
    })
  }
  return sizeMap
}

export const getSizesByItemTypeId = itemTypeId => {
  const url =
    EventConstants.COMMON + EventConstants.SIZES_BY_ITEM_TYPE + itemTypeId

  return AxiosUtil.get(url)
    .then(resp => {
      resp.data = mapSizeItem(itemTypeId, resp.data)
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getMerchStyles = deptClazzSubclazz => {
  const url =
    EventConstants.COMMON + 'merchStyles?deptSubclassIds=' + deptClazzSubclazz
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getVMMAttributes = (eventId, bidId, vendorIds) => {
  const url =
    EventConstants.COMMON +
    'event/' +
    eventId +
    EventConstants.VENDOR_ATTRIBUTES +
    bidId +
    '&vendorId=' +
    vendorIds
  return AxiosUtil.get(url)
    .then(resp => {
      resp = modifyOrderSpecs(resp)
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

const mapOrderSpecs = orderSpec => {
  const deptId_OrderSpec = {}
  const spec: [string, [number, number[]][]][] = Object.entries(orderSpec)

  spec.forEach(([specName, altObj]) => {
    const altIdDept: any[] = Object.entries(altObj)
    altIdDept.forEach(([altId, depts]) => {
      depts.forEach(dept => {
        if (!deptId_OrderSpec[dept]) {
          deptId_OrderSpec[dept] = []
        }
        deptId_OrderSpec[dept].push({
          alternateId: altId,
          orderPointName: specName,
        })
      })
    })
  })
  return deptId_OrderSpec
}

interface VmmAttributeResponse {
  brands: [number, string][]
  countryOfOrigin: [string, number[]][]
  datingTerms: [
    number,
    { normalDescription: string; normalDays: number; normalPercentage: number }
  ][]
  factoryIdName: [number, string][]
  orderSpecs: [string, [number, number[]][]][]
  storeDatingTerms: [
    number,
    { storeDescription: string; storeDays: number; storePercentage: number }
  ][]
  vendorId: number
  vendorName: string
  vendorOrderPoints: { dept: number; shipLocation: string }[]
  vendorReturnPolicy: [
    number,
    { physicalDisposition: string; financialDisposition: string }
  ][]
  vendorType: string
}

const modifyOrderSpecs = resp => {
  const vendorData: [string, VmmAttributeResponse][] = Object.entries(resp.data)
  vendorData.forEach(([vendorId, attr]) => {
    const orderSpec = attr.orderSpecs
    resp.data[vendorId].orderSpecs = mapOrderSpecs(orderSpec)
  })
  return resp
}

export const getDynamicMasterMap = resp => {
  const dynamicMasterIdsForBps = {}
  if (resp.length > 0) {
    resp.forEach(masterApex => {
      dynamicMasterIdsForBps[masterApex.bp_id] = masterApex.values
    })
  }
  return dynamicMasterIdsForBps
}

export const getDynamicMasterApexIds = (eventId, bpIds) => {
  const url = EventConstants.COMMON + EventConstants.DYNAMIC_MASTER_IDS
  const dynamicMasterRequest = {
    event_id: eventId,
    bp_id: bpIds,
  }
  return AxiosUtil.post(url, dynamicMasterRequest).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getStandardApexIds = (eventId, bpIds) => {
  const url = EventConstants.COMMON + EventConstants.STANDARD_MASTER_IDS
  const standardAssortedRequest = {
    event_id: eventId,
    bp_id: bpIds,
  }
  return AxiosUtil.post(url, standardAssortedRequest).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}
export const getPids = (bpIds, deptIds) => {
  const url = EventConstants.COMMON + 'getPids'
  const requestBody = {
    bps: bpIds,
    departments: deptIds,
  }
  return AxiosUtil.post(url, requestBody).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getPidsDetails = pidIds => {
  const url = EventConstants.COMMON + 'getPidsInfo'
  const requestBody = {
    pidIds: pidIds,
  }
  return AxiosUtil.post(url, requestBody).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getAutocompleteAttributeValues = (
  searchText,
  attributeType,
  parentFamilyId
) => {
  let url =
    EventConstants.COMMON + 'getPimAttributes?attributeType=' + attributeType

  if (attributeType.includes('ship point')) {
    url =
      EventConstants.COMMON +
      'getDistributionCenters?attributeName=' +
      searchText +
      '&attributeType=' +
      attributeType
  } else if (parentFamilyId != null) {
    url = url + '&parentFamilyId=' + parentFamilyId
  } else {
    url = url + '&attributeName=' + searchText
  }

  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getDeptStandardReturnPolicies = () => {
  const url = EventConstants.COMMON + 'deptStdReturnPolicies'
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getColorFamilies = () => {
  const url =
    EventConstants.COMMON + 'getPimAttributes?attributeType=colorFamily'
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getPatternFamilies = () => {
  const url =
    EventConstants.COMMON + 'getPimAttributes?attributeType=patternFamily'
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}
