export const FETCH_CUSTOM_ATTRIBUTES = 'FETCH_CUSTOM_ATTRIBUTES'
export const FETCH_CUSTOM_ATTRIBUTES_SUCCESS = 'FETCH_CUSTOM_ATTRIBUTES_SUCCESS'
export const FETCH_CUSTOM_ATTRIBUTES_ERROR = 'FETCH_CUSTOM_ATTRIBUTES_ERROR'
export const RESET_CUSTOM_ATTRIBUTES = 'RESET_CUSTOM_ATTRIBUTES'
export const FETCH_NUMERIC_ATTRIBUTES = 'FETCH_NUMERIC_ATTRIBUTES'
export const FETCH_NUMERIC_ATTRIBUTES_SUCCESS =
  'FETCH_NUMERIC_ATTRIBUTES_SUCCESS'
export const FETCH_NUMERIC_ATTRIBUTES_ERROR = 'FETCH_NUMERIC_ATTRIBUTES_ERROR'

export const FETCH_DELETED_CUSTOM_ATTRIBUTES = 'FETCH_DELETED_CUSTOM_ATTRIBUTES'
export const FETCH_DELETED_CUSTOM_ATTRIBUTES_SUCCESS =
  'FETCH_DELETED_CUSTOM_ATTRIBUTES_SUCCESS'
export const FETCH_DELETED_CUSTOM_ATTRIBUTES_ERROR =
  'FETCH_DELETED_CUSTOM_ATTRIBUTES_ERROR'
