import React from 'react'
import { initializeAppConfig, getAppConfig } from '../../config/appConfig'
import Progress from '@material-ui/core/LinearProgress'
import { initAxios } from '../../utils/axios-util'
import { withAngularContext } from '../../contexts/AngularContext'

class ConfigProvider extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      config: null,
    }
  }

  async componentDidMount() {
    await initializeAppConfig({
      isAngular: this.props.angularProps?.isAngular,
      edgeBaseUrlForAngular: this.props.angularProps?.baseUrl,
    })
    initAxios()
    this.setState({ config: getAppConfig() })
  }

  renderConfig = () => {
    if (this.state.config !== null) {
      return this.props.children
    } else {
      return (
        <div>
          <Progress color="secondary" />
        </div>
      )
    }
  }

  render() {
    return <React.Fragment>{this.renderConfig()}</React.Fragment>
  }
}

export default withAngularContext(ConfigProvider)
