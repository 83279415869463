import * as requestVendorPreIsuActionTypes from './actionTypes'

const initialState = {
  products: [],
  bids: [],
  isFetchingRequestVendorPreIsuProducts: false,
  vendorPreIsuCountByBpIdMap: {},
  productsTotalCount: 0,
  isDownloading: false,
}

export const requestVendorPreIsuReducer = (
  state = initialState,
  action: { type?: string; payload?: any } = {}
) => {
  switch (action.type) {
    case requestVendorPreIsuActionTypes.FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS: {
      return {
        ...state,
        isFetchingRequestVendorPreIsuProducts: true,
      }
    }
    case requestVendorPreIsuActionTypes.FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.payload.products.values,
        bids: action.payload.vendors.bids,
        productsTotalCount: action.payload.products.totalItemsIncludingLevels,
        isFetchingRequestVendorPreIsuProducts: false,
      }
    }
    case requestVendorPreIsuActionTypes.FETCH_REQUEST_VENDOR_PRE_ISU_PRODUCTS_ERROR: {
      return {
        ...state,
        isFetchingRequestVendorPreIsuProducts: false,
      }
    }
    case requestVendorPreIsuActionTypes.SAVE_VENDOR_PRE_ISU: {
      return {
        ...state,
      }
    }
    case requestVendorPreIsuActionTypes.SAVE_VENDOR_PRE_ISU_SUCCESS: {
      return {
        ...state,
      }
    }
    case requestVendorPreIsuActionTypes.SAVE_VENDOR_PRE_ISU_ERROR: {
      return {
        ...state,
      }
    }
    case requestVendorPreIsuActionTypes.EXPORT_VENDOR_PRE_ISU: {
      return {
        ...state,
        isDownloading: true,
      }
    }
    case requestVendorPreIsuActionTypes.EXPORT_VENDOR_PRE_ISU_SUCCESS: {
      return {
        ...state,
        isDownloading: false,
      }
    }
    case requestVendorPreIsuActionTypes.EXPORT_VENDOR_PRE_ISU_ERROR: {
      return {
        ...state,
        isDownloading: false,
      }
    }
    case requestVendorPreIsuActionTypes.RESET_REQUEST_VENDOR_PRE_ISU: {
      return {
        ...state,
        products: [],
        bids: [],
        isFetchingRequestVendorPreIsuProducts: false,
        vendorPreIsuCountByBpIdMap: {},
        productsTotalCount: 0,
        isDownloading: false,
      }
    }
    default:
      return state
  }
}
