export enum EventCategories {
  AgGrid = 'ag-grid',
  Homepage = 'Homepage',
  AlertPane = 'Alert Pane',
  ProductsPage = 'Products Page',
  Files = 'Files',
  Feedback = 'Feedback',
  Discussion = 'Discussion',
  ManageViews = 'Manage Views',
  ManageViewsScreen = 'Manage Views Screen',
  AddProducts = 'Add Products',
  Event = 'Event',
  SearchEvent = 'Search Event',
  Pivot = 'Pivot',
  ManageBid = 'Manage Bid',
  ManageAttributes = 'Manage Attributes',
  SuggestAttributes = 'Suggest Attributes',
  OperationalReportingPage = 'Operational Reporting Page',
  HealthMetricsPage = 'Health Metrics and Compliance Page',
  Popup = 'Popup',
}
