import { Feedback } from '../../models/feedback/Feedback'
import { FeedbackState } from '../../models/store/feedback/FeedbackState'
import { FileInfo } from '../../services/file-service'
import * as feedbackActionTypes from './actionTypes'

const initialState: FeedbackState = {
  feedback: {},
  isFetchingFeedback: false,
  feedbackConversation: [],
  isFetchingFeedbackConversation: false,
  feedbackHighlights: {},
  isFetchingFeedbackHighlight: false,
  totalUnreadFeedbackCount: null,
}

export const feedbackReducer = (
  state = initialState,
  action: { type?: string; payload?: any } = {}
) => {
  switch (action.type) {
    case feedbackActionTypes.FETCH_FEEDBACK_CONVERSATION: {
      return {
        ...state,
        isFetchingFeedbackConversation: true,
      }
    }
    case feedbackActionTypes.FETCH_FEEDBACK_CONVERSATION_SUCCESS: {
      const { payload } = action

      const conversation: Feedback[] = payload.data.map(feedback => {
        if (feedback.comments === undefined) {
          return {
            _type: 'upload',
            ...feedback,
          }
        } else if ((feedback.fileId ?? null) !== null) {
          return {
            _type: 'file',
            ...feedback,
          }
        } else {
          return {
            _type: 'comment',
            ...feedback,
          }
        }
      })

      return {
        ...state,
        isFetchingFeedbackConversation: false,
        feedbackConversation: conversation,
      }
    }
    case feedbackActionTypes.FETCH_FEEDBACK: {
      return {
        ...state,
        isFetchingFeedback: true,
      }
    }
    case feedbackActionTypes.FETCH_FEEDBACK_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isFetchingFeedback: false,
        feedback: payload.data,
      }
    }
    case feedbackActionTypes.FETCH_FEEDBACK_ERROR: {
      return {
        ...state,
        isFetchingFeedback: false,
      }
    }
    case feedbackActionTypes.UPDATE_TOTAL_UNREAD_FEEDBACK_COUNT: {
      const { payload: totalUnreadFeedbackCount } = action

      return {
        ...state,
        totalUnreadFeedbackCount: totalUnreadFeedbackCount,
      }
    }
    case feedbackActionTypes.FETCH_FEEDBACK_HIGHLIGHT: {
      return {
        ...state,
        isFetchingFeedbackHighlight: true,
      }
    }
    case feedbackActionTypes.FETCH_FEEDBACK_HIGHLIGHT_SUCCESS: {
      const { payload } = action
      const feedbackHighlights = state.feedbackHighlights
      feedbackHighlights[payload.key] = payload.value
      return {
        ...state,
        feedbackHighlights: feedbackHighlights,
        isFetchingFeedbackHighlight: false,
      }
    }
    case feedbackActionTypes.ADD_FEEDBACK_FILE: {
      const { payload } = action
      return {
        ...state,
        feedbackConversation: [...state.feedbackConversation, payload],
      }
    }
    case feedbackActionTypes.UPDATE_FEEDBACK_FILE: {
      const payload: FileInfo = action.payload
      const updatedState = state.feedbackConversation.map(conversation => {
        if (
          conversation._type === 'upload' &&
          conversation.fileInfo.fileName === payload.fileName
        ) {
          return {
            ...conversation,
            fileInfo: payload,
          }
        } else {
          return conversation
        }
      })

      return {
        ...state,
        feedbackConversation: updatedState,
      }
    }
    default:
      return state
  }
}
