import * as productActionTypes from './actionTypes'
import {
  processItemSetupValidations,
  processSectionsToFieldNames,
} from '../../services/products-service'
import { ProductsState } from '../../models/store/products/ProductsState'

const initialState: ProductsState = {
  itemBps: [],
  products: [],
  isFetchingItemSetupCount: false,
  itemSetupCount: -1,
  isFetchingItemBps: false,
  isFetchingProducts: false,
  isFetchingDeletedProductsCount: false,
  productList: [],
  productsPage: 1,
  productsPageCount: 100,
  productsTotalCount: 0,
  productsTotalLevelCount: 0,
  sections: [],
  views: [],
  fieldNames: {},
  businessConfirmationView: [],
  isBulkUpdating: false,
  isuValidationsList: [],
  alertList: [],
  isFetchingISUValidations: false,
  productsDeptSubClazz: {},
  parentItemBpIds: {},
  isSavingProducts: false,
  saveProductsErrors: null,
  isFetchingDeletedProducts: false,
  deletedProductsCount: 0,
  deletedProductsLevelCount: 0,
  deletedProductList: [],
  isCopyProducts: false,
  isCloneProducts: false,
  isFetchingAuditHighlights: false,
  audits: {},
  pmuRules: {},
  readOnlyAttributesForCF: [],
  productFeedbackHighlights: {},
  isSearchingPotentialItems: false,
  potentialItemsList: [],
  impressionList: [],
  impressionPageCount: 0,
  impressionTotalCount: 0,
  impressionTotalLevelCount: 0,
  itemSetupView: {},
  isFetchingAllItemBps: false,
}

export const productsReducer = (
  state = initialState,
  action: { type?: string; payload?: any } = {}
) => {
  switch (action.type) {
    case productActionTypes.FETCH_ITEMBP: {
      return {
        ...state,
        isFetchingItemBps: true,
      }
    }
    case productActionTypes.FETCH_ITEMBP_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        itemBps: payload.values,
        isFetchingItemBps: false,
      }
    }
    case productActionTypes.FETCH_PRODUCTS: {
      return {
        ...state,
        isFetchingProducts: true,
      }
    }
    case productActionTypes.FETCH_PRODUCTS_SUCCESS: {
      const {
        products,
        view,
        sections,
        feedbackHighlights,
        productsDeptSubClazz,
        parentItemBpIds,
        pmuRules,
        readOnlyAttributesForCF,
      } = action.payload
      const fieldNames = processSectionsToFieldNames(sections)
      return {
        ...state,
        isFetchingProducts: false,
        productList: products.values,
        productsPage: products.page,
        productsPageCount: products.per_page,
        productsTotalCount: products.count,
        productsTotalLevelCount: products.totalItemsIncludingLevels,
        sections: sections,
        fieldNames: fieldNames,
        businessConfirmationView: view.find(
          res =>
            res.viewName === 'Vendor Alignment View' &&
            res.createUser === 'PCNSystemUser'
        ),
        views: view,
        productFeedbackHighlights: feedbackHighlights,
        productsDeptSubClazz: productsDeptSubClazz,
        parentItemBpIds: parentItemBpIds,
        pmuRules: pmuRules,
        readOnlyAttributesForCF: readOnlyAttributesForCF,
      }
    }
    case productActionTypes.FETCH_PRODUCTS_ERROR: {
      return {
        ...state,
        isFetchingProducts: false,
      }
    }
    case productActionTypes.FETCH_IMPRESSIONS: {
      return {
        ...state,
        isFetchingAllImpressionsForProgram: true,
        impressionList: [],
        impressionPageCount: 0,
        impressionTotalCount: 0,
        impressionTotalLevelCount: 0,
      }
    }
    case productActionTypes.FETCH_IMPRESSIONS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isFetchingAllImpressionsForProgram: false,
        impressionList: payload.values,
        impressionPageCount: payload.per_page,
        impressionTotalCount: payload.count,
        impressionTotalLevelCount: payload.totalItemsIncludingLevels,
      }
    }
    case productActionTypes.FETCH_IMPRESSIONS_ERROR: {
      return {
        ...state,
        isFetchingAllImpressionsForProgram: false,
        impressionList: [],
        impressionPageCount: 0,
        impressionTotalCount: 0,
        impressionTotalLevelCount: 0,
      }
    }
    case productActionTypes.FETCH_PRODUCTS_FOR_ISU: {
      return {
        ...state,
        isFetchingProducts: true,
      }
    }
    case productActionTypes.FETCH_PRODUCTS_FOR_ISU_SUCCESS: {
      const {
        products,
        sections,
        productsDeptSubClazz,
        parentItemBpIds,
        view,
      } = action.payload
      const fieldNames = processSectionsToFieldNames(sections)
      return {
        ...state,
        isFetchingProducts: false,
        productList: products.values,
        productsPage: products.page,
        productsPageCount: products.per_page,
        productsTotalCount: products.count,
        productsTotalLevelCount: products.totalItemsIncludingLevels,
        sections: sections,
        fieldNames: fieldNames,
        productsDeptSubClazz: productsDeptSubClazz,
        parentItemBpIds: parentItemBpIds,
        saveProductsErrors: null,
        itemSetupView: view.find(
          res =>
            res.viewName === 'Item Setup View' &&
            res.createUser === 'PCNSystemUser'
        ),
      }
    }
    case productActionTypes.FETCH_PRODUCTS_FOR_ISU_ERROR: {
      return {
        ...state,
        isFetchingProducts: false,
      }
    }
    case productActionTypes.FETCH_DELETED_PRODUCTS: {
      return {
        ...state,
        isFetchingDeletedProducts: true,
      }
    }
    case productActionTypes.FETCH_DELETED_PRODUCTS_SUCCESS: {
      //This is required for the row count status panel
      const deletedProductListTemp = action.payload.values
      if (deletedProductListTemp?.length > 0) {
        deletedProductListTemp[0].totalRowCount = deletedProductListTemp.length
      }
      return {
        ...state,
        isFetchingDeletedProducts: false,
        deletedProductList: deletedProductListTemp,
        productsPage: action.payload.page,
        productsPageCount: action.payload.per_page,
        deletedProductsCount: action.payload.count,
        deletedProductsLevelCount: action.payload.totalItemsIncludingLevels,
      }
    }
    case productActionTypes.FETCH_DELETED_PRODUCTS_ERROR: {
      return {
        ...state,
        isFetchingDeletedProducts: false,
      }
    }

    case productActionTypes.FETCH_DELETED_PRODUCTS_COUNT: {
      return {
        ...state,
        isFetchingDeletedProductsCount: true,
      }
    }
    case productActionTypes.FETCH_DELETED_PRODUCTS_COUNT_SUCCESS: {
      return {
        ...state,
        isFetchingDeletedProductsCount: false,
        deletedProductsCount: action.payload.count,
        deletedProductsLevelCount: action.payload.totalItemsIncludingLevels,
      }
    }
    case productActionTypes.FETCH_DELETED_PRODUCTS_COUNT_ERROR: {
      return {
        ...state,
        isFetchingDeletedProductsCount: false,
      }
    }

    case productActionTypes.FETCH_AUDIT_HIGHLIGHTS: {
      return {
        ...state,
        isFetchingAuditHighlights: true,
      }
    }
    case productActionTypes.FETCH_AUDIT_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        isFetchingAuditHighlights: false,
        audits: action.payload.data.values,
      }
    }
    case productActionTypes.FETCH_AUDIT_HIGHLIGHTS_ERROR: {
      return {
        ...state,
        isFetchingDeletedProducts: false,
      }
    }

    case productActionTypes.RESET_AUDITS: {
      return {
        ...state,
        audits: {},
      }
    }

    case productActionTypes.RESET_PRODUCTS: {
      return {
        ...state,
        productList: [],
        productsPage: 1,
        productsPageCount: 100,
        productsTotalCount: 0,
        productsTotalLevelCount: 0,
        sections: [],
        fieldNames: {},
        businessConfirmationView: [],
        isuValidationsList: [],
        alertList: [],
        productsDeptSubClazz: {},
        parentItemBpIds: {},
        saveProductsErrors: null,
        views: [],
        feedbackHighlights: {},
      }
    }
    case productActionTypes.RESET_ISU_VALIDATION: {
      return {
        ...state,
        isuValidationsList: [],
      }
    }
    case productActionTypes.RESET_ITEM_BPS: {
      return {
        ...state,
        itemBps: [],
      }
    }
    case productActionTypes.FETCH_ITEM_SETUP_COUNT: {
      return {
        ...state,
        isFetchingItemSetupCount: true,
      }
    }
    case productActionTypes.FETCH_ITEM_SETUP_COUNT_SUCCESS: {
      const count = action.payload.data.count
      return {
        ...state,
        isFetchingItemSetupCount: false,
        itemSetupCount: count,
      }
    }
    case productActionTypes.FETCH_ITEM_SETUP_COUNT_ERROR: {
      return {
        ...state,
        isFetchingItemSetupCount: false,
        count: -1,
      }
    }
    case productActionTypes.BULK_UPDATE_PRODUCTS: {
      return {
        ...state,
        isBulkUpdating: true,
      }
    }
    case productActionTypes.BULK_UPDATE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isBulkUpdating: false,
      }
    }
    case productActionTypes.BULK_UPDATE_PRODUCTS_ERROR: {
      return {
        ...state,
        isBulkUpdating: false,
      }
    }
    case productActionTypes.FETCH_ISU_VALIDATIONS: {
      return {
        ...state,
        isFetchingISUValidations: true,
      }
    }
    case productActionTypes.FETCH_ISU_VALIDATIONS_SUCCESS: {
      const { payload } = action

      const [isuValidationsList, alertList] = processItemSetupValidations(
        payload.data.values,
        state.productList,
        state.fieldNames
      )

      return {
        ...state,
        isuValidationsList,
        alertList,
        isFetchingISUValidations: false,
      }
    }
    case productActionTypes.FETCH_ISU_VALIDATIONS_ERROR: {
      return {
        ...state,
        isFetchingISUValidations: false,
      }
    }

    case productActionTypes.SAVE_PRODUCTS: {
      return {
        ...state,
        isSavingProducts: true,
      }
    }

    case productActionTypes.SAVE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isSavingProducts: false,
        saveProductsErrors: null,
      }
    }

    case productActionTypes.SAVE_PRODUCTS_ERROR: {
      const { payload } = action
      const hasErrors = Object.keys(payload.response.data).length > 0
      return {
        ...state,
        isSavingProducts: false,
        saveProductsErrors: hasErrors ? payload.response.data : null,
      }
    }

    case productActionTypes.COPY_PRODUCTS: {
      return {
        ...state,
      }
    }

    case productActionTypes.COPY_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isCopyProducts: false,
      }
    }

    case productActionTypes.COPY_PRODUCTS_ERROR: {
      return {
        ...state,
        isCopyProducts: false,
      }
    }

    case productActionTypes.CLONE_PRODUCTS: {
      return {
        ...state,
      }
    }

    case productActionTypes.CLONE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isCloneProducts: false,
      }
    }

    case productActionTypes.CLONE_PRODUCTS_ERROR: {
      return {
        ...state,
        isCloneProducts: false,
      }
    }

    case productActionTypes.SEARCH_POTENTIAL_ITEMS: {
      return {
        ...state,
        isSearchingPotentialItems: true,
        potentialItemsList: [],
      }
    }

    case productActionTypes.SEARCH_POTENTIAL_ITEMS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isSearchingPotentialItems: false,
        potentialItemsList: payload.data.values,
      }
    }

    case productActionTypes.SEARCH_POTENTIAL_ITEMS_ERROR: {
      return {
        ...state,
        isSearchingPotentialItems: false,
      }
    }

    case productActionTypes.FETCH_ITEMBP_ALL: {
      return {
        ...state,
        isFetchingAllItemBps: true,
      }
    }
    case productActionTypes.FETCH_ITEMBP_ALL_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        itemBps: payload,
        isFetchingAllItemBps: false,
      }
    }
    case productActionTypes.FETCH_ITEMBP_ALL_ERROR: {
      return {
        ...state,
        itemBps: [],
        isFetchingAllItemBps: false,
      }
    }

    default:
      return state
  }
}
