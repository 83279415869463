const angularConfig = edgeBaseUrl => {
  const config = {}
  config.REACT_APP_SERVICE_URL = edgeBaseUrl
  if (
    window.location.host.indexOf('pogap-dev.target.com') > -1 ||
    window.location.host.indexOf('localhost') > -1
  ) {
    config.smartAssist = {
      baseUrl:
        'https://smartassistservice-dev.dev.target.com/smart_assist_documents/v1',
      apiKey: '5f0a98e974d5c0ce3d997fb228c54d84272d916b',
      confluenceSpace: 'PCNSA',
      userType: 'I',
      enabled: true,
      environment: 'dev',
    }
    config.googleAnalytics = {
      trackingId: 'UA-160860827-2',
    }
    config.factorialUrl = 'https://factorial-ui-dev.dev.target.com/'
    config.mfe = {
      id: 'what_if_ui@https://whatifui.dev.target.com/remoteEntry.js',
    }
  }

  if (window.location.host.indexOf('apex-stage.target.com') > -1) {
    config.smartAssist = {
      baseUrl:
        'https://smartassistservice.dev.target.com/smart_assist_documents/v1',
      apiKey: '5f0a98e974d5c0ce3d997fb228c54d84272d916b',
      confluenceSpace: 'PCNSA',
      userType: 'I',
      enabled: true,
      environment: 'stage',
    }
    config.googleAnalytics = {
      trackingId: 'UA-160860827-5',
      gtagTrackingID: 'G-L34L0HRBGR',
    }
    config.factorialUrl = 'https://factorial-ui-stage.dev.target.com/'
    config.mfe = {
      id: 'what_if_ui@https://whatifui-stage.dev.target.com/remoteEntry.js',
    }
  }

  if (window.location.host.indexOf('apex.target.com') > -1) {
    config.smartAssist = {
      baseUrl:
        'https://smartassistservice.prod.target.com/smart_assist_documents/v1',
      apiKey: '9ddc5bc7a67ec7678e2b824567dbfaed7e1b2855',
      confluenceSpace: 'PCNSA',
      userType: 'I',
      enabled: true,
      environment: 'prod',
    }
    config.googleAnalytics = {
      trackingId: 'UA-160860827-6',
      gtagTrackignId: 'G-TVEMFNPE6S',
    }
    config.factorialUrl = 'https://factorial.prod.target.com/'
    config.mfe = {
      id: 'what_if_ui@https://whatifui.prod.target.com/remoteEntry.js',
    }
  }

  return config
}

export default angularConfig
