import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core'
import { createStore } from '../store/store'
import { ConfigProvider } from '../components/ConfigProvider'
import { ThemeConfig } from 'apex-ui-kit'
import BpImpersonation from '../components/BpImpersonation/BpImpersonation'
import { analyticsService } from '../services/analytics-service'

export const store = createStore()

const PcnApp = props => {
  // Pageviews are tracked in the router, but only on changes
  // This tracks the first pageview or landing page
  analyticsService.pageView()

  return (
    <ConfigProvider>
      <BpImpersonation>
        <Provider store={store}>
          <ThemeProvider theme={ThemeConfig}>{props.children}</ThemeProvider>
        </Provider>
      </BpImpersonation>
    </ConfigProvider>
  )
}

export default PcnApp
