import React, { ReactNode } from 'react'
import HomeIcon from '@material-ui/icons/Home'
import EventIcon from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CustomAttributesIcon from '@material-ui/icons/AccountCircle'
import HealthMetricsIcon from '@material-ui/icons/ShowChart'

const PageRouteAndIcon = (route: string, icon: ReactNode) => ({
  route: route,
  icon: icon,
})

const InternalNavRoutes = {
  Home: PageRouteAndIcon('/', <HomeIcon />),
  Events: PageRouteAndIcon('/events', <EventIcon />),
  'Health & Compliance': PageRouteAndIcon(
    '/healthMetrics',
    <HealthMetricsIcon />
  ),
  'Custom Attributes': PageRouteAndIcon(
    '/customAttributes',
    <CustomAttributesIcon />
  ),
  'Custom Views': PageRouteAndIcon('/settingsViews', <CustomAttributesIcon />),
  Logout: PageRouteAndIcon('/logout', <ExitToAppIcon />),
}

export default InternalNavRoutes
