import { AxiosUtil } from '../../utils/axios-util'
import {
  errorResponseNotification,
  errorNotification,
  successNotification,
} from '../notification-service'
import EventConstants from '../../constants/EventConstants'
import { saveAs } from 'file-saver'

const getPageUrl = (page, perPage) => {
  return 'page=' + page + '&per_page=' + perPage
}

export const getVendorsByEvent = ({ eventId, page, perPage }) => {
  const url =
    EventConstants.EVENT +
    'bid_search' +
    '?' +
    getPageUrl(page, perPage) +
    EventConstants.EVENT_ID +
    eventId
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getBpsByName = searchName => {
  const url = 'internal/bparts?name=' + searchName
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getBpsByVendorId = searchId => {
  const url = 'internal/bparts?id=' + searchId
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const addBid = vendorRequest => {
  const url = EventConstants.EVENT + 'bids'
  return AxiosUtil.post(url, vendorRequest).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const updateBid = (eventId, bidId, updateRequest) => {
  const url = EventConstants.EVENT + eventId + '/bids/' + bidId
  return AxiosUtil.put(url, updateRequest).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getItemBpsWithoutPlanId = (eventId, bpId, page, perPage) => {
  const url =
    'common/events/v1/itemBps' +
    '?event_id=' +
    eventId +
    '&bp_ids=' +
    bpId +
    '&page=' +
    page +
    '&per_page=' +
    perPage +
    '&plan_unclaimed=true'
  return AxiosUtil.post(url, {}).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const exportToExcel = ({ fileName, exportTableData }) => {
  const url = EventConstants.VENDOR_CONTACTS_EXCEL
  return AxiosUtil.post(url, exportTableData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  })
    .then(res => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, fileName + '.xlsx')
      successNotification(['Excel sheet downloaded successfully.'])
    })
    .catch(err => {
      errorNotification([
        'Failed to download the Excel. Something went wrong while fetching the data.',
      ])
    })
}

export const getPickedNLCItems = ({ eventId, page, perPage }) => {
  const request = {
    filter_request: [
      { field: 'buyers_choice', values: [true], operator: 'equals' },
      {
        field: 'section_data.item.item_status_value',
        values: ['No Longer Under Consideration'],
        operator: 'equals',
      },
    ],
  }
  return AxiosUtil.post(
    EventConstants.POTENTIAL_ITEMS +
      'aggregations_search_with_bps?page=' +
      page +
      '&per_page=' +
      perPage +
      '&event_id=' +
      eventId,
    request
  ).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}
