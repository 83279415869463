import EventConstants from '../constants/EventConstants'
import { AxiosUtil } from '../utils/axios-util'
import { saveAs } from 'file-saver'
import {
  errorNotification,
  errorResponseNotification,
  successNotification,
} from './notification-service'
import { AxiosRequestConfig, AxiosPromise } from 'axios'
import { Alert } from 'apex-ui-kit/components/AlertPane/types/Alert'
import { ProductsResponse } from '../models/products/productsResponse'
import { Products } from '../models/products/products'
import { ItemSetupValidation } from '../models/store/products/ItemSetupValidation'
import { ItemSetupValidationError } from '../models/store/products/ItemSetupValidationError'
import { GetItemBpParams } from './models/productsService/GetItemBpsParams'
import Immutable from 'immutable'

const AttributeMappings = Immutable.Map<string, string[]>({
  brand: [
    'section_data.item.brand',
    'section_data.buyer_owned.intended_brand',
    'section_data.item.brand_type',
    'section_data.item.brand_classification',
    'section_data.item.brand_id',
  ],
  factory: ['section_data.shipping_location.factories'],
  sparkPid: ['section_data.item.spark_pids'],
})

export const getItemBps = ({
  eventIds = null,
  bpIds = [],
  page = 1,
  perPage = 1000,
  requestBody,
  itemLevelOnly = false,
  fetchAll = false,
  includeHierarchy = false,
}: GetItemBpParams): Promise<Products> => {
  const requestParams = new URLSearchParams({
    page: page.toString(),
    per_page: perPage.toString(),
  })
  if (bpIds) {
    bpIds.forEach(bpId => requestParams.append('bp_ids', bpId.toString()))
  }
  if (eventIds) {
    eventIds.forEach(id => requestParams.append('event_id', id))
  }
  if (fetchAll) {
    requestParams.append('fetchAll', fetchAll.toString())
  }
  if (itemLevelOnly) {
    requestParams.append('itemLevelOnly', itemLevelOnly.toString())
  }
  if (includeHierarchy) {
    requestParams.append('includeHierarchy', includeHierarchy.toString())
  }
  const url = `${EventConstants.ITEM_BP}?${requestParams.toString()}`
  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.data)
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getProducts = ({
  eventId,
  bidId,
  userType,
  viewId,
  isDeleted,
  page,
  perPage,
  requestBody,
  isApparelEvent,
  isStaticView = false,
  screenName,
  bpViewIds,
  bpId,
}): AxiosPromise<ProductsResponse> => {
  const screenView =
    screenName === 'compareBidsWithTreeView' ? 'compareBids' : screenName

  let url =
    EventConstants.COMMON +
    'event/' +
    eventId +
    '/vendor/products?bidId=' +
    bidId +
    '&userType=' +
    userType +
    '&view=' +
    viewId +
    '&deleted=' +
    isDeleted +
    '&page=' +
    page +
    '&perPage=' +
    perPage +
    '&isStaticView=' +
    isStaticView +
    '&screenName=' +
    screenView +
    '&bpViewIds=' +
    bpViewIds

  if (bpId) {
    url = url + '&bpId=' + bpId
  }

  if (isApparelEvent) {
    if (screenName === 'compareBidsWithTreeView') {
      url = url + '&includeHierarchy=' + isApparelEvent
    } else {
      url = url + '&itemLevelOnly=' + isApparelEvent
    }
  }

  url = url + '&view=1&levelThreeView=' + isApparelEvent
  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getProductsForISU = ({
  eventId,
  bpIds,
  userType,
  viewId,
  isDeleted,
  page,
  perPage,
  requestBody,
  isApparelEvent,
  isuFlag,
  bpId,
}): AxiosPromise<ProductsResponse> => {
  var url =
    EventConstants.COMMON +
    'event/' +
    eventId +
    '/vendor/products?bidId=' +
    bpIds +
    '&userType=' +
    userType +
    '&view=' +
    viewId +
    '&deleted=' +
    isDeleted +
    '&page=' +
    page +
    '&perPage=' +
    perPage +
    '&isuFlag=' +
    isuFlag +
    '&itemLevelOnly=' +
    isApparelEvent +
    '&bpId=' +
    bpId +
    '&screenName=itemSetup'
  url = url + '&view=1&levelThreeView=' + isApparelEvent
  if (isApparelEvent) {
    url = url + '&includeParentFeedback=' + isApparelEvent
  }
  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getDeletedProducts = ({
  eventId,
  bpIds,
  isDeleted,
  page,
  perPage,
  requestBody,
  isApparelEvent,
  screenName,
}): Promise<Products> => {
  var url =
    EventConstants.ITEM_BP +
    '?event_id=' +
    eventId +
    '&bp_ids=' +
    bpIds +
    '&deleted=' +
    isDeleted +
    '&page=' +
    page +
    '&per_page=' +
    perPage

  if (isApparelEvent) {
    if (screenName === 'compareBidsWithTreeView') {
      url =
        url +
        '&includeHierarchy=' +
        isApparelEvent +
        '&includeChildren=' +
        isApparelEvent
    } else {
      url = url + '&itemLevelOnly=' + isApparelEvent
    }
  }

  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getISUValidations = (bpId, requestBody) => {
  var url = EventConstants.COMMON + '/itemBps/validate_item_bps?bpId=' + bpId
  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    'axios-retry': {
      retries: 5,
      retryCondition: () => {
        return true
      },
    },
  } as AxiosRequestConfig).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getAuditHighlights = (eventId, requestBody) => {
  var url = EventConstants.COMMON + 'event/' + eventId + '/auditHighlights'
  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getChangeHistory = (itemBps, attributes) => {
  const itemBpIds = Object.keys(itemBps)
  const attributeIds = Object.keys(attributes)

  const url =
    EventConstants.EVENT +
    'bids/auditLog?itemBpId=' +
    itemBpIds +
    '&attributeId=' +
    attributeIds

  return AxiosUtil.get(url)
    .then(res => {
      const resp = processChangeLog(itemBps, attributes, res)
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

interface auditRecord {
  label: string
  field: string
  oldValue: string
  newValue: string
  userId: string
  createdDate: string
}
const processChangeLog = (itemBps, attributes, res) => {
  const auditLog: auditRecord[] = []
  //const auditLog = []
  const audits = res.data
  Object.keys(audits).forEach(key => {
    const value = audits[key]
    const splitData = key.split('-')
    for (let i = value.length - 1; i >= 0; i--) {
      const auditKey: auditRecord = {
        label: itemBps[splitData[0]] as string,
        field: attributes[splitData[1]] as string,
        newValue: value[i].value as string,
        userId: value[i].user_id as string,
        createdDate: value[i].created_ts as string,
        oldValue: '',
      }
      if (value.length > 1 && i > 0) {
        auditKey.oldValue = value[i - 1].value as string
      }
      auditLog.push(auditKey)
    }
  })
  return auditLog
}

export const saveProducts = ({
  data,
  vendorId,
  bidObjectId,
  eventId,
  vendorType = [],
  isDynamicComponentOrMaster = false,
  shouldCreateNewItem = false,
  shouldSwapBuyersChoice = false,
  fastTrackEvent = false,
}) => {
  var url =
    EventConstants.COMMON +
    'products?vendorId=' +
    vendorId +
    '&bid_id=' +
    bidObjectId +
    '&event_id=' +
    eventId +
    '&validate_products=true' +
    '&vendor_type=' +
    vendorType +
    '&dynamicComponentType=' +
    isDynamicComponentOrMaster +
    '&create_new_item=' +
    shouldCreateNewItem +
    '&swap_buyers_choice=' +
    shouldSwapBuyersChoice +
    '&fastItemSetup=' +
    fastTrackEvent

  return AxiosUtil.put(url, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (res && res.data && res.data.message) {
        successNotification(res.data.message)
      }
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getExcelSheet = ({
  eventId,
  bpIds,
  isDeleted,
  page = 1,
  perPage = 1000,
  isuFlag,
  requestBody,
  fileName,
  screenName,
  isApparelEvent,
}) => {
  var url =
    EventConstants.ITEM_BP_EXCEL +
    '?event_id=' +
    eventId +
    '&bp_ids=' +
    bpIds +
    '&deleted=' +
    isDeleted +
    '&page=' +
    page +
    '&per_page=' +
    perPage

  if (isuFlag) {
    url = url + '&isuFlag=' + isuFlag
  }

  if (isApparelEvent) {
    if (screenName === 'compareBidsWithTreeView') {
      url =
        url +
        '&includeHierarchy=' +
        isApparelEvent +
        '&includeChildren=' +
        isApparelEvent
    } else {
      url = url + '&itemLevelOnly=' + isApparelEvent
    }
  }

  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  })
    .then(res => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, fileName + '.xlsx')
      successNotification(['Excel sheet downloaded successfully.'])
    })
    .catch(err => {
      errorNotification([
        'Failed to download the Excel. Something went wrong while fetching the data.',
      ])
    })
}

export const getItemSetupCount = (eventObjectId, bpId) => {
  const url =
    encodeURI(EventConstants.ISU_COUNT) +
    '?eventObjId=' +
    encodeURIComponent(eventObjectId) +
    '&bpId=' +
    encodeURIComponent(bpId)
  return AxiosUtil.get(url)
}

export const bulkUpdateProducts = (eventId, bpId, requestBody) => {
  var url = EventConstants.COMMON + 'bulkItemBpUpdate?event_id=' + eventId
  if (bpId) {
    url = url + '&bpId=' + bpId
  }
  return AxiosUtil.put(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (res && res.data && res.data.message) {
        successNotification(res.data.message)
      }
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

type FieldNames = { [key: string]: string }
export const processSectionsToFieldNames = (sections: any[]): FieldNames => {
  return sections.reduce((fieldNames: FieldNames, section) => {
    Object.entries(section.attributes).forEach(
      ([attributeId, attribute]: any[]) => {
        fieldNames[`section_data.${section.id}.${attributeId}`] = attribute.name
      }
    )
    return fieldNames
  }, {})
}

const getProduct = (itemBpId: string, products: any[]) =>
  products.find(product => product.id === itemBpId)

const getFieldName = (
  fieldId: string,
  fieldNames: { [key: string]: string }
): string => (fieldNames[fieldId] ? fieldNames[fieldId] : fieldId)

const additionalIsuValidations = (
  errors: any[],
  product: any,
  fieldNames: { [fieldId: string]: string }
) => {
  if (!product.section_data.shipping_location.import_designation) {
    errors.push({
      field: 'section_data.shipping_location.import_designation',
      reason:
        getFieldName(
          'section_data.shipping_location.import_designation',
          fieldNames
        ) + ' is required for item setup',
    })
  }
  const itemCreateType = product.section_data.buyer_owned.item_create_type
  if (
    itemCreateType &&
    (itemCreateType === 'Dynamic Assorted Master' ||
      itemCreateType === 'Dynamic Assorted Component') &&
    product.section_data.item.bar_code_type === 'SYSTEM GENERATED (12 DIGITS)'
  ) {
    errors.push({
      field: 'section_data.item.bar_code_type',
      reason:
        getFieldName('section_data.item.bar_code_type', fieldNames) +
        ' cannot be System Generated for Dynamic Assorted Master/Components',
    })
  }
}

export const processItemSetupValidations = (
  responseValidations: {
    item_bp_id: string
    status: string
    message: string
    errors: { field: string; reason: string }[]
  }[],
  products: any[],
  fieldNames: { [fieldId: string]: string }
): [ItemSetupValidation[], Alert[]] => {
  var isuValidations: [ItemSetupValidation[], Alert[]] =
    responseValidations.reduce(
      ([validations, alerts], response) => {
        // Convert the response to our object
        const responseErrors = response.errors as ItemSetupValidationError[]
        handleVCPSSP(responseErrors)
        // Prune the errors
        const prunedErrors = Object.values(
          responseErrors.reduce((errorMap, error) => {
            const mappedErrors = errorMap[error.field]
              ? errorMap[error.field]
              : []
            if (
              mappedErrors.length === 0 ||
              !mappedErrors.some(existing => existing.reason === error.reason)
            ) {
              mappedErrors.push(error)
            }

            errorMap[error.field] = mappedErrors
            return errorMap
          }, {})
        ).flat()

        const product = getProduct(response.item_bp_id, products)
        additionalIsuValidations(prunedErrors, product, fieldNames)
        const newAlerts = prunedErrors.map((error: any) => ({
          fieldId: error.field,
          fieldName: getFieldName(error.field, fieldNames),
          message: error.reason,
          productId: response.item_bp_id,
          productDisplayId: product.pcn_item_id,
          productDisplayIdLabel: 'PCN Item ID',
          productDescription: product
            ? product.section_data.item.description
            : `${response.item_bp_id} description`,
        }))

        const validation: ItemSetupValidation = {
          ...response,
          itemBpId: response.item_bp_id,
          status: (prunedErrors ?? []).length > 0 ? 'FAILURE' : 'SUCCESS',
          message:
            (prunedErrors ?? []).length > 0
              ? prunedErrors.length + ' error(s) found'
              : 'No Validation Errors',
          errors: prunedErrors as ItemSetupValidationError[],
          alerts: newAlerts,
        }
        validations.push(validation)

        return [validations, [...alerts, ...newAlerts]]
      },
      [[], []] as [ItemSetupValidation[], Alert[]]
    )
  return isuValidations
}
const handleVCPSSP = (responseErrors: ItemSetupValidationError[]) => {
  responseErrors = responseErrors.map(error => {
    if (error.reason) {
      error.reason = error.reason.replace(/\(VCP\)/g, 'Master Casepack')
      error.reason = error.reason.replace(
        /inner pack \(SSP\)/g,
        'Inner Casepack'
      )
      error.reason = error.reason.replace(/\(SSP\)/g, 'Inner Casepack')
      error.reason = error.reason.replace(/Store Ship Pack/g, '')
      error.reason = error.reason.replace(/VCP/g, 'Master Casepack')
      error.reason = error.reason.replace(/SSP/g, 'Inner Casepack')
      error.reason = error.reason.replace(/Quantity/g, 'Units')
      error.reason = error.reason.replace(/Quantities/g, 'Units')
    }
    if (error.field && error.field === 'vendor _casepack') {
      if (error.reason && error.reason.indexOf('Weight') >= 0) {
        error.field =
          'section_data.vendor_order_point_product_casepack.master_weight'
      } else {
        error.field =
          'section_data.vendor_order_point_product_casepack.master_casepack_unit'
      }
    }
    return error
  })
}

// copy products
export const copyProducts = (eventId, bpId, requestBody) => {
  let url = EventConstants.COMMON + 'itemBps/bulkAction?eventId=' + eventId
  if (bpId) {
    url = url + '&bp_ids=' + bpId
  }
  return AxiosUtil.post(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (requestBody.is_drill_down) {
        successNotification(['Successfully Drilled'])
      } else {
        successNotification(['Successfully Copied'])
      }
    })
    .catch(err => {
      errorResponseNotification(err.response)
      throw err
    })
}

// add option
export const cloneProducts = (eventId, bpId, requestBody) => {
  let url =
    EventConstants.COMMON +
    'itemBps/clone_item_bps?eventId=' +
    eventId +
    '&create_option=true'
  if (bpId) {
    url = url + '&bpId=' + bpId
  }
  return AxiosUtil.put(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      successNotification(['Successfully added option'])
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const addCFProduct = (type: string, product: any) => {
  const url = EventConstants.COMMON + 'createCarryforwardItems'

  return AxiosUtil.post(url, product)
    .then(res => {
      successNotification([res.data.message])
      return res
    })
    .catch(err => {
      errorNotification(err.response.data.message)
      throw err
    })
}

export const getDepartments = (deptId: string) => {
  const url = EventConstants.COMMON_DEPARTMENT + '?dept=' + deptId

  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
    throw err
  })
}

export const getClasses = (deptId: string) => {
  const url = EventConstants.COMMON_CLASS + '?dept=' + deptId

  return AxiosUtil.get(url)
    .then(res => {
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const pickAndMapItemBp = ({
  newItemBpId,
  unpickItemId,
  unpickItemBpId,
}) => {
  const url =
    EventConstants.INTERNAL_ITEM_BP +
    '/pick_map_item_bps?' +
    'pick_itembp_id=' +
    newItemBpId +
    '&unpick_itembp_id=' +
    unpickItemBpId +
    '&item_id=' +
    unpickItemId

  return AxiosUtil.post(url, '', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (res && res.data && res.data.message) {
        successNotification(res.data.message)
      }
    })
    .catch(err => {
      errorResponseNotification(err.response)
    })
}

export const searchPotentialItems = ({
  eventId,
  page,
  perPage,
  requestBody,
}) => {
  const url = `${EventConstants.POTENTIAL_ITEMS}aggregations_search_with_bps?event_id=${eventId}&page=${page}&per_page=${perPage}`

  return AxiosUtil.post(url, requestBody).catch(err => {
    if (err?.response?.data?.message) {
      errorNotification(err.response.data.message)
    }
    throw err
  })
}

export const updateItemBpsGroup = (
  updateItemBpGroupRequest,
  drillToImpressions
) => {
  return AxiosUtil.put(
    `${
      EventConstants.INTERNAL_ITEM_BP
    }/group_item_bps?delete_newly_drilled_impressions=${!drillToImpressions}`,
    updateItemBpGroupRequest,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getItemByItemId = itemId => {
  const url = EventConstants.ITEM_BY_ITEMID + '?itemId=' + itemId

  return AxiosUtil.get(url)

    .then(res => {
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getProductCount = ({
  eventId,
  bpId,
  isBusinessConfirmed,
  request = isBusinessConfirmed
    ? {
        filter_request: [
          { field: 'business_confirmed', values: [true], operator: 'equals' },
        ],
        sort_request: [],
      }
    : { filter_request: [], sort_request: [] },
}) => {
  let url =
    EventConstants.EVENT + 'bid/confirmproducts/count?event_obj_id=' + eventId

  if (bpId) {
    url = url + '&bp_id=' + bpId
  }

  return AxiosUtil.post(url, request)
    .then(resp => {
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getProductCountForBp = ({ eventId, bpId, isApparelEvent }) => {
  let url =
    EventConstants.EVENT +
    'bid/products/count?event_obj_id=' +
    eventId +
    '&bp_id=' +
    bpId

  if (isApparelEvent) {
    url = url + '&itemLevelOnly=' + isApparelEvent
  }

  return AxiosUtil.get(url)
    .then(resp => {
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getPidInfo = (pidId: string) => {
  const url = EventConstants.COMMON + '/getPidInfo?pidId=' + pidId
  return AxiosUtil.get(url)
    .then(resp => {
      return resp
    })
    .catch(err => {
      throw err
    })
}

export const cloneImpressions = requestBody => {
  const url = EventConstants.COMMON + 'clone_impressions'
  return AxiosUtil.put(url, requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.data)
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

const refreshAttributes = (itemBpIds: string[], attributes: string[]) => {
  const requestBody = {
    item_bp_ids: itemBpIds,
    attribute_ids: getAttibuteIds(attributes),
  }
  return AxiosUtil.post(EventConstants.COMMON + 'refreshItemBps', requestBody, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}
export const refreshProducts = (
  selectedIds: { itemId: string; itemBpId: string }[],
  attributeNames: string[]
) => {
  const itemAttributes: string[] = []
  const attributes: string[] = []
  attributeNames.forEach(attr =>
    attr === 'brand' ? itemAttributes.push(attr) : attributes.push(attr)
  )

  const itemIds: string[] = []
  const itemBpIds: string[] = []
  selectedIds.forEach(item => {
    if (item.itemId !== undefined) {
      itemIds.push(item.itemId)
    }
    if (item.itemBpId !== undefined) {
      itemBpIds.push(item.itemBpId)
    }
  })
  const promises: AxiosPromise<any>[] = []
  if (itemIds.length > 0 && itemAttributes.length > 0) {
    promises.push(refreshItemAttributes(itemIds, itemAttributes))
  }
  if (itemBpIds.length > 0 && attributes.length > 0) {
    promises.push(refreshAttributes(itemBpIds, attributes))
  }

  return Promise.all(promises)
    .then(res => {
      const errorCount = res
        .flatMap(res => res.data.values)
        .filter(resStatus => resStatus.status === 'FAILURE').length

      if (errorCount > 0) {
        errorNotification([errorCount + ' error(s) in refreshing attributes'])
      } else {
        successNotification('Successfully refreshed and updated attributes')
      }
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

const refreshItemAttributes = (itemIds: string[], attributes: string[]) => {
  const requestBody = {
    item_ids: itemIds,
    attribute_ids: getAttibuteIds(attributes),
  }
  return AxiosUtil.post(
    EventConstants.COMMON + 'refreshItemAttributes',
    requestBody,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
}
const getAttibuteIds = (attributes: string[]) => {
  return attributes.flatMap(attr => AttributeMappings.get(attr) ?? [])
}
