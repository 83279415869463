import React, { useState, useEffect } from 'react'
import { Button, CustomDialog, TextField } from 'apex-ui-kit'
import { getAppConfig, setAppConfig } from '../../config/appConfig'
import { getStore } from '../../store/store'
import { resetStoreAction } from '../../store/resetStore'
import { ThemeProvider } from '@material-ui/core'
import { ThemeConfig } from 'apex-ui-kit'

export const BpImpersonation = props => {
  const config = getAppConfig()
  const [bpId, setBpId] = useState(config.bpId ?? 0)
  const [isImpersonationDialogOpen, setIsImpersonationDialogOpen] =
    useState(false)
  const [bpIdInput, setBpIdInput] = useState(config.bpId)

  useEffect(() => {
    config.bpId = bpIdInput
    setAppConfig(config)
  }, [bpIdInput, config])

  const changeBpId = () => {
    const store = getStore()
    store.dispatch(resetStoreAction())
    setBpId(bpIdInput)
    setIsImpersonationDialogOpen(false)
  }

  return (
    <ThemeProvider theme={ThemeConfig}>
      <div>
        {config.isImpersonation && (
          <div style={{ position: 'absolute', zIndex: 1, right: '40%' }}>
            <Button
              color="secondary"
              onClick={() => setIsImpersonationDialogOpen(true)}
            >
              You are Impersonating BP# {bpId}
            </Button>
          </div>
        )}
        <React.Fragment key={bpId}>{props.children}</React.Fragment>
        <CustomDialog
          open={isImpersonationDialogOpen}
          title="Impersonate different BP"
          variant="basic"
          onClose={() => setIsImpersonationDialogOpen(false)}
        >
          <TextField
            hintText="Enter BP Id"
            id="bpImpersonationId"
            label="BP Id"
            onChange={evt => setBpIdInput(evt.target.value)}
            value={bpIdInput}
          />
          <Button color="primary" onClick={() => changeBpId()}>
            Save
          </Button>
        </CustomDialog>
      </div>
    </ThemeProvider>
  )
}

export default BpImpersonation
