import { combineReducers } from 'redux'
import { eventsReducer } from './events/reducer'
import { authReducer } from './auth/reducer'
import { feedbackReducer } from './feedback/reducer'
import { productsReducer } from './products/reducer'
import { filesReducer } from './files/reducer'
import { attributeValuesReducer } from './attributeValues/reducer'
import { claimOrReleaseProductsReducer } from './claimOrReleaseProducts/reducer'
import { plansReducer } from './plans/reducer'
import { vendorsReducer } from './vendors/reducer'
import { navBarReducer } from './navBar/reducer'
import { requestVendorPreIsuReducer } from './requestVendorPreIsu/reducer'
import { rollupReducer } from './rollup/reducer'
import { associateProductsReducer } from './associateProducts/reducer'
import { addProductsReducer } from './addProducts/reducer'
import { settingsReducer } from './settings/reducer'
import { greenfieldReducer } from './greenfield/reducer'

export const appReducer = combineReducers({
  auth: authReducer,
  events: eventsReducer,
  vendors: vendorsReducer,
  feedback: feedbackReducer,
  products: productsReducer,
  files: filesReducer,
  attributeValues: attributeValuesReducer,
  claimOrReleaseProducts: claimOrReleaseProductsReducer,
  allPlans: plansReducer,
  navBar: navBarReducer,
  requestVendorPreIsu: requestVendorPreIsuReducer,
  rollup: rollupReducer,
  associateProducts: associateProductsReducer,
  addProducts: addProductsReducer,
  settings: settingsReducer,
  greenfield: greenfieldReducer,
})

export const rootReducer = (state, action) => {
  // when a reset_store action is dispatched it will reset redux state
  if (action.type === 'RESET_STORE') {
    state = undefined
  }

  return appReducer(state, action)
}
