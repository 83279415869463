export const FETCH_ITEMBP = 'FETCH_ITEMBP'
export const FETCH_ITEMBP_SUCCESS = 'FETCH_ITEMBP_SUCCESS'
export const FETCH_ITEMBP_ERROR = 'FETCH_ITEMBP_ERROR'

export const FETCH_ITEMBP_ALL = 'FETCH_ITEMBP_ALL'
export const FETCH_ITEMBP_ALL_SUCCESS = 'FETCH_ITEMBP_ALL_SUCCESS'
export const FETCH_ITEMBP_ALL_ERROR = 'FETCH_ITEMBP_ALL_ERROR'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR'

export const FETCH_PRODUCTS_FOR_ISU = 'FETCH_PRODUCTS_FOR_ISU'
export const FETCH_PRODUCTS_FOR_ISU_SUCCESS = 'FETCH_PRODUCTS_FOR_ISU_SUCCESS'
export const FETCH_PRODUCTS_FOR_ISU_ERROR = 'FETCH_PRODUCTS_FOR_ISU_ERROR'

export const FETCH_DELETED_PRODUCTS = 'FETCH_DELETED_PRODUCTS'
export const FETCH_DELETED_PRODUCTS_SUCCESS = 'FETCH_DELETED_PRODUCTS_SUCCESS'
export const FETCH_DELETED_PRODUCTS_ERROR = 'FETCH_DELETED_PRODUCTS_ERROR'

export const FETCH_DELETED_PRODUCTS_COUNT = 'FETCH_DELETED_PRODUCTS_COUNT'
export const FETCH_DELETED_PRODUCTS_COUNT_SUCCESS =
  'FETCH_DELETED_PRODUCTS_COUNT_SUCCESS'
export const FETCH_DELETED_PRODUCTS_COUNT_ERROR =
  'FETCH_DELETED_PRODUCTS_COUNT_ERROR'

export const FETCH_ISU_VALIDATIONS = 'FETCH_ISU_VALIDATIONS'
export const FETCH_ISU_VALIDATIONS_SUCCESS = 'FETCH_ISU_VALIDATIONS_SUCCESS'
export const FETCH_ISU_VALIDATIONS_ERROR = 'FETCH_ISU_VALIDATIONS_ERROR'

export const FETCH_ITEM_SETUP_COUNT = 'FETCH_ITEM_SETUP_COUNT'
export const FETCH_ITEM_SETUP_COUNT_SUCCESS = 'FETCH_ITEM_SETUP_COUNT_SUCCESS'
export const FETCH_ITEM_SETUP_COUNT_ERROR = 'FETCH_ITEM_SETUP_COUNT_ERROR'

export const RESET_PRODUCTS = 'RESET_PRODUCTS'

export const RESET_ISU_VALIDATION = 'RESET_ISU_VALIDATION'

export const RESET_ITEM_BPS = 'RESET_ITEM_BPS'

export const GET_EXCEL_SHEET = 'GET_EXCEL_SHEET'
export const GET_EXCEL_SHEET_SUCCESS = 'GET_EXCEL_SHEET_SUCCESS'
export const GET_EXCEL_SHEET_ERROR = 'GET_EXCEL_SHEET_ERROR'

export const BULK_UPDATE_PRODUCTS = 'BULK_UPDATE_PRODUCTS'
export const BULK_UPDATE_PRODUCTS_SUCCESS = 'BULK_UPDATE_PRODUCTS_SUCCESS'
export const BULK_UPDATE_PRODUCTS_ERROR = 'BULK_UPDATE_PRODUCTS_ERROR'

export const SAVE_PRODUCTS = 'SAVE_PRODUCTS'
export const SAVE_PRODUCTS_SUCCESS = 'SAVE_PRODUCTS_SUCCESS'
export const SAVE_PRODUCTS_ERROR = 'SAVE_PRODUCTS_ERROR'

export const COPY_PRODUCTS = 'COPY_PRODUCTS'
export const COPY_PRODUCTS_SUCCESS = 'COPY_PRODUCTS_SUCCESS'
export const COPY_PRODUCTS_ERROR = 'COPY_PRODUCTS_ERROR'

export const CLONE_PRODUCTS = 'CLONE_PRODUCTS'
export const CLONE_PRODUCTS_SUCCESS = 'CLONE_PRODUCTS_SUCCESS'
export const CLONE_PRODUCTS_ERROR = 'CLONE_PRODUCTS_ERROR'

export const FETCH_AUDIT_HIGHLIGHTS = 'FETCH_AUDIT_HIGHLIGHTS'
export const FETCH_AUDIT_HIGHLIGHTS_SUCCESS = 'FETCH_AUDIT_HIGHLIGHTS_SUCCESS'
export const FETCH_AUDIT_HIGHLIGHTS_ERROR = 'FETCH_AUDIT_HIGHLIGHTS_ERROR'

export const FETCH_IMPRESSIONS = 'FETCH_IMPRESSIONS'
export const FETCH_IMPRESSIONS_SUCCESS = 'FETCH_IMPRESSIONS_SUCCESS'
export const FETCH_IMPRESSIONS_ERROR = 'FETCH_IMPRESSIONS_ERROR'

export const RESET_AUDITS = 'RESET_AUDITS'

export const SEARCH_POTENTIAL_ITEMS = 'SEARCH_POTENTIAL_ITEMS'
export const SEARCH_POTENTIAL_ITEMS_SUCCESS = 'SEARCH_POTENTIAL_ITEMS_SUCCESS'
export const SEARCH_POTENTIAL_ITEMS_ERROR = 'SEARCH_POTENTIAL_ITEMS_ERROR'

export const UPDATE_ITEM_BPS_GROUP = 'UPDATE_ITEM_BPS_GROUP'
export const UPDATE_ITEM_BPS_GROUP_SUCCESS = 'UPDATE_ITEM_BPS_GROUP_SUCCESS'
export const UPDATE_ITEM_BPS_GROUP_ERROR = 'UPDATE_ITEM_BPS_GROUP_ERROR'

export const FETCH_ITEM_BY_ITEM_ID = 'FETCH_ITEM_BY_ITEM_ID'
export const FETCH_ITEM_BY_ITEM_ID_SUCCESS = 'FETCH_ITEM_BY_ITEM_ID_SUCCESS'
export const FETCH_ITEM_BY_ITEM_ID_ERROR = 'FETCH_ITEM_BY_ITEM_ID_ERROR'
