import { apexToast } from 'apex-ui-kit'

export const successNotification = (
  messages,
  autoHideDuration: number | null = 3200
) => {
  apexToast.success({
    message: messages,
    showDismissButton: true,
    autoHideDuration: autoHideDuration,
  })
}

export const errorNotification = (
  messages: string[],
  autoHideDuration?: number | null // autoHideDuration = null persists notification // TODO: update apex-ui-kit with local changes
) =>
  apexToast.error({
    message: messages,
    showDismissButton: true,
    autoHideDuration: autoHideDuration,
  })

export const infoNotification = (
  messages: string[],
  autoHideDuration: number | null = 3200
) =>
  apexToast.info({
    message: messages,
    showDismissButton: true,
    autoHideDuration,
  })

export const warningNotification = (
  messages: string[],
  autoHideDuration: number | null = 3200
) =>
  apexToast.warning({
    message: messages,
    showDismissButton: true,
    autoHideDuration,
  })

interface ErrorResponse {
  response?: {
    data?: {
      message?: string
    }
    status?: number
  }

  data?: {
    message?: string
  }

  message: string | null
}

export const errorResponseNotification = (errorResponse: ErrorResponse) => {
  let message: string | null =
    errorResponse?.response?.data?.message ?? //Look for response.data.message. Most of the error messages are in this path
    errorResponse?.data?.message ?? //Look at data.message
    errorResponse?.message //Finally just at message

  if (errorResponse?.response?.status === 401) {
    message = 'Unauthorized'
  }

  //This will avoid showing failed with status code toast which does not make sense to users
  if (message && !message.toLowerCase().includes('request failed with')) {
    apexToast.error({
      message,
      showDismissButton: true,
      autoHideDuration: 3200,
    })
  }
}
