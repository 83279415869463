import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getUserAction, setUserType } from '../../store/auth/actionCreator'
import { addAxiosInterceptorsForAngular } from '../../utils/axios-util'

const WithAngularAuth = ({
  //mapped props
  challengeAuthentication,
  //mapped dispatches
  setUserType,
  //props from parent component
  showAngularLogin,
  children,
}) => {
  addAxiosInterceptorsForAngular()
  //We will need to set userType in the store until completely migrated to react
  setUserType('I')
  useEffect(() => {
    if (challengeAuthentication) {
      showAngularLogin()
    }
  }, [challengeAuthentication, showAngularLogin])

  return <>{!challengeAuthentication && children}</>
}

const mapStateToProps = state => {
  const { auth } = state
  const { userName, challengeAuthentication } = auth
  return {
    userName,
    challengeAuthentication,
  }
}

const mapDispatchToProps = {
  getUserAction,
  setUserType,
}

export default connect(mapStateToProps, mapDispatchToProps)(WithAngularAuth)
