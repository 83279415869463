import * as authActionTypes from './actionTypes'

const initialState = {
  isAuthLoading: true,
  xAuthToken: null,
  partnerId: null,
  userName: null,
  userId: null,
  emailId: null,
  challengeAuthentication: false,
  userType: 'E', //External has lesser privileges compared to internal. So, initializing with external
}

export const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case authActionTypes.FETCH_USER: {
      return {
        ...state,
        isAuthLoading: true,
      }
    }
    case authActionTypes.FETCH_USER_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        isAuthLoading: false,
        partnerId: payload.data.partnerId,
        userName: payload.data.userName,
        impVendorIds: payload.data.impVendors,
        domVendorIds: payload.data.domVendors,
        dvsVendorIds: payload.data.dvsVendors,
        vendorsInfo: payload.data.vendorsInfo,
        userId: payload.data.userId,
        emailId: payload.data.emailId,
        userType: payload.data.userType,
      }
    }

    case authActionTypes.CHALLENGE_AUTHENTICATION: {
      const { payload } = action

      return {
        ...state,
        challengeAuthentication: payload,
      }
    }

    case authActionTypes.SET_USER_TYPE: {
      const { payload } = action

      return {
        ...state,
        userType: payload,
      }
    }

    default:
      return state
  }
}
