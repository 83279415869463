import * as addProductsActionTypes from './actionTypes'

const initialState = {
  plans: [],
  isAddingProducts: false,
  isFetchingPlans: false,
}

export const addProductsReducer = (
  state = initialState,
  action: { type?: string; payload?: any } = {}
) => {
  switch (action.type) {
    case addProductsActionTypes.ADD_PRODUCTS: {
      return {
        ...state,
        isAddingProducts: true,
      }
    }
    case addProductsActionTypes.ADD_PRODUCTS_SUCCESS:
    case addProductsActionTypes.ADD_PRODUCTS_ERROR: {
      return {
        ...state,
        isAddingProducts: false,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
