import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { WithAuthUser } from '../components/UserProvider'
import {
  storeInCacheWithExpiry,
  getFromCacheWithExpiry,
} from '../utils/LocalStorageUtil'
import { analyticsService } from '../services/analytics-service/analytics-service'
const PcnView = props => {
  const propsForView = getPropsForView(props)

  const history = useHistory()

  const handleBrowserBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  useEffect(() => {
    window.history.pushState(null, '', history.location.pathname)
    window.addEventListener('popstate', handleBrowserBackClick)
    // remove event listener on unmount
    return () => {
      window.removeEventListener('popstate', handleBrowserBackClick)
    }
  }, [handleBrowserBackClick, history.location.pathname])

  // Track pageviews on page change
  useEffect(() => {
    analyticsService.pageNavigationTimerStop(window?.location?.pathname)
    return history.listen(location => {
      analyticsService.pageView({ path: location.pathname })
      analyticsService.pageNavigationTimerStart(location.pathname)
    })
  }, [history])

  return (
    <div style={{ padding: '8px' }}>
      <WithAuthUser>{props.children(propsForView)}</WithAuthUser>
    </div>
  )
}

const getPropsForView = props => {
  //All of these views need the same props
  if (
    props.view === 'vendor-bids' ||
    props.view === 'vendor-costing-bids' ||
    props.view === 'manage-files' ||
    props.view === 'vendor-alignment' ||
    props.view === 'event' ||
    props.view === 'associateProducts' ||
    props.view === 'addProducts' ||
    props.view === 'reviewBids' ||
    props.view === 'manageBps'
  ) {
    const propsReceived = props?.location?.state?.reactProps ?? null
    let propsToUse = {}

    if (propsReceived) {
      propsToUse = props
      storeInCacheWithExpiry({
        key: 'viewProps',
        value: { view: props.view, props: props ?? {} },
        ttl: 1000 * 60 * 60 * 12,
      }) //expires in 12 hours
    } else {
      const propsFromCache = getFromCacheWithExpiry('viewProps')
      if (
        propsFromCache &&
        propsFromCache.view === props.view &&
        propsFromCache.props
      ) {
        propsToUse = propsFromCache.props
      }
    }

    return propsToUse
  } else {
    return props
  }
}

export default PcnView
