export const FETCH_GREENFIELD_METADATA = 'FETCH_GREENFIELD_METADATA'
export const FETCH_GREENFIELD_DATA = 'FETCH_GREENFIELD_DATA'
export const FETCH_GREENFIELD_FILTER_VALUES = 'FETCH_GREENFIELD_FILTER_VALUES'

export const FETCH_GREENFIELD_METADATA_SUCCESS =
  'FETCH_GREENFIELD_METADATA_SUCCESS'
export const FETCH_GREENFIELD_METADATA_ERROR = 'FETCH_GREENFIELD_METADATA_ERROR'
export const FETCH_GREENFIELD_DATA_SUCCESS = 'FETCH_GREENFIELD_DATA_SUCCESS'
export const FETCH_GREENFIELD_DATA_ERROR = 'FETCH_GREENFIELD_DATA_ERROR'
export const FETCH_GREENFIELD_FILTER_VALUES_SUCCESS =
  'FETCH_GREENFIELD_FILTER_VALUES_SUCCESS'
export const FETCH_GREENFIELD_FILTER_VALUES_ERROR =
  'FETCH_GREENFIELD_FILTER_VALUES_ERROR'
export const RESET_GREENFIELD_ACTION = 'RESET_GREENFIELD_ACTION'
