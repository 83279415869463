import { AxiosUtil } from '../utils/axios-util'
import { getImpersonationUserUrl } from '../utils/ImpersonationUtil'
import { errorResponseNotification } from './notification-service'
import EventConstants from '../constants/EventConstants'

export const getAuthenticatedUser = () => {
  let url = '/common/user/v1/user'
  url = getImpersonationUserUrl(url)
  return AxiosUtil.get(url).catch(err => {
    errorResponseNotification(err)
  })
}

export const getUserDetailsFromEmail = emailId => {
  return AxiosUtil.get(EventConstants.GET_VALID_USER_BY_EMAIL + emailId).catch(
    err => {
      errorResponseNotification(err)
      throw err
    }
  )
}

export const getUserDetailsFromUserId = userId => {
  return AxiosUtil.get(EventConstants.GET_VALID_USER_BY_ID + userId).catch(
    err => {
      errorResponseNotification(err)
      throw err
    }
  )
}
