export const FETCH_VENDORS_FOR_EVENT = 'FETCH_VENDORS_FOR_EVENT'
export const FETCH_VENDORS_FOR_EVENT_SUCCESS = 'FETCH_VENDORS_FOR_EVENT_SUCCESS'
export const FETCH_VENDORS_FOR_EVENT_ERROR = 'FETCH_VENDORS_FOR_EVENT_ERROR'
export const EXPORT_VENDOR_CONTACTS_FOR_EVENT = 'EXPORT_VENDOR_CONTACTS'
export const EXPORT_VENDOR_CONTACTS_FOR_EVENT_SUCCESS =
  'EXPORT_VENDOR_CONTACTS_SUCCESS'
export const EXPORT_VENDOR_CONTACTS_FOR_EVENT_ERROR =
  'EXPORT_VENDOR_CONTACTS_ERROR'
export const FETCH_PICKED_NLC_ITEMS = 'FETCH_PICKED_NLC_ITEMS'
export const FETCH_PICKED_NLC_ITEMS_SUCCESS = 'FETCH_PICKED_NLC_ITEMS_SUCCESS'
export const FETCH_PICKED_NLC_ITEMS_ERROR = 'FETCH_PICKED_NLC_ITEMS_ERROR'
