export const SOURCING_PERCENTAGE_FIELDS = [
  'section_data.import_vendor.free_carrier_unit',
  'section_data.import_vendor.import_unit',
  'section_data.domestic_vendor.prepaid_domestic_unit',
  'section_data.domestic_vendor.collect_domestic_unit',
  'section_data.domestic_vendor.direct_to_store_delivery_unit',
  'section_data.dvs_vendor.direct_vendor_shipping_unit',
]

const isUndefinedOrNull = (value: any): boolean =>
  value === undefined || value === null

const isUndefinedOrNullOrBlank = (value: any): boolean => {
  return isUndefinedOrNull(value) || value === ''
}

const isUndefinedOrNullOrBlankOrZero = (value: any): boolean => {
  return isUndefinedOrNull(value) || value === 0 || value === ''
}

const isUndefinedOrNullOrBlankWithSection = (
  data: any,
  field: string
): boolean => {
  const [sectionData, sectionName, fieldName] = field.split('.')
  return isUndefinedOrNullOrBlank(
    data?.[sectionData]?.[sectionName]?.[fieldName]
  )
}

const isUndefinedOrNullOrBlankOrZeroWithSection = (
  data: any,
  field: string
): boolean => {
  const [sectionData, sectionName, fieldName] = field.split('.')
  return isUndefinedOrNullOrBlankOrZero(
    data?.[sectionData]?.[sectionName]?.[fieldName]
  )
}

const isOwnedBrand = (data: any): boolean =>
  !isUndefinedOrNullOrBlankWithSection(data, 'section_data.item.brand_type') &&
  (data.section_data.item.brand_type === 'Target-Owned' ||
    data.section_data.item.brand_type === 'Target-Exclusive' ||
    data.section_data.item.brand_type === 'National-Exclusive' ||
    (data.section_data.item.brand_type === 'National-National' &&
      data.section_data?.import_vendor?.free_carrier_unit > 0) ||
    data.section_data?.import_vendor?.import_unit > 0)

const checkOwnedBrandFactoryPresent = (
  data: any,
  attrList: Array<string>
): Array<string> => {
  if (isOwnedBrand(data)) {
    const updatedAttrList = new Set([...attrList])
    if (
      isUndefinedOrNullOrBlankOrZeroWithSection(
        data,
        'section_data.shipping_location.factory_id'
      )
    ) {
      updatedAttrList.add('section_data.shipping_location.factory_id')
    }
    if (
      isUndefinedOrNullOrBlankOrZeroWithSection(
        data,
        'section_data.shipping_location.factory_name'
      )
    ) {
      updatedAttrList.add('section_data.shipping_location.factory_name')
    }
    return Array.from(updatedAttrList)
  } else {
    return attrList
  }
}
const itemDeptClassPresentInPIDRequiredData = (
  deptId: number,
  classId: number,
  brandType: string,
  sparkPid: string,
  pidRequiredDeptClassMap: any
): boolean => {
  const deptClassList = pidRequiredDeptClassMap
    ? pidRequiredDeptClassMap[deptId]
    : []
  return (
    isUndefinedOrNullOrBlank(sparkPid) &&
    (brandType === 'Target-Owned' || brandType === 'Target-Exclusive') &&
    deptClassList?.some(classNum => classNum === classId)
  )
}

const checkIfSparkPIDRequiredForDeptClass = (
  data: any,
  pIDRequiredDeptClassMap: any
): boolean => {
  if (
    itemDeptClassPresentInPIDRequiredData(
      data.section_data.item.dept,
      data.section_data.item.clazz,
      data.section_data.item.brand_type,
      data.section_data.item.spark_pid,
      pIDRequiredDeptClassMap
    )
  ) {
    return true
  }
  return false
}

const getUnits = (data: any, field: string): string => {
  const [sectionData, sectionName, fieldName] = field.split('.')
  return isUndefinedOrNullOrBlankWithSection(data, field)
    ? '0'
    : data[sectionData][sectionName][fieldName]
}

const isSourcingPercentageInvalid = (data: any, field: string): boolean => {
  if (SOURCING_PERCENTAGE_FIELDS.includes(field)) {
    try {
      const sum = SOURCING_PERCENTAGE_FIELDS.reduce(
        (acc, col) => acc + parseFloat(getUnits(data, col)),
        0.0
      )
      return sum !== 0.0 && sum !== 100.0
    } catch (exception) {
      // If an exception is caught then there must be an issue with the field values
      return true
    }
  } else {
    return false
  }
}

const isInArray = (field: string, arrList: Array<string>): boolean =>
  Array.isArray(arrList) && arrList.includes(field)

const DataValidationUtil = {
  isUndefinedOrNull,
  isUndefinedOrNullOrBlank,
  isUndefinedOrNullOrBlankOrZero,
  isUndefinedOrNullOrBlankOrZeroWithSection,
  isUndefinedOrNullOrBlankWithSection,
  checkOwnedBrandFactoryPresent,
  checkIfSparkPIDRequiredForDeptClass,
  isInArray,
  isOwnedBrand,
  isSourcingPercentageInvalid,
}

export default DataValidationUtil
