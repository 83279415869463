import * as eventActionTypes from './actionTypes'

const initialState = {
  isFetchingArchivedEvents: false,
  archivedEventsList: [],
  archivedEventsTotalCount: 0,
  isFetchingEvents: false,
  eventList: [],
  eventsPageCount: 0,
  eventsTotalCount: 0,
  isFetchingReleases: false,
  releases: [],
  isFetchingItemSetupEvents: false,
  itemSetupEventsList: [],
  itemsWaitingItemSetupCount: 0,
  isFetchingContactList: false,
  contactList: [],
  isFetchingEventAttributes: false,
  eventAttributes: [],
  isEventAttributesUpdating: false,
  isFetchingAssociatedPlans: false,
  eventPlansMap: {},
  isFetchingVendorCount: false,
  eventVendorCountMap: {},
  isFetchingProductCount: false,
  eventProductCountMap: {},
  authUserList: [],
  isFetchingAuthUsers: false,
  isFetchingEventUserViews: false,
  authUserListWithView: {},
  viewList: [],
  eventProductCountForBpMap: {},
  isFetchingProductCountForBp: false,
  event: {},
  isFetchingDistBps: false,
  distBpsList: [],
  searchCriteria: null,
  hasSearched: false,
}

export const eventsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case eventActionTypes.FETCH_EVENTS: {
      return {
        ...state,
        isFetchingEvents: true,
      }
    }
    case eventActionTypes.FETCH_EVENTS_SUCCESS: {
      const { events, per_page, total_count } = action.payload.data
      return {
        ...state,
        isFetchingEvents: false,
        eventList: events,
        eventsPageCount: per_page,
        eventsTotalCount: total_count,
      }
    }
    case eventActionTypes.FETCH_EVENTS_ERROR: {
      return {
        ...state,
        isFetchingEvents: false,
        eventList: [],
        eventsPageCount: 0,
        eventsTotalCount: 0,
      }
    }

    case eventActionTypes.FETCH_ARCHIVED_EVENTS: {
      return {
        ...state,
        isFetchingArchivedEvents: true,
      }
    }

    case eventActionTypes.FETCH_ARCHIVED_EVENTS_SUCCESS: {
      const { events, total_count } = action.payload.data
      return {
        ...state,
        isFetchingArchivedEvents: false,
        archivedEventsList: events,
        archivedEventsTotalCount: total_count,
      }
    }

    case eventActionTypes.FETCH_ARCHIVED_EVENTS_ERROR: {
      return {
        ...state,
        isFetchingArchivedEvents: false,
        archivedEventsList: [],
        archivedEventsTotalCount: 0,
      }
    }

    case eventActionTypes.FETCH_RELEASES: {
      return {
        ...state,
        isFetchingReleases: true,
      }
    }
    case eventActionTypes.FETCH_RELEASES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isFetchingReleases: false,
        releases: payload.data.releases,
      }
    }
    case eventActionTypes.FETCH_RELEASES_ERROR: {
      return {
        ...state,
        isFetchingReleases: false,
        releases: [],
      }
    }

    case eventActionTypes.FETCH_ITEM_SETUP_EVENTS: {
      return {
        ...state,
        isFetchingItemSetupEvents: true,
      }
    }
    case eventActionTypes.FETCH_ITEM_SETUP_EVENTS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isFetchingItemSetupEvents: false,
        itemsWaitingItemSetupCount: payload.data.total_count,
        itemSetupEventsList: payload.data.events,
      }
    }
    case eventActionTypes.FETCH_ITEM_SETUP_EVENTS_ERROR: {
      return {
        ...state,
        isFetchingItemSetupEvents: false,
        itemsWaitingItemSetupCount: 0,
        itemSetupEventsList: [],
      }
    }

    case eventActionTypes.SEARCH_EVENTS: {
      return {
        ...state,
        isFetchingEvents: true,
      }
    }
    case eventActionTypes.SEARCH_EVENTS_SUCCESS: {
      const { events, per_page, total_count } = action.payload.data
      return {
        ...state,
        isFetchingEvents: false,
        eventList: events,
        eventsPageCount: per_page,
        eventsTotalCount: total_count,
      }
    }
    case eventActionTypes.SEARCH_EVENTS_ERROR: {
      return {
        ...state,
        isFetchingEvents: false,
        eventList: [],
        eventsPageCount: 0,
        eventsTotalCount: 0,
      }
    }

    case eventActionTypes.FETCH_EVENT_CONTACTS: {
      return {
        ...state,
        isFetchingContactList: true,
      }
    }
    case eventActionTypes.FETCH_EVENT_CONTACTS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isFetchingContactList: false,
        contactList: payload.data,
      }
    }
    case eventActionTypes.FETCH_EVENT_CONTACTS_ERROR: {
      return {
        ...state,
        isFetchingContactList: false,
        contactList: [],
      }
    }

    case eventActionTypes.FETCH_EVENT_ATTRIBUTES: {
      return {
        ...state,
        isFetchingEventAttributes: true,
      }
    }
    case eventActionTypes.FETCH_EVENT_ATTRIBUTES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        isFetchingEventAttributes: false,
        eventAttributes: payload,
      }
    }
    case eventActionTypes.FETCH_EVENT_ATTRIBUTES_ERROR: {
      return {
        ...state,
        isFetchingEventAttributes: false,
        eventAttributes: [],
      }
    }

    case eventActionTypes.UPDATE_EVENT_ATTRIBUTES: {
      return {
        ...state,
        isEventAttributesUpdating: true,
      }
    }
    case eventActionTypes.UPDATE_EVENT_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        isEventAttributesUpdating: false,
      }
    }
    case eventActionTypes.UPDATE_EVENT_ATTRIBUTES_ERROR: {
      return {
        ...state,
        isEventAttributesUpdating: false,
      }
    }

    case eventActionTypes.UPDATE_BID_SUCCESS: {
      const updatedBid = action.payload.data
      return {
        ...state,
        eventList: state.eventList.map(event => {
          if (event.bid_response.id === updatedBid.id) {
            return {
              ...event,
              bid_response: updatedBid,
            }
          } else {
            return event
          }
        }),
      }
    }

    case eventActionTypes.FETCH_ASSOCIATED_PLANS: {
      return {
        ...state,
        eventPlansMap: {
          [action.eventId]: { isFetchingAssociatedPlans: true, planList: [] },
        },
      }
    }

    case eventActionTypes.FETCH_ASSOCIATED_PLANS_SUCCESS: {
      return {
        ...state,
        eventPlansMap: {
          ...state.eventPlansMap,
          [action.eventId]: {
            isFetchingAssociatedPlans: false,
            planList: action.payload.data,
          },
        },
      }
    }

    case eventActionTypes.FETCH_ASSOCIATED_PLANS_ERROR: {
      return {
        ...state,
        eventPlansMap: {
          ...state.eventPlansMap,
          [action.eventId]: { isFetchingAssociatedPlans: false, planList: [] },
        },
      }
    }

    case eventActionTypes.FETCH_VENDOR_COUNT: {
      return {
        ...state,
        eventVendorCountMap: {
          [action.eventId]: { isFetchingVendorCount: true, vendorCount: '' },
        },
      }
    }

    case eventActionTypes.FETCH_VENDOR_COUNT_SUCCESS: {
      return {
        ...state,
        eventVendorCountMap: {
          ...state.eventVendorCountMap,
          [action.eventId]: {
            isFetchingVendorCount: false,
            vendorCount: action.payload.data.total_count,
          },
        },
      }
    }

    case eventActionTypes.FETCH_VENDOR_COUNT_ERROR: {
      return {
        ...state,
        eventVendorCountMap: {
          ...state.eventVendorCountMap,
          [action.eventId]: { isFetchingVendorCount: true, vendorCount: '' },
        },
      }
    }

    case eventActionTypes.FETCH_PRODUCT_COUNT: {
      return {
        ...state,
        eventProductCountMap: {
          [action.bpId ? action.bpId : action.eventId]: {
            isFetchingProductCount: true,
            productCount: '',
          },
        },
      }
    }

    case eventActionTypes.FETCH_PRODUCT_COUNT_SUCCESS: {
      return {
        ...state,
        eventProductCountMap: {
          ...state.eventProductCountMap,
          [action.bpId ? action.bpId : action.eventId]: {
            isFetchingProductCount: false,
            productCount: action.payload.data.count,
          },
        },
      }
    }

    case eventActionTypes.FETCH_PRODUCT_COUNT_ERROR: {
      return {
        ...state,
        eventProductCountMap: {
          ...state.eventProductCountMap,
          [action.bpId ? action.bpId : action.eventId]: {
            isFetchingProductCount: true,
            productCount: '',
          },
        },
      }
    }

    case eventActionTypes.SET_SEARCH_CRITERIA: {
      const { eventName, eventId, userName, page } = action.payload
      return {
        ...state,
        searchCriteria: {
          eventName,
          eventId,
          userName,
          page,
        },
        hasSearched: true,
      }
    }

    case eventActionTypes.CLEAR_SEARCH_CRITERIA: {
      return {
        ...state,
        searchCriteria: null,
        hasSearched: false,
      }
    }

    case eventActionTypes.SEARCH_INTERNAL_EVENTS: {
      return {
        ...state,
        isFetchingEvents: true,
      }
    }

    case eventActionTypes.SEARCH_INTERNAL_EVENTS_SUCCESS: {
      const { events, per_page, total_count } = action.payload.data
      return {
        ...state,
        isFetchingEvents: false,
        eventList: events,
        eventsPageCount: per_page,
        eventsTotalCount: total_count,
      }
    }

    case eventActionTypes.SEARCH_INTERNAL_EVENTS_ERROR: {
      return {
        ...state,
        isFetchingEvents: false,
        eventList: [],
        eventsPageCount: 0,
        eventsTotalCount: 0,
      }
    }

    case eventActionTypes.GET_AUTH_USERS: {
      return {
        ...state,
        isFetchingAuthUsers: true,
      }
    }
    case eventActionTypes.GET_AUTH_USERS_SUCCESS: {
      return {
        ...state,
        isFetchingAuthUsers: false,
        authUserList: action.payload,
      }
    }
    case eventActionTypes.GET_AUTH_USERS_ERROR: {
      return {
        ...state,
        isFetchingAuthUsers: false,
        authUserList: [],
      }
    }

    case eventActionTypes.GET_EVENT_USER_VIEWS: {
      return {
        ...state,
        isFetchingEventUserViews: true,
      }
    }
    case eventActionTypes.GET_EVENT_USER_VIEWS_SUCCESS: {
      const { authUsers, view, event } = action.payload
      return {
        ...state,
        isFetchingEventUserViews: false,
        authUserListWithView: authUsers,
        viewList: view,
        event: event,
      }
    }
    case eventActionTypes.GET_EVENT_USER_VIEWS_ERROR: {
      return {
        ...state,
        isFetchingEventUserViews: false,
        authUserListWithView: {},
        viewList: [],
        event: {},
      }
    }
    case eventActionTypes.RESET_EVENT_USER_VIEWS: {
      return {
        ...state,
        isFetchingEventUserViews: false,
        authUserListWithView: {},
        viewList: [],
        event: {},
      }
    }

    case eventActionTypes.RESET_EVENT_LISTS: {
      return {
        ...state,
        archivedEventsList: [],
        eventList: [],
        itemSetupEventsList: [],
        archivedEventsTotalCount: 0,
        itemsWaitingItemSetupCount: 0,
        eventsPageCount: 0,
        eventsTotalCount: 0,
      }
    }

    case eventActionTypes.FETCH_PRODUCT_COUNT_FOR_BP: {
      return {
        ...state,
        eventProductCountForBpMap: {
          [action.bpId]: {
            isFetchingProductCountForBp: true,
            productCount: '',
          },
        },
      }
    }

    case eventActionTypes.FETCH_PRODUCT_COUNT_FOR_BP_SUCCESS: {
      return {
        ...state,
        eventProductCountForBpMap: {
          ...state.eventProductCountForBpMap,
          [action.bpId]: {
            isFetchingProductCountForBp: false,
            productCount: action.payload.data.count,
          },
        },
      }
    }

    case eventActionTypes.FETCH_PRODUCT_COUNT_FOR_BP_ERROR: {
      return {
        ...state,
        eventProductCountForBpMap: {
          ...state.eventProductCountForBpMap,
          [action.bpId]: {
            isFetchingProductCountForBp: true,
            productCount: '',
          },
        },
      }
    }

    case eventActionTypes.FETCH_DISTRIBUTOR_BPS: {
      return {
        ...state,
        isFetchingDistBps: true,
      }
    }
    case eventActionTypes.FETCH_DISTRIBUTOR_BPS_SUCCESS: {
      return {
        ...state,
        distBpsList: action.payload,
        isFetchingDistBps: false,
      }
    }
    case eventActionTypes.FETCH_DISTRIBUTOR_BPS_ERROR: {
      return {
        ...state,
        distBpsList: [],
        isFetchingDistBps: false,
      }
    }

    default:
      return state
  }
}
