export const FETCH_FEEDBACK_CONVERSATION = 'FETCH_FEEDBACK_CONVERSATION'
export const FETCH_FEEDBACK_CONVERSATION_SUCCESS =
  'FETCH_FEEDBACK_CONVERSATION_SUCCESS'
export const FETCH_FEEDBACK_CONVERSATION_ERROR =
  'FETCH_FEEDBACK_CONVERSATION_ERROR'

export const FETCH_FEEDBACK_HIGHLIGHT = 'FETCH_FEEDBACK_HIGHLIGHT'
export const FETCH_FEEDBACK_HIGHLIGHT_SUCCESS =
  'FETCH_FEEDBACK_HIGHLIGHT_SUCCESS'
export const FETCH_FEEDBACK_HIGHLIGHT_ERROR = 'FETCH_FEEDBACK_HIGHLIGHT_ERROR'

export const SAVE_FEEDBACK_CONVERSATION = 'SAVE_FEEDBACK_CONVERSATION'
export const SAVE_FEEDBACK_CONVERSATION_SUCCESS =
  'SAVE_FEEDBACK_CONVERSATION_SUCCESS'
export const SAVE_FEEDBACK_CONVERSATION_ERROR =
  'SAVE_FEEDBACK_CONVERSATION_ERROR'

export const FETCH_FEEDBACK = 'FETCH_FEEDBACK'
export const FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS'
export const FETCH_FEEDBACK_ERROR = 'FETCH_FEEDBACK_ERROR'
export const UPDATE_TOTAL_UNREAD_FEEDBACK_COUNT =
  'UPDATE_TOTAL_UNREAD_FEEDBACK_COUNT'

export const ADD_FEEDBACK_FILE = 'ADD_FEEDBACK_FILE'
export const UPDATE_FEEDBACK_FILE = 'UPDATE_FEEDBACK_FILE'
