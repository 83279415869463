import React from 'react'
import { Redirect } from 'react-router'
import { withAngularContext } from '../../contexts/AngularContext'

const PcnRedirect = props => {
  if (props.angularProps.isAngular) {
    props.angularProps.angularRedirect(props)
    props.resetRedirection()
    return <h1>Angular Redirect</h1>
  } else {
    return (
      <Redirect
        to={{
          pathname: props.redirectTo,
          state: {
            reactProps: props.redirectionProps,
            backClicked: props?.backClicked,
          },
        }}
      />
    )
  }
}

export default withAngularContext(PcnRedirect)
