import DataValidationUtil from '../../utils/DataValidationUtil'
import { getStore } from '../../store/store'
import { validators as helpers } from './PMUAttributesBuilder'

export const getPMUFields = () => {
  const {
    commonPMUFields,
    importPMUAlwaysRequiredFields,
    importPMUConditionallyRequiredFields,
    domesticPMUAlwaysRequiredFields,
    domesticPMUConditionallyRequiredFields,
    dvsPMUFields,
  } = getStore().getState().products.pmuRules

  const importPMUFields = [
    ...importPMUAlwaysRequiredFields,
    ...importPMUConditionallyRequiredFields,
  ]

  const domesticPMUFields = [
    ...domesticPMUAlwaysRequiredFields,
    ...domesticPMUConditionallyRequiredFields,
  ]

  return [
    ...new Set([
      ...importPMUFields,
      ...domesticPMUFields,
      ...dvsPMUFields,
      ...commonPMUFields,
      'section_data.us_unit.vendor_est_total_receipt_units',
    ]),
  ]
}

export const getPMUFieldsForVendor = ({
  hasImportVendors = false,
  hasDomesticVendors = false,
  hasDvsVendors = false,
} = {}) => {
  const {
    commonPMUFields,
    importPMUAlwaysRequiredFields,
    importPMUConditionallyRequiredFields,
    domesticPMUAlwaysRequiredFields,
    domesticPMUConditionallyRequiredFields,
    dvsPMUFields,
  } = getStore().getState().products.pmuRules

  const attributes: string[] = [
    ...commonPMUFields,
    'section_data.us_unit.vendor_est_total_receipt_units',
  ]

  if (hasImportVendors) {
    attributes.push(
      ...importPMUAlwaysRequiredFields,
      ...importPMUConditionallyRequiredFields
    )
  }

  if (hasDomesticVendors) {
    attributes.push(
      ...domesticPMUAlwaysRequiredFields,
      ...domesticPMUConditionallyRequiredFields
    )
  }

  if (hasDvsVendors) {
    attributes.push(...dvsPMUFields)
  }

  return [...new Set(attributes)]
}

export const validatePMU = node => {
  const columns: string[] = []

  // All are unset
  if (
    [
      node?.data?.section_data?.import_vendor?.free_carrier_unit,
      node?.data?.section_data?.import_vendor?.import_unit,
      node?.data?.section_data?.domestic_vendor?.prepaid_domestic_unit,
      node?.data?.section_data?.domestic_vendor?.collect_domestic_unit,
      node?.data?.section_data?.domestic_vendor?.direct_to_store_delivery_unit,
      node?.data?.section_data?.dvs_vendor?.direct_vendor_shipping_unit,
    ].every(DataValidationUtil.isUndefinedOrNullOrBlankOrZero)
  ) {
    columns.push(
      'section_data.import_vendor.free_carrier_unit',
      'section_data.import_vendor.import_unit',
      'section_data.domestic_vendor.prepaid_domestic_unit',
      'section_data.domestic_vendor.collect_domestic_unit',
      'section_data.domestic_vendor.direct_to_store_delivery_unit',
      'section_data.dvs_vendor.direct_vendor_shipping_unit'
    )
  }

  // Validate Import free carrier unit and import unit
  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.import_vendor?.free_carrier_unit
    ) ||
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.import_vendor?.import_unit
    )
  ) {
    columns.push(
      ...helpers.validateCommonFieldsForPMU(node),
      ...helpers.validateImportFieldsForPMU(node)
    )
  }

  // Valdiate domestic prepaid, collect, and direct to store delivery units
  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.prepaid_domestic_unit
    ) ||
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.collect_domestic_unit
    ) ||
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.direct_to_store_delivery_unit
    )
  ) {
    columns.push(
      ...helpers.validateCommonFieldsForPMU(node),
      ...helpers.validateDomesticFieldsForPMU(node)
    )
  }

  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.dvs_vendor?.direct_vendor_shipping_unit
    )
  ) {
    columns.push(...helpers.validateDVSFieldsForPMU(node))
  }

  return columns
}

const validateCommonFieldsForPMU = (node): string[] => {
  const { commonPMUFields } = getStore().getState().products.pmuRules

  return commonPMUFields.reduce((columns: string[], pmuField) => {
    if (pmuField === 'section_data.us_unit.est_total') {
      if (
        DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
          node?.data?.section_data?.us_unit?.vendor_est_total_receipt_units
        ) &&
        DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
          node?.data?.section_data?.us_unit?.est_total
        )
      ) {
        columns.push('section_data.us_unit.vendor_est_total_receipt_units')
      }
    } else if (
      DataValidationUtil.isUndefinedOrNullOrBlankOrZeroWithSection(
        node.data,
        pmuField
      )
    ) {
      columns.push(pmuField)
    }
    return columns
  }, [])
}

const validateImportFieldsForPMU = (node): Array<string> => {
  const { importPMUAlwaysRequiredFields } =
    getStore().getState().products.pmuRules

  const columns = importPMUAlwaysRequiredFields.reduce(
    (columns: string[], importField) => {
      if (importField === 'section_data.import_vendor.duty_rate') {
        if (
          DataValidationUtil.isUndefinedOrNullOrBlankWithSection(
            node.data,
            importField
          )
        ) {
          columns.push(importField)
        }
      } else if (
        DataValidationUtil.isUndefinedOrNullOrBlankOrZeroWithSection(
          node.data,
          importField
        )
      ) {
        columns.push(importField)
      }
      return columns
    },
    []
  )

  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.import_vendor?.free_carrier_unit
    ) &&
    DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.import_vendor?.factory_unit_cost
    )
  ) {
    columns.push('section_data.import_vendor.factory_unit_cost')
  }

  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.import_vendor?.import_unit
    ) &&
    DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.import_vendor?.port_of_export_unit_cost
    )
  ) {
    columns.push('section_data.import_vendor.port_of_export_unit_cost')
  }

  if (
    node?.data?.section_data?.import_vendor?.free_carrier_unit > 0 &&
    DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.shipping_location?.factory_city
    )
  ) {
    columns.push('section_data.shipping_location.factory_city')
  }

  return columns
}

const validateDomesticFieldsForPMU = node => {
  const { domesticPMUAlwaysRequiredFields } =
    getStore().getState().products.pmuRules

  const columns = domesticPMUAlwaysRequiredFields.filter(domesticField =>
    DataValidationUtil.isUndefinedOrNullOrBlankOrZeroWithSection(
      node.data,
      domesticField
    )
  )

  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.prepaid_domestic_unit
    ) &&
    DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.prepaid_domestic_unit_cost
    )
  ) {
    columns.push('section_data.domestic_vendor.prepaid_domestic_unit_cost')
  }

  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.collect_domestic_unit
    ) &&
    DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.collect_domestic_unit_cost
    )
  ) {
    columns.push('section_data.domestic_vendor.collect_domestic_unit_cost')
  }

  if (
    !DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.direct_to_store_delivery_unit
    ) &&
    DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.direct_to_store_delivery_cost
    )
  ) {
    columns.push('section_data.domestic_vendor.direct_to_store_delivery_cost')
  }

  if (
    node?.data?.section_data?.domestic_vendor?.collect_domestic_unit > 0 &&
    DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
      node?.data?.section_data?.domestic_vendor?.ship_point
    )
  ) {
    columns.push('section_data.domestic_vendor.ship_point')
  }

  return columns
}

const validateDVSFieldsForPMU = node => {
  const { dvsPMUFields } = getStore().getState().products.pmuRules

  return dvsPMUFields.reduce((columns: string[], dvsField) => {
    if (dvsField === 'section_data.us_unit.est_total') {
      if (
        DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
          node?.data?.section_data?.us_unit?.vendor_est_total_receipt_units
        ) &&
        DataValidationUtil.isUndefinedOrNullOrBlankOrZero(
          node?.data?.section_data?.us_unit?.est_total
        )
      ) {
        columns.push('section_data.us_unit.vendor_est_total_receipt_units')
      }
    } else if (
      DataValidationUtil.isUndefinedOrNullOrBlankOrZeroWithSection(
        node.data,
        dvsField
      )
    ) {
      columns.push(dvsField)
    }
    return columns
  }, [])
}

export const validators = {
  validateCommonFieldsForPMU,
  validateImportFieldsForPMU,
  validateDomesticFieldsForPMU,
  validateDVSFieldsForPMU,
}
