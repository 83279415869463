import React, { ReactNode } from 'react'
import HomeIcon from '@material-ui/icons/Home'
import EventIcon from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting'

const PageRouteAndIcon = (route: string, icon: ReactNode) => ({
  route: route,
  icon: icon,
})

const ExternalNavRoutes = {
  Home: PageRouteAndIcon('/', <HomeIcon />),
  Events: PageRouteAndIcon('/events', <EventIcon />),
  'Pre-Item Setup': PageRouteAndIcon('/item-setup', <PermDataSettingIcon />),
  Logout: PageRouteAndIcon('/logout', <ExitToAppIcon />),
}

export default ExternalNavRoutes
