export const commonConfig = {
  REACT_APP_SERVICE_URL: window.location.protocol + '//' + window.location.host,

  smartAssist: {
    baseUrl:
      window.location.protocol +
      '//' +
      window.location.host +
      '/smart_assist_documents/v1',
  },
}
