import * as eventActionTypes from './actionTypes'
import { getReleases } from '../../services/homepage-service'

import {
  archiveEvents,
  getEvents,
  getItemSetupEvents,
  submit,
  searchEvents,
  fetchEventContacts,
  unarchiveEvents,
  getEventAttributes,
  updateEventAttributes,
  getDistBps,
} from '../../services/events-service'

import {
  getUserEvents,
  getAssociatedPlans,
  searchEvents as searchEventsInternal,
  getAuthUsers,
  getEventUserViews,
  submitBid,
} from '../../services/internal/events-service'

import { getVendorsByEvent } from '../../services/internal/vendor-grid-service'
import {
  getProductCount,
  getProductCountForBp,
} from '../../services/products-service'
import {
  getPMUFieldsForVendor,
  getPMUFields,
} from '../../components/ValidatePMU/PMUAttributesBuilder'

export const fetchEventsAction =
  (page, perPage, sortByBidTs) => (dispatch, getStore) => {
    dispatch({
      type: eventActionTypes.FETCH_EVENTS,
    })

    const bpId = getStore().auth.partnerId

    return getEvents(page, perPage, bpId, sortByBidTs, false)
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_EVENTS_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_EVENTS_ERROR,
          payload: err,
        })
      })
  }

export const fetchInternalUserEventsAction =
  ({ isUserEvent, page, perPage }) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.FETCH_EVENTS,
    })

    return getUserEvents({ isUserEvent, page, perPage })
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_EVENTS_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_EVENTS_ERROR,
          payload: err,
        })
      })
  }

export const fetchReleasesAction = () => dispatch => {
  dispatch({
    type: eventActionTypes.FETCH_RELEASES,
  })

  return getReleases()
    .then(res => {
      dispatch({
        type: eventActionTypes.FETCH_RELEASES_SUCCESS,
        payload: res,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.FETCH_RELEASES_ERROR,
        payload: err,
      })
    })
}

export const fetchItemSetupEventsAction =
  (page, perPage) => (dispatch, getStore) => {
    dispatch({
      type: eventActionTypes.FETCH_ITEM_SETUP_EVENTS,
    })

    const bpId = getStore().auth.partnerId

    return getItemSetupEvents(page, perPage, bpId)
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_ITEM_SETUP_EVENTS_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_ITEM_SETUP_EVENTS_ERROR,
          payload: err,
        })
      })
  }

export const archiveEventsAction = (eventObjectid, bidId) => dispatch => {
  dispatch({
    type: eventActionTypes.ARCHIVE_EVENTS,
  })

  return archiveEvents(eventObjectid, bidId)
    .then(res => {
      dispatch({
        type: eventActionTypes.ARCHIVE_EVENTS_SUCCESS,
        payload: res,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.ARCHIVE_EVENTS_ERROR,
        payload: err,
      })
    })
}

export const fetchArchivedEventsAction =
  (page = 1, perPage = 1, sortByBidTs = false) =>
  (dispatch, getStore) => {
    dispatch({
      type: eventActionTypes.FETCH_ARCHIVED_EVENTS,
    })

    const bpId = getStore().auth.partnerId

    return getEvents(page, perPage, bpId, sortByBidTs, true)
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_ARCHIVED_EVENTS_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_ARCHIVED_EVENTS_ERROR,
          payload: err,
        })
      })
  }

export const unarchiveEventsAction =
  (eventObjectid, bidId, eventName) => dispatch => {
    dispatch({
      type: eventActionTypes.UNARCHIVE_EVENTS,
    })

    return unarchiveEvents(eventObjectid, bidId, eventName)
      .then(res => {
        dispatch({
          type: eventActionTypes.UNARCHIVE_EVENTS_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.UNARCHIVE_EVENTS_ERROR,
          payload: err,
        })
      })
  }

export const submitEventsAction = bid => dispatch => {
  bid.status_key = 'submitted_to_target'
  dispatch({
    type: eventActionTypes.SUBMIT_EVENTS,
  })
  return submit(
    bid,
    'Bid submitted successfully. Email was generated and sent.',
    'Failed to submit the bid',
    true
  )
    .then(res => {
      dispatch({
        type: eventActionTypes.SUBMIT_EVENTS_SUCCESS,
        payload: res,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.SUBMIT_EVENTS_ERROR,
        payload: err,
      })
    })
}

export const updateBidAction =
  (
    bid,
    successMessage = 'Updated successfully.',
    errorMessage = 'Failed to update.'
  ) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.UPDATE_BID,
    })
    return submit(bid, successMessage, errorMessage)
      .then(res => {
        dispatch({
          type: eventActionTypes.UPDATE_BID_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.UPDATE_BID_ERROR,
          payload: err,
        })
      })
  }

export const setSearchCriteriaAction =
  ({ eventName, eventId, userName, page }) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.SET_SEARCH_CRITERIA,
      payload: { eventName, eventId, userName, page },
    })
  }

export const clearSearchCriteriaAction = () => dispatch => {
  dispatch({
    type: eventActionTypes.CLEAR_SEARCH_CRITERIA,
  })
}

export const searchEventsAction =
  (page, perPage, eventName, eventId) => (dispatch, getStore) => {
    dispatch({
      type: eventActionTypes.SEARCH_EVENTS,
    })

    const bpId = getStore().auth.partnerId

    return searchEvents(page, perPage, bpId, eventName, eventId)
      .then(res => {
        dispatch({
          type: eventActionTypes.SEARCH_EVENTS_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.SEARCH_EVENTS_ERROR,
          payload: err,
        })
      })
  }

export const fetchEventContactsAction = eventId => (dispatch, getStore) => {
  dispatch({
    type: eventActionTypes.FETCH_EVENT_CONTACTS,
  })

  return fetchEventContacts(eventId)
    .then(res => {
      dispatch({
        type: eventActionTypes.FETCH_EVENT_CONTACTS_SUCCESS,
        payload: res,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.FETCH_EVENT_CONTACTS_ERROR,
        payload: err,
      })
    })
}

export const fetchEventAttributesAction = (eventId, bidId) => dispatch => {
  dispatch({
    type: eventActionTypes.FETCH_EVENT_ATTRIBUTES,
  })

  return getEventAttributes(eventId, bidId)
    .then(res => {
      dispatch({
        type: eventActionTypes.FETCH_EVENT_ATTRIBUTES_SUCCESS,
        payload: res.data,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.FETCH_EVENT_ATTRIBUTES_ERROR,
        payload: err,
      })
    })
}

export const fetchEventAttributesForPMUAction =
  (eventId, bidId, impVendorIds, domVendorIds, dvsVendorIds) => dispatch => {
    dispatch({
      type: eventActionTypes.FETCH_EVENT_ATTRIBUTES,
    })

    return getEventAttributes(eventId, bidId)
      .then(res => {
        const eventAttributes = res.data
        const eventAttributePaths = eventAttributes.map(
          ({ attribute }) => 'section_data.' + attribute
        )

        if (eventAttributePaths.length > 0) {
          const pmuAttributes = bidId
            ? getPMUFieldsForVendor({
                hasImportVendors: impVendorIds?.length > 0,
                hasDomesticVendors: domVendorIds?.length > 0,
                hasDvsVendors: dvsVendorIds?.length > 0,
              })
            : getPMUFields()

          const missingPMUAttributes = pmuAttributes
            .filter(attribute => !eventAttributePaths.includes(attribute))
            .map(missingAttribute => {
              const [, section, attribute] = missingAttribute.split('.')
              return {
                attribute: `${section}.${attribute}`,
                event_level: !bidId,
                status: 'E',
              }
            })

          if (missingPMUAttributes.length > 0) {
            const updated = [...eventAttributes, ...missingPMUAttributes]
            return updateEventAttributesAction(
              eventId,
              bidId,
              updated
            )(dispatch).then(() => {
              dispatch({
                type: eventActionTypes.FETCH_EVENT_ATTRIBUTES_SUCCESS,
                payload: updated,
              })
              return {
                ...res,
                data: updated,
              }
            })
          }
        }

        dispatch({
          type: eventActionTypes.FETCH_EVENT_ATTRIBUTES_SUCCESS,
          payload: res.data,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_EVENT_ATTRIBUTES_ERROR,
          payload: err,
        })
      })
  }

export const updateEventAttributesAction =
  (eventId, bidId, requestBody) => dispatch => {
    dispatch({
      type: eventActionTypes.UPDATE_EVENT_ATTRIBUTES,
    })

    return updateEventAttributes(eventId, bidId, requestBody)
      .then(res => {
        dispatch({
          type: eventActionTypes.UPDATE_EVENT_ATTRIBUTES_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.UPDATE_EVENT_ATTRIBUTES_ERROR,
          payload: err,
        })
      })
  }

export const fetchAssociatedPlansAction =
  ({ eventId }) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.FETCH_ASSOCIATED_PLANS,
      eventId,
    })

    return getAssociatedPlans({ eventId })
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_ASSOCIATED_PLANS_SUCCESS,
          payload: res,
          eventId,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_ASSOCIATED_PLANS_ERROR,
          payload: err,
          eventId,
        })
      })
  }

export const fetchVendorCountAction =
  ({ eventId }) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.FETCH_VENDOR_COUNT,
      eventId,
    })

    return getVendorsByEvent({ eventId, page: 1, perPage: 1 })
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_VENDOR_COUNT_SUCCESS,
          payload: res,
          eventId,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_VENDOR_COUNT_ERROR,
          payload: err,
          eventId,
        })
      })
  }

export const fetchProductCountAction =
  ({ eventId, bpId, isBusinessConfirmed }) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.FETCH_PRODUCT_COUNT,
      eventId,
      bpId,
    })

    return getProductCount({ eventId, bpId, isBusinessConfirmed })
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_PRODUCT_COUNT_SUCCESS,
          payload: res,
          eventId,
          bpId,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_PRODUCT_COUNT_ERROR,
          payload: err,
          eventId,
          bpId,
        })
      })
  }

export const fetchProductCountActionForBp =
  ({ eventId, bpId, isApparelEvent }) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.FETCH_PRODUCT_COUNT_FOR_BP,
      bpId,
    })

    return getProductCountForBp({ eventId, bpId, isApparelEvent })
      .then(res => {
        dispatch({
          type: eventActionTypes.FETCH_PRODUCT_COUNT_FOR_BP_SUCCESS,
          payload: res,
          bpId,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.FETCH_PRODUCT_COUNT_FOR_BP_ERROR,
          payload: err,
          bpId,
        })
      })
  }

export const searchEventsInternalAction =
  ({
    page,
    perPage,
    eventName,
    eventId,
    userName,
    devCycleIds,
    devCycleYearIds,
    departmentId,
    clazzId,
    brand,
    levelGroup,
    bpName,
  }) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.SEARCH_INTERNAL_EVENTS,
    })

    return searchEventsInternal({
      page,
      perPage,
      eventName,
      eventId,
      userName,
      devCycleIds,
      devCycleYearIds,
      departmentId,
      clazzId,
      brand,
      levelGroup,
      bpName,
    })
      .then(res => {
        dispatch({
          type: eventActionTypes.SEARCH_INTERNAL_EVENTS_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.SEARCH_INTERNAL_EVENTS_ERROR,
          payload: err,
        })
      })
  }

export const resetEventsAction = () => dispatch => {
  return dispatch({
    type: eventActionTypes.RESET_EVENT_LISTS,
  })
}

export const getAuthUsersAction = eventId => dispatch => {
  dispatch({
    type: eventActionTypes.GET_AUTH_USERS,
  })

  return getAuthUsers(eventId)
    .then(authorizedUsers => {
      dispatch({
        type: eventActionTypes.GET_AUTH_USERS_SUCCESS,
        payload: authorizedUsers,
      })
      return authorizedUsers
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.GET_AUTH_USERS_ERROR,
        payload: err,
      })
    })
}

export const getEventUserViewsAction = eventId => dispatch => {
  dispatch({
    type: eventActionTypes.GET_EVENT_USER_VIEWS,
  })

  return getEventUserViews(eventId)
    .then(res => {
      dispatch({
        type: eventActionTypes.GET_EVENT_USER_VIEWS_SUCCESS,
        payload: res,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.GET_EVENT_USER_VIEWS_ERROR,
        payload: err,
      })
    })
}

export const updateBidForInternalAction =
  (
    eventId,
    bid,
    successMessage = 'Updated successfully.',
    errorMessage = 'Failed to update.'
  ) =>
  dispatch => {
    dispatch({
      type: eventActionTypes.UPDATE_BID_INTERNAL,
    })
    return submitBid(eventId, bid, successMessage, errorMessage)
      .then(res => {
        dispatch({
          type: eventActionTypes.UPDATE_BID_INTERNAL_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(err => {
        dispatch({
          type: eventActionTypes.UPDATE_BID_INTERNAL_ERROR,
          payload: err,
        })
      })
  }

export const resetEventUserViewsAction = () => dispatch => {
  dispatch({
    type: eventActionTypes.RESET_EVENT_USER_VIEWS,
  })
}

export const fetchDistBps = bpId => dispatch => {
  dispatch({
    type: eventActionTypes.FETCH_DISTRIBUTOR_BPS,
  })

  return getDistBps(bpId)
    .then(res => {
      dispatch({
        type: eventActionTypes.FETCH_DISTRIBUTOR_BPS_SUCCESS,
        payload: res.data,
      })
      return res
    })
    .catch(err => {
      dispatch({
        type: eventActionTypes.FETCH_DISTRIBUTOR_BPS_ERROR,
        payload: err,
      })
    })
}
