import * as settingsActionTypes from './actionTypes'

const initialState = {
  customAttributeList: [],
  isFetchingCustAttrs: false,
  isFetchingNumericColumns: false,
  numericColumnList: [],
  isFetchingDeletedCustomAttributes: false,
  deletedCustomAttributeList: [],
}

export const settingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case settingsActionTypes.FETCH_CUSTOM_ATTRIBUTES: {
      return {
        ...state,
        isFetchingCustAttrs: true,
      }
    }
    case settingsActionTypes.FETCH_CUSTOM_ATTRIBUTES_SUCCESS: {
      const attributes = action.payload
        .filter(attribute => attribute.id === 'custom_attributes')
        .flatMap(attributeObject =>
          Object.entries(attributeObject.attributes).map(([key, value]) => ({
            ...value,
            id: key,
          }))
        )

      return {
        ...state,
        isFetchingCustAttrs: false,
        customAttributeList: attributes,
      }
    }
    case settingsActionTypes.FETCH_CUSTOM_ATTRIBUTES_ERROR: {
      return {
        ...state,
        isFetchingCustAttrs: false,
        customAttributeList: [],
      }
    }
    case settingsActionTypes.FETCH_NUMERIC_ATTRIBUTES: {
      return {
        ...state,
        isFetchingNumericColumns: true,
      }
    }
    case settingsActionTypes.FETCH_NUMERIC_ATTRIBUTES_SUCCESS: {
      const attributes = action.payload.flatMap(attributeObject =>
        Object.entries(attributeObject.attributes).reduce(
          (attr, [key, value]) => {
            attr.push({
              name: value.name,
              id: `${attributeObject.id}.${key}`,
            })
            return attr
          },
          []
        )
      )
      return {
        ...state,
        isFetchingNumericColumns: false,
        numericColumnList: attributes,
      }
    }
    case settingsActionTypes.FETCH_NUMERIC_ATTRIBUTES_ERROR: {
      return {
        ...state,
        isFetchingNumericColumns: false,
        numericColumnList: [],
      }
    }
    case settingsActionTypes.FETCH_DELETED_CUSTOM_ATTRIBUTES: {
      return {
        ...state,
        isFetchingDeletedCustomAttributes: true,
      }
    }
    case settingsActionTypes.FETCH_DELETED_CUSTOM_ATTRIBUTES_SUCCESS: {
      const { userId } = action
      const attributes = action.payload
        .filter(attribute => attribute.id === 'custom_attributes')
        .flatMap(attributeObject =>
          Object.entries(attributeObject.attributes).reduce(
            (acc, [key, value]) => {
              if (value.archived_users.includes(userId)) {
                acc.push({
                  ...value,
                  id: key,
                })
              }
              return acc
            },
            []
          )
        )

      return {
        ...state,
        isFetchingDeletedCustomAttributes: false,
        deletedCustomAttributeList: attributes,
      }
    }
    case settingsActionTypes.FETCH_DELETED_CUSTOM_ATTRIBUTES_ERROR: {
      return {
        ...state,
        isFetchingDeletedCustomAttributes: false,
        deletedCustomAttributeList: [],
      }
    }
    case settingsActionTypes.RESET_CUSTOM_ATTRIBUTES: {
      return {
        ...state,
        isFetchingCustAttrs: false,
        customAttributeList: [],
      }
    }
    default:
      return state
  }
}
