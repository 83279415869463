import * as associateProductsActionTypes from './actionTypes'

const initialState = {
  plannedProducts: [],
  isFetchingPlannedProducts: false,
}

export const associateProductsReducer = (
  state = initialState,
  action: { type?: string; payload?: any } = {}
) => {
  switch (action.type) {
    case associateProductsActionTypes.FETCH_PLANNED_PRODUCTS: {
      return {
        ...state,
        isFetchingPlannedProducts: true,
      }
    }
    case associateProductsActionTypes.FETCH_PLANNED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        plannedProducts: action.payload.data,
        isFetchingPlannedProducts: false,
      }
    }
    case associateProductsActionTypes.FETCH_PLANNED_PRODUCTS_ERROR: {
      return {
        ...state,
        plannedProducts: [],
        isFetchingPlannedProducts: false,
      }
    }
    default:
      return { ...state }
  }
}
