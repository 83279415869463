import {
  createStore as reactCreateStore,
  compose,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from './rootReducer'

let composeEnhancers = compose

if (
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === 'development'
) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

let store = null

export const createStore = () => {
  const middleware = [thunk]

  store = reactCreateStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  )
  return store
}

export const getStore = () => store
