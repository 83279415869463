import * as plansActionTypes from './actionTypes'

const initialState = {
  plans: [],
  totalPlansCount: 0,
  isFetchingPlans: false,
}

export const plansReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case plansActionTypes.FETCH_PLANS: {
      return {
        ...state,
        isFetchingPlans: true,
      }
    }
    case plansActionTypes.FETCH_PLANS_SUCCESS: {
      return {
        ...state,
        plans: action.payload.data.plans,
        totalPlansCount: action.payload.data.count,
        isFetchingPlans: false,
      }
    }
    case plansActionTypes.FETCH_PLANS_ERROR: {
      return {
        ...state,
        isFetchingPlans: false,
      }
    }
    default:
      return state
  }
}
