import * as fileActionTypes from './actionTypes'

const initialState = {
  fileList: null, // only null until fetch files is called once
  isFetchingFiles: false,
}

export const filesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case fileActionTypes.FETCH_FILES: {
      return {
        ...state,
        isFetchingFiles: true,
      }
    }
    case fileActionTypes.FETCH_FILES_SUCCESS: {
      return {
        ...state,
        isFetchingFiles: false,
        fileList: action.payload.data,
      }
    }
    case fileActionTypes.FETCH_FILES_ERROR: {
      return {
        ...state,
        isFetchingFiles: false,
        fileList: [],
      }
    }
    case fileActionTypes.RESET_FILES: {
      return {
        ...state,
        fileList: [],
        isFetchingFiles: false,
      }
    }
    case fileActionTypes.UPLOAD_FILE_SUCCESS: {
      // Create a new fileList array by looping through the current fileList
      const fileListWithUpdate = state.fileList.map(existingFile => {
        // Check if there is a new file that matches the existing file
        const idx = action.payload.data.findIndex(
          newFile => existingFile.fileKey === newFile.fileKey
        )
        if (idx === -1) return existingFile // No match so return the existing file
        // There was a match so update modified time
        return {
          ...existingFile,
          fileModifiedTime: action.payload.data[idx].fileModifiedTime,
        }
      })
      return {
        ...state, // spread the state in order to make a copy
        fileList: fileListWithUpdate, // but use our updated fileList in the new state
      }
    }
    default:
      return state
  }
}
