import { AxiosUtil } from '../../utils/axios-util'
import {
  errorResponseNotification,
  errorNotification,
  successNotification,
} from '../notification-service'
import EventConstants from '../../constants/EventConstants'
import { hasDefinedNonNullProps } from '../../utils/PropsValidationUtil'
import { analyticsService, EventCategories } from '../analytics-service'

const getPageUrl = (page, perPage) => {
  return 'page=' + page + '&per_page=' + perPage
}

export const getUserEvents = ({ isUserEvent, page, perPage }) => {
  const url =
    EventConstants.EVENT +
    EventConstants.SEARCH_EVENTS +
    '?' +
    getPageUrl(page, perPage) +
    EventConstants.ONLY_USER_EVENTS +
    isUserEvent
  return AxiosUtil.post(url, {})
    .then(resp => {
      // Filter out events that are missing props needed for views
      const validEvents = resp.data.events.filter(event =>
        hasDefinedNonNullProps(event, ['id', 'event_name', 'event_id'])
      )

      // If some events were filtered out, show error toast that informs the user not all events were loaded
      if (resp.data.events.length !== validEvents.length) {
        errorNotification(
          ['Unable to load some events. Refresh the page to try again.'],
          null
        )
      }
      resp.data.events = validEvents
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getAllUserEvents = () =>
  AxiosUtil.get(EventConstants.GET_ALL_USER_EVENTS).catch(err => {
    errorResponseNotification(err)
    throw err
  })

export const getEventUserViews = (eventId: string) => {
  const url = EventConstants.EVENT + eventId + '/eventDefView'
  return AxiosUtil.get(url)
    .then(res => {
      return res.data
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getAssociatedPlans = ({ eventId }) => {
  const url = EventConstants.COMMON_PLAN + '/plansForEvent?event_id=' + eventId
  return AxiosUtil.get(url)
    .then(resp => {
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const searchEvents = ({
  page,
  perPage,
  eventName,
  eventId,
  userName,
  devCycleIds = null,
  devCycleYearIds = null,
  departmentId = null,
  clazzId = null,
  brand = null,
  levelGroup = null,
  bpName = null,
}) => {
  const requestParams = new URLSearchParams({
    page,
    per_page: perPage,
    show_archived_events: 'true',
    only_events_info: 'false',
  })
  const requestBody: any = { filterRequest: [] }
  const url = 'internal/events/v1/event/searchEvents'

  let analyticsAction = 'Internal Search'
  if (eventName && eventName !== '') {
    requestParams.append('event_name', encodeURIComponent(eventName))
    analyticsAction = `Event Name ${analyticsAction}`
  }
  if (eventId && eventId !== '') {
    requestParams.append('event_ids', eventId)
    analyticsAction = `Event ID ${analyticsAction}`
  }
  if (userName && userName !== '') {
    requestParams.append('first_last_name', encodeURIComponent(userName))
    analyticsAction = `Username ${analyticsAction}`
  }
  if (departmentId) {
    requestBody.filterRequest.push({
      field: 'section_data.item.dept',
      operator: 'equals',
      values: [parseInt(departmentId)],
    })
  }
  if (clazzId) {
    requestBody.filterRequest.push({
      field: 'section_data.item.clazz',
      operator: 'equals',
      values: [parseInt(clazzId)],
    })
  }
  if (brand) {
    requestBody.filterRequest.push({
      field: 'section_data.item.brand',
      operator: 'contains',
      values: [brand],
    })
  }
  if (levelGroup) {
    requestBody.filterRequest.push({
      field: 'level_group',
      operator: 'contains',
      values: [levelGroup],
    })
  }
  if (bpName) {
    requestBody.filterRequest.push({
      field: 'bp_name',
      operator: 'contains',
      values: [bpName],
    })
  }
  if (devCycleIds) {
    requestBody.devCycles = devCycleIds
  }
  if (devCycleYearIds) {
    requestBody.devCycleYears = devCycleYearIds
  }

  analyticsService.event({
    category: EventCategories.SearchEvent,
    action: analyticsAction,
    label: `eventName: ${eventName}; eventId: ${eventId}`,
  })

  return AxiosUtil.post(`${url}?${requestParams.toString()}`, requestBody)
    .then(resp => {
      resp.data.events = resp.data.events.map(event => {
        event.event_name_id = `${event.event_name} (${event.event_id})`
        return event
      })
      return resp
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const getAuthUsers = eventId => {
  const url = EventConstants.EVENT + eventId
  return AxiosUtil.get(url)
    .then(response => response.data.authorized_users)
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const assignUsers = (users, eventId) => {
  const url = EventConstants.EVENT + eventId
  return AxiosUtil.put(url, users, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const assignUserView = (eventId, viewId) => {
  const url = EventConstants.EVENT + eventId + '/eventUserView'
  return AxiosUtil.put(url, viewId, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      successNotification(['Successfully updated view for this event'])
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const removeAuthUsers = (eventId, authUserId, copyAttrFlag) => {
  const url =
    EventConstants.EVENT +
    'authUsers/' +
    eventId +
    '/' +
    authUserId +
    '?copyAttr=' +
    copyAttrFlag
  return AxiosUtil.put(url, [], {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const createEvent = event => {
  const url = EventConstants.EVENT
  return AxiosUtil.post(url, event, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(() => {
      successNotification('Event was created Successfully')
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const copyEvent = event => {
  const url = EventConstants.EVENT + 'copy'
  return AxiosUtil.post(url, event, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(() => {
      successNotification('Event was copied Successfully')
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const updateEvent = ({ event, publishFlag }) => {
  const url =
    EventConstants.EVENT + 'updateEvent/' + event.id + '?publish=' + publishFlag
  return AxiosUtil.put(url, event, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      successNotification('Event was saved Successfully')
      return res
    })
    .catch(err => {
      errorResponseNotification(err)
      throw err
    })
}

export const submitBid = (
  eventId,
  bidId,
  request,
  successMessage,
  errorMessage
) => {
  return AxiosUtil.put(
    'internal/events/v1/event/' + eventId + '/bids/' + bidId,
    request,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (successMessage) {
        successNotification([successMessage])
      }
      return res
    })
    .catch(err => {
      if (errorMessage) {
        errorNotification(errorMessage)
      } else {
        errorResponseNotification(err)
      }
      throw err
    })
}
