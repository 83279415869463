import React, { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { connect } from 'react-redux'
import { getUserAction } from '../../store/auth/actionCreator'
import { getStore } from '../../store/store'
import { resetStoreAction } from '../../store/resetStore'
import { addAxiosInterceptors } from '../../utils/axios-util'
import Cookies from 'js-cookie'
import { LinearProgress } from '@material-ui/core/'

const WithAuthUser = ({
  //mapped props
  userName,
  challengeAuthentication,
  //dispatch
  getUserAction,
  //passed props
  children,
}) => {
  const auth = useAuth()
  const { isAuthorized, session, login } = auth
  const isUserAuthorized = isAuthorized()

  useEffect(() => {
    if (isUserAuthorized) {
      if (localStorage.getItem('id_token') === null && session?.accessToken) {
        localStorage.setItem('id_token', session?.accessToken)
      }
      addAxiosInterceptors()
      if (
        window.location.host.indexOf('partnersonline') < 0 &&
        session?.userInfo
      ) {
        // if not on partnersonline domain, for testing sake, put a dummy cookie to simulate POL cookie
        Cookies.set(
          'SSOSESSIONINFO',
          btoa(
            JSON.stringify({
              user: session.userInfo.lanId,
              login_status: true,
              debug_timestamp: 1579884083,
            })
          )
        )
      }
      if (!userName) {
        //clear the store for every new login or change in userName
        const store = getStore()
        store.dispatch(resetStoreAction)
        getUserAction()
      }
    }
  }, [getUserAction, isUserAuthorized, userName, isAuthorized, session])

  useEffect(() => {
    if (challengeAuthentication) {
      localStorage.removeItem('id_token')
      localStorage.removeItem('access_token')
      window.location.reload()
    }
  }, [challengeAuthentication])

  return (
    <>
      {!userName && <LinearProgress />}
      {isUserAuthorized && userName && !challengeAuthentication
        ? children
        : login()}
    </>
  )
}

const mapStateToProps = state => {
  const { auth } = state
  const { userName, challengeAuthentication } = auth
  return {
    userName,
    challengeAuthentication,
  }
}

const mapDispatchToProps = {
  getUserAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(WithAuthUser)
